<template>
  <div class="inner-content">
    <h2>
      {{ title }}
    </h2>
    <p>
      <b>{{ listTitle }}</b>
    </p>
    <p>
      {{ listSubtitle }}
    </p>
    <ol>
      <li v-for="(item, index) in listItems" :key="index" v-html="item" />
    </ol>
    <div class="list-wrapper">
      <ul>
        <li v-for="(voucher, index) in vouchers" :key="index">{{ voucher }}</li>
      </ul>
    </div>
    <div class="footer-message">
      <i>
        {{ footerStartText }}
        <a :href="termsAndConditionsUrl" target="_blank">
          {{ footerTermsLink }}
        </a>
        {{ footerTextInBetweenLinks }}
        <a :href="privacyPolicyUrl" target="_blank">
          {{ footerPrivacyLink }}
        </a>
        {{ footerEndText }}
      </i>
    </div>
    <base-button
      class="primary"
      id="btn-activation-instructions-close"
      data-track-event="navigate"
      data-track-name="close-activation-instructions"
      @click="closePopup"
    >
      {{ buttonText }}
    </base-button>
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton.vue'
export default {
  components: {
    BaseButton
  },
  props: {
    serviceId: {
      type: Number
    },
    title: {
      type: String,
      default: ''
    },
    listTitle: {
      type: String,
      default: ''
    },
    listSubtitle: {
      type: String,
      default: ''
    },
    listItems: {
      type: Array
    },
    footerStartText: {
      type: String,
      default: ''
    },
    footerTermsLink: {
      type: String,
      default: ''
    },
    footerTextInBetweenLinks: {
      type: String,
      default: ''
    },
    footerPrivacyLink: {
      type: String,
      default: ''
    },
    footerEndText: {
      type: String,
      default: ''
    },
    termsAndConditionsUrl: {
      type: String,
      default: ''
    },
    privacyPolicyUrl: {
      type: String,
      default: ''
    },
    vouchers: {
      type: Array
    },
    closePopup: {
      type: Function
    }
  },
  computed: {
    buttonText() {
      return this.$t('activation.btn_close')
    }
  }
}
</script>

<style lang="scss" scoped>
div.inner-content {
  text-align: left;

  h2 {
    font-size: 2rem;
  }
}
ol {
  list-style: none;
  counter-reset: li;
  padding-left: 0;

  li:before {
    counter-increment: li;
    content: counter(li) '. ';
  }
}

.list-wrapper {
  padding: 0 0 10px 0;

  ul {
    list-style: none;
    padding-left: 0;
    li {
      background: #f5f5f5;
      border-radius: 3px;
      padding: 6px 13px;
      text-align: left;

      + li {
        margin-top: 10px;
      }
    }
  }
}

div.footer-message {
  padding: 0 0 20px;
}

@media (min-width: 768px) {
  div.inner-content {
    text-align: center;

    h2 {
      font-size: 2.2rem;
    }
  }
  .left-aligned {
    text-align: left;
  }
  .flex-btn {
    width: auto;
  }
  .list-wrapper {
    padding: 10px 40px 30px 40px;
  }
}
</style>
