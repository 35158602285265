var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.doExist
    ? _c("iframe", {
        attrs: { src: _vm.iframeUrlManaged, frameborder: "0" },
        on: { load: _vm.onLoad },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }