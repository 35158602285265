<template>
  <one-col-box-grid-content-box>
    <h2>{{ $t('delete_user_confirmation.hl_delete_user') }}</h2>
    <p>{{ $t('delete_user_confirmation.question_if_delete_user') }}</p>
    <p>{{ $t('delete_user_confirmation.delete_result_with_contract') }}</p>
    <base-button class="alert" @click="deleteUser" :class="{ loading }">
      {{ $t('delete_user_confirmation.yes') }}
    </base-button>
    <base-button-link class="neutral" to="/portal/contact">
      {{ $t('delete_user_confirmation.no') }}
    </base-button-link>
  </one-col-box-grid-content-box>
</template>

<script>
import OneColBoxGridContentBox from './OneColBoxGridContentBox.vue'
import BaseButton from './BaseButton.vue'
import BaseButtonLink from './BaseButtonLink.vue'
import CustomerService from '@/services/CustomerService'
import DataMixin from '@/mixins/data'

export default {
  data() {
    return {
      loading: false
    }
  },
  components: {
    OneColBoxGridContentBox,
    BaseButton,
    BaseButtonLink
  },
  mixins: [DataMixin],
  methods: {
    async deleteUser() {
      this.loading = true
      const response = await this.preHandleResponse(() => {
        return CustomerService.postDeleteUser(this.$store.state.customer.token)
      })
      if (!response || response.errorCode) {
        this.loading = false
        return
      }
      let redirecUrl = 'https://' + document.location.hostname + '/login'
      window.location =
        response.saLogoutUrl + '?redirect_url=' + encodeURIComponent(redirecUrl)
    }
  }
}
</script>

<style lang="scss" scoped></style>
