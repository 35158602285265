<template>
  <div class="other-info">
    <span class="days-until-expiration" v-if="showDaysUntilExpiration">
      <span class="text">{{ $t('statusbar.ntc_daysleft') }}</span>
      <span class="circle blue">
        <span class="value">{{ daysUntilExpirationShown }}</span>
      </span></span
    >
    <span class="licenses">
      <span class="text">{{ $t('statusbar.ntc_remaining_x_licenses') }}</span>
      <span v-if="availableLicenses !== null" class="circle blue">
        <span class="value">
          <span class="available" v-if="totalLicenses > 0">{{
            availableLicenses
          }}</span>
          <span v-if="totalLicenses > 0"> / </span>
          <span class="total">{{ totalLicenses }}</span>
        </span>
      </span>
      <span v-else class="loader-wrapper">
        <img src="@/assets/images/spinner-white.svg" />
      </span>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    daysUntilExpiration: Number,
    availableLicenses: Number,
    totalLicenses: Number,
    hideDaysUntilExpiration: Boolean
  },
  computed: {
    daysUntilExpirationShown: function a() {
      let daysUntilExpiration = 0

      if (this.daysUntilExpiration > 0) {
        daysUntilExpiration = this.daysUntilExpiration
      }

      return daysUntilExpiration
    },
    showDaysUntilExpiration() {
      return (
        this.daysUntilExpiration !== null &&
        !this.$props.hideDaysUntilExpiration
      )
    }
  }
}
</script>
