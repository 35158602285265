var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isVisible
    ? _c(
        "base-settings-content-section",
        { attrs: { id: "additional-services-id" } },
        [
          _c("h2", { attrs: { id: "hl-additional-services" } }, [
            _vm._v(
              " " + _vm._s(_vm.$t("subscription.hl_additional_services")) + " "
            ),
          ]),
          _c(
            "div",
            { staticClass: "grid" },
            _vm._l(_vm.filteredAdditionalServices, function (service) {
              return _c("service-item", {
                key: service.id,
                attrs: { "service-id": service.id },
              })
            }),
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }