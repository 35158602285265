<template>
  <input :value="value" @input="updateValue" v-bind="$attrs" v-on="listeners" />
</template>

<script>
export default {
  props: {
    value: [String, Number]
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue
      }
    }
  },
  methods: {
    updateValue(event) {
      this.$emit('input', event.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
input {
  border-radius: 0;
  width: 100%;
  min-height: 50px;
  line-height: 50px;
  border: 1px solid #ccc;
  color: $font-color;
  padding: 0 10px;
  border-radius: 4px;
  font-size: 1.14286rem;
  transition-duration: 0.2s;

  &.error {
    border-color: $danger-color;
  }
}

@media (min-width: $desktop-min-width) {
  input {
    &.one-line-desktop {
      width: auto;

      + .flex-btn {
        margin-top: 0;
        margin-left: 10px;
      }
    }
  }
}
</style>
