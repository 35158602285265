<template>
  <router-link
    class="flex-btn"
    v-bind="$props"
    v-on="$listeners"
    :event="$props.preventClick ? '' : 'click'"
  >
    <span>
      <slot />
    </span>
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: String
    },
    preventClick: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped></style>
