<template>
  <one-col-box-grid-content-box>
    <template v-if="showSuccess">
      <h2>{{ $t('cancel_autorenew.hl_cancelled') }}</h2>
      <p>{{ noticeCancelled }}</p>
      <base-button-link
        class="neutral"
        to="/portal/order"
        @click.native="resetData"
      >
        {{ $t('cancel_autorenew.btn_back') }}
      </base-button-link>
    </template>
    <template v-else-if="showError">
      <h2>{{ $t('cancel_autorenew.hl_cancel_error') }}</h2>
      <p>
        {{ $t('cancel_autorenew.ntc_cancel_error') }}<br />{{
          $t('cancel_autorenew.ntc_cancel_error_retry')
        }}
      </p>
      <base-button-link class="primary" to="/portal/order">
        {{ $t('cancel_autorenew.btn_back') }}
      </base-button-link>
    </template>
    <template v-else>
      <h2>{{ $t('cancel_autorenew.hl_cancel_subscription') }}</h2>
      <p>{{ noticeCancel }}</p>
      <base-button-link
        class="alert"
        prevent-click
        to="/portal/order"
        @click.native="cancelAutorenew"
        :class="{ loading }"
      >
        {{ $t('cancel_autorenew.btn_cancelsubscription') }}
      </base-button-link>
      <base-button-link
        class="neutral"
        to="/portal/order"
        @click.native="resetData"
      >
        {{ $t('cancel_autorenew.btn_cancelcancelsubscription') }}
      </base-button-link>
    </template>
  </one-col-box-grid-content-box>
</template>

<script>
import SubscriptionService from '@/services/SubscriptionService.js'
import { mapGetters } from 'vuex'
import OneColBoxGridContentBox from './OneColBoxGridContentBox.vue'
import BaseButtonLink from '@/components/BaseButtonLink.vue'
import DataMixin from '@/mixins/data'
export default {
  data() {
    return {
      cancelRequestSent: false,
      cancelSuccess: false,
      loading: false
    }
  },
  mixins: [DataMixin],
  components: {
    OneColBoxGridContentBox,
    BaseButtonLink
  },
  computed: {
    ...mapGetters({
      expirationDateFormatted: 'customer/contract/expirationDateFormatted'
    }),
    showSuccess() {
      return this.cancelRequestSent && this.cancelSuccess
    },
    showError() {
      return this.cancelRequestSent && !this.cancelSuccess
    },
    noticeCancel() {
      return this.$t('cancel_autorenew.ntc_cancel', {
        nextBillingDate: this.expirationDateFormatted
      }).replace(/^\[.*\]/, '')
    },
    noticeCancelled() {
      return this.$t('cancel_autorenew.ntc_cancelled', {
        nextBillingDate: this.expirationDateFormatted
      }).replace(/^\[.*\]/, '')
    }
  },
  methods: {
    resetData() {
      this.cancelRequestSent = false
      this.cancelSuccess = false
    },
    async cancelAutorenew() {
      if (this.loading) {
        return
      }

      this.loading = true
      const response = await this.preHandleResponse(() =>
        SubscriptionService.postCancelAutorenewal(
          this.$store.state.customer.token
        )
      )
      this.loading = false

      if (!response || response.errorCode) {
        this.cancelRequestSent = true
        return
      }

      this.cancelSuccess = true
      this.cancelRequestSent = true
      this.$store.dispatch('customer/fetch')
      this.$store.dispatch('ui/fetchCart')
    }
  }
}
</script>

<style lang="scss" scoped></style>
