var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal-popup",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isOpen,
          expression: "isOpen",
        },
      ],
      staticClass: "activation",
      attrs: {
        id: "activation-popup",
        "is-wide": "",
        "display-close-button": "",
        "on-close-click": _vm.closeWithOptionalRefresh,
        "more-padding": "",
        "less-close-padding": "",
        "top-close-id": "additional-service-top-close",
      },
    },
    [
      _c("popup-content", {
        attrs: {
          "close-popup": _vm.closeOrShowInstructions,
          "info-text": _vm.infoText,
          "is-info-left-aligned": _vm.isInfoLeftAligned,
          "is-activated-only-on-myfs": _vm.isActivatedOnlyOnMyfs,
          "show-instructions-popup": _vm.showInstructionsPopup,
          "close-button-text": _vm.closeButtonText,
          "service-name": _vm.serviceName,
          "learnmore-url": _vm.learnmoreUrl,
          "terms-url": _vm.termsUrl,
          "service-id": _vm.serviceId,
        },
        on: { "service-activated": _vm.enableRefreshOnClose },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }