<template>
  <base-view>
    <template #top>
      <the-header is-nav-shown />
      <the-title-section :title="mainTitle" :sub-title="subTitle" />
      <one-col-box-grid-content start-higher>
        <the-account-settings-box />
        <the-notification-settings-box />
        <the-other-settings-box />
        <the-change-email-box />
        <avenue-iframe-background />
      </one-col-box-grid-content>
    </template>
    <template #bottom>
      <the-region-picker />
      <the-help />
      <the-footer />
    </template>
  </base-view>
</template>
<script>
import AvenueIframeBackground from '@/components/AvenueIframeBackground.vue'
import TheHeader from '@/components/TheHeader.vue'
import TheHelp from '@/components/TheHelp.vue'
import TheRegionPicker from '@/components/TheRegionPicker.vue'
import TheFooter from '@/components/TheFooter.vue'
import BaseView from '@/components/BaseView.vue'
import TheTitleSection from '@/components/TheTitleSection.vue'
import OneColBoxGridContent from '@/components/OneColBoxGridContent.vue'
import TheAccountSettingsBox from '@/components/TheAccountViewAccountSettingsBox.vue'
import TheNotificationSettingsBox from '@/components/TheAccountViewNotificationSettingsBox.vue'
import TheChangeEmailBox from '@/components/TheAccountViewChangeEmailBox.vue'
import TheOtherSettingsBox from '@/components/TheAccountViewOtherSettingsBox.vue'

export default {
  components: {
    TheHeader,
    TheTitleSection,
    TheRegionPicker,
    TheHelp,
    TheFooter,
    BaseView,
    OneColBoxGridContent,
    TheAccountSettingsBox,
    TheNotificationSettingsBox,
    TheChangeEmailBox,
    TheOtherSettingsBox,
    AvenueIframeBackground
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$root.$emit('route-entered', to.path, from.path)
      vm.$root.$emit('account-view-entered', to.path, from.path)
    })
  },
  computed: {
    mainTitle() {
      return this.$t('account.hl_account')
    },
    subTitle() {
      return this.$t('account.sl_account')
    }
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: $desktop-min-width) {
  ::v-deep .one-col-box.grid .flex-btn {
    width: auto;
  }
}
</style>
