var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "arrow-menu",
      class: { "menu-open": _vm.isOpen },
      on: { mouseenter: _vm.openMenu, mouseleave: _vm.closeMenu },
    },
    [
      _c(
        "base-header-bar-link",
        {
          staticClass: "main-link",
          attrs: { to: _vm.$props.to, disabled: "" },
          nativeOn: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.togglePopup.apply(null, arguments)
            },
          },
        },
        [
          _vm._t("main-link"),
          _c("span", { staticClass: "arrow-icon" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/icons/dashboard_icon_arrow_up.svg"),
                alt: "",
              },
            }),
            _c("img", {
              attrs: {
                src: require("@/assets/images/icons/dashboard_icon_arrow_down.svg"),
                alt: "",
              },
            }),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "menu-items" }, [_vm._t("menu-items")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }