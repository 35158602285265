<template>
  <base-settings-content-section
    class="sense-howto"
    id="sense-howto-id"
    v-if="isVisible"
  >
    <h2 id="hl_how_to_start_using_sense">
      {{ $t('subscription.hl_howto_start_using_sense') }}
    </h2>
    <base-settings-content-section-grid>
      <div class="long">
        <ol id="ol-sense-instructions">
          <li v-html="infoSenseInstruction1"></li>
          <li>{{ $t('subscription.info_sense_instruction_2') }}</li>
          <li>{{ $t('subscription.info_sense_instruction_3') }}</li>
          <li>{{ $t('subscription.info_sense_instruction_4') }}</li>
        </ol>
      </div>
      <div class="short">
        <base-button-link-native
          class="primary"
          :href="senseDownloadUrl"
          id="btn-download-sense"
          target="_blank"
        >
          {{ $t('subscription.info_sense_download_label') }}
        </base-button-link-native>
      </div>
    </base-settings-content-section-grid>
  </base-settings-content-section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import BaseSettingsContentSection from './BaseSettingsContentSection.vue'
import BaseSettingsContentSectionGrid from './BaseSettingsContentSectionGrid.vue'
import BaseButtonLinkNative from './BaseButtonLinkNative.vue'
import DataMixin from '@/mixins/data'

export default {
  mixins: [DataMixin],
  components: {
    BaseSettingsContentSection,
    BaseButtonLinkNative,
    BaseSettingsContentSectionGrid
  },
  computed: {
    ...mapState({
      customerEmail: (state) => state.customer.email,
      senseDownloadUrl: (state) => state.customer.senseDownloadUrl
    }),
    ...mapGetters({
      isSenseSubscription: 'customer/contract/isSenseSubscription',
      hasSenseSdk: 'customer/hasSenseSdk'
    }),
    isVisible() {
      return this.hasSenseSdk
      // return this.isSenseSubscription || this.hasSenseSdk
    },
    infoSenseInstruction1() {
      return this.$t('subscription.info_sense_instruction_1')
    }
  }
}
</script>

<style lang="scss">
.sense-howto {
  &.settings-content {
    padding: 33px 40px;

    > h2 {
      margin-bottom: 16px;
    }

    li {
      margin-top: 0;
      margin-bottom: 12px;
      color: $font-color;
      line-height: 1.571rem;
      font-size: 1.1rem;
    }

    .messages {
      p {
        font-weight: 600;
      }
      .ok {
        color: $ok-color;
      }
      .error {
        color: $danger-color;
      }
    }
  }
}
@media (min-width: 768px) {
  .sense-howto {
    &.settings-content {
      padding: 40px;

      > h2 {
        margin-bottom: 13px;
      }

      p {
        margin-bottom: 20px;

        a {
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
