var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item" }, [
    _c("div", { staticClass: "title" }, [
      _vm._v(" " + _vm._s(_vm.$t("subscription.hl_date")) + " "),
    ]),
    _c("div", { staticClass: "title" }, [
      _vm._v(" " + _vm._s(_vm.$t("subscription.hl_service")) + " "),
    ]),
    _c("div", { staticClass: "title" }, [
      _vm._v(" " + _vm._s(_vm.$t("subscription.hl_paid_by")) + " "),
    ]),
    _c("div", { staticClass: "title" }, [
      _vm._v(" " + _vm._s(_vm.$t("subscription.hl_total")) + " "),
    ]),
    _c("div", { staticClass: "title" }, [
      _vm._v(" " + _vm._s(_vm.$t("subscription.hl_documents")) + " "),
    ]),
    _c("div", { staticClass: "value" }, [
      _vm._v(" " + _vm._s(_vm.formattedDate) + " "),
    ]),
    _c("div", { staticClass: "value" }, [
      _vm._v(" " + _vm._s(_vm.translatedService) + " "),
    ]),
    _c("div", { staticClass: "value" }, [
      _vm._v(" " + _vm._s(_vm.translatedPaymentMethod) + " "),
    ]),
    _c("div", { staticClass: "value" }, [
      _vm._v(" " + _vm._s(_vm.priceAndCurrency) + " "),
    ]),
    _c("div", { staticClass: "value" }, [
      _c(
        "a",
        {
          attrs: {
            "data-track-event": "download",
            "data-track-name": "open-invoice",
            target: "_blank",
            rel: "noopener noreferrer",
            href: _vm.item.invoiceLink,
          },
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("subscription.lnk_open_document", {
                  purchase_document_title: _vm
                    .$t("subscription.purchase_document_title_inv")
                    .toLowerCase(),
                })
              ) +
              " "
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }