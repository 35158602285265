<template>
  <div class="container">
    <h1>{{ $t('registration.hl_complete_coupon_registration') }}</h1>
    <div class="tab" :class="{ 'tab-active': step === 1 }">
      <span class="icon">
        <img
          src="../assets/images/icons/icon_status_green_fill_small.svg"
          alt
        />
      </span>
      {{ $t('complete_registration.step_1_tab_title') }}
    </div>
    <div class="tab tab-middle">
      <span class="icon-step" v-if="step === 1">2</span>
      <span class="icon" v-else>
        <img
          src="../assets/images/icons/icon_status_green_fill_small.svg"
          alt
        />
      </span>
      {{ $t('complete_registration.step_2_tab_title') }}
    </div>
    <div class="tab" :class="{ 'tab-active': step === 3 }">
      <span class="icon-step">3</span>
      {{ $t('complete_registration.step_3_tab_title') }}
    </div>
    <div class="tab-content">
      <template v-if="step === 1">
        <img
          src="../assets/images/account-activation-account-created.svg"
          alt
        />
        <h3>{{ $t('complete_registration.step_1_heading') }}</h3>
        <base-button-link class="primary" to="/portal/coupon/redeem">
          {{ $t('complete_registration.step_1_btn_label') }}
        </base-button-link>
      </template>
      <template v-else>
        <div class="step-3">
          <div class="step-3-image">
            <img src="../assets/images/account-activation-success.svg" alt />
          </div>
          <div class="step-3-desc">
            <template>
              <h3>
                {{
                  $t('complete_registration.step_3_heading_1', {
                    service_name: productName
                  })
                }}
              </h3>
              {{
                $t('complete_registration.step_3_body_1', {
                  license_size: licenseSize
                })
              }}
            </template>
            <div class="btn-action">
              <base-button-link class="primary" to="/portal/welcome">
                {{ $t('complete_registration.step_3_btn_label') }}
              </base-button-link>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="tab-footer" v-if="step === 1">
      <p>
        {{ haveNoCodeText }}
        <router-link to="/portal/start-trial">{{
          haveNoCodeLinkText
        }}</router-link>
      </p>
    </div>
  </div>
</template>
<script>
import BaseButtonLink from './BaseButtonLink.vue'
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    BaseButtonLink
  },
  computed: {
    ...mapGetters({
      productNames: 'ui/productNames',
      unifiedProductNames: 'ui/unifiedProductNames',
      isIDPSubscription: 'customer/contract/isIdpSubscription',
      isFreedomeSubscription: 'customer/contract/isFreedomeSubscription',
      isTotalSubscription: 'customer/contract/isTotalSubscription',
      isUnified: 'customer/isUnified',
      getUnifiedProductName: 'customer/contract/getUnifiedProductName'
    }),
    ...mapState({
      licenseSize: (state) => state.customer.contract.licenseSize,
      contractServiceKey: (state) => state.customer.contract.serviceKey
    }),
    haveNoCodeText() {
      return this.$t('registration.link_complete_coupon_registration').replace(
        /{linkstart}(.*?){linkend}/g,
        ''
      )
    },
    productName() {
      if (this.isFreedomeSubscription) {
        return this.unifiedProductNames.unifiedVpnFull
      }

      if (this.isUnified) {
        return this.getUnifiedProductName
      } else {
        if (this.isIDPSubscription) {
          return this.productNames.key
        } else if (this.isTotalSubscription) {
          return this.productNames.total
        }
        return this.productNames.safe
      }
    },
    haveNoCodeLinkText() {
      return /{linkstart}(.*?){linkend}/g.exec(
        this.$t('registration.link_complete_coupon_registration')
      )[1]
    },
    step() {
      return parseInt(this.$route.query.step) || 1
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$root.$emit('route-entered', to.path, from.path)
      vm.$root.$emit('complete-registration-view-entered', to.path, from.path)
    })
  }
}
</script>
<style lang="scss" scoped>
@media (min-width: $desktop-min-width) {
  .container {
    text-align: center;
    h1 {
      padding-bottom: 15px;
    }
    .icon {
      img {
        width: 21px;
        margin-bottom: -6px;
      }
    }
    .icon-step {
      color: white;
      background-color: #2693ff;
      padding: 2px 6px 2px 6px;
      border-radius: 18px;
      font-size: 0.75rem;
    }

    .flex-btn {
      width: auto;
      margin-top: 16px;
    }
    .tab {
      border: 1px solid #c5c5c5;
      width: 33.33%;
      float: left;
      padding-top: 10px;
      padding-bottom: 10px;
      font-weight: 500;
    }
    .tab-middle {
      border-left: 0;
      border-right: 0;
    }
    .tab-active {
      border-bottom: 0;
    }
    .tab-content {
      border: 1px solid #c5c5c5;
      border-top: 0;
      display: block;
      margin-right: 1px;
      padding-top: 70px;
      padding-bottom: 20px;
      .step-3 {
        display: flex;
        div {
          flex: 50%;
        }
        .step-3-desc {
          text-align: left;
          padding-right: 20px;
          h3 {
            font-size: large;
          }
          .btn-action {
            text-align: right;
          }
        }
      }
    }
  }
}
</style>
