var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "input",
    _vm._g(
      _vm._b(
        { domProps: { value: _vm.value }, on: { input: _vm.updateValue } },
        "input",
        _vm.$attrs,
        false
      ),
      _vm.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }