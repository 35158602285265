var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-coupon-view-content", {
    attrs: { couponValid: _vm.couponValid },
    scopedSlots: _vm._u([
      {
        key: "apply-coupon",
        fn: function () {
          return [
            _c("div", { staticStyle: { "text-align": "center" } }, [
              _c("img", {
                attrs: { src: require("../assets/images/coupon-valid.svg") },
              }),
            ]),
            _c("h1", [_vm._v(_vm._s(_vm.$t("coupon_renew.hl_valid_code")))]),
            _vm.redeem
              ? _c("strong", [
                  _vm._v(_vm._s(_vm.$t("coupon_redeem.ntc_want_to_activate"))),
                ])
              : _c(
                  "strong",
                  [
                    _vm.sameProduct
                      ? [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "coupon_renew.ntc_want_renew_same_product",
                                  { servicename: _vm.newProductName }
                                )
                              ) +
                              " "
                          ),
                        ]
                      : [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("coupon_renew.ntc_want_renew", {
                                  old_servicename: _vm.oldProductName,
                                  servicename: _vm.newProductName,
                                })
                              ) +
                              " "
                          ),
                        ],
                  ],
                  2
                ),
            _vm.redeem
              ? _c("span", { staticStyle: { display: "block" } }, [
                  _vm._v(_vm._s(_vm.containsLengthOfProtectionText)),
                ])
              : _vm._e(),
            _c("br"),
            !_vm.redeem
              ? _c("div", { staticClass: "grid-4" }, [
                  _c("div", { staticClass: "value" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("coupon_apply.label_subscription_current")
                        )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.currentDaysLeftText) + " "),
                    ]),
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _c("span", [_vm._v(_vm._s(_vm.licenseSizeText))]),
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _c("span", [_vm._v(_vm._s(_vm.oldProductName))]),
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("coupon_apply.label_subscription_new"))
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.newDaysLeftText) + " "),
                    ]),
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _vm.isDeviceCountDowngrade
                      ? _c("span", { staticClass: "downgrade" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("subscription.x_devices", {
                                maxdevices: _vm.newDeviceCount,
                              })
                            )
                          ),
                        ])
                      : _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("subscription.x_devices", {
                                maxdevices: _vm.newDeviceCount,
                              })
                            )
                          ),
                        ]),
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _vm.isProductChange
                      ? _c("span", { staticClass: "downgrade" }, [
                          _vm._v(_vm._s(_vm.newProductName)),
                        ])
                      : _c("span", [_vm._v(_vm._s(_vm.newProductName))]),
                  ]),
                ])
              : _vm._e(),
            _vm.newServiceKey === "FREEDOME" && _vm.redeem
              ? _c("span", { staticStyle: { display: "block" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "coupon_apply.label_freedome_info_login_required"
                        )
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
            !_vm.redeem
              ? _c("p", { domProps: { innerHTML: _vm._s(_vm.hintText) } })
              : _vm._e(),
            _vm.applyingCoupon
              ? [
                  _c("div", [
                    _c("picture", [
                      _c("img", {
                        staticClass: "applying-coupon-spinner",
                        attrs: {
                          src: require("@/assets/images/spinner.svg"),
                          alt: "",
                        },
                      }),
                    ]),
                  ]),
                ]
              : _vm._e(),
            _c(
              "div",
              { staticStyle: { "text-align": "right" } },
              [
                _c(
                  "base-button",
                  {
                    staticClass: "neutral",
                    class: { disabled: _vm.applyingCoupon },
                    attrs: { disabled: _vm.applyingCoupon },
                    on: { click: _vm.resetState },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("coupon_renew.btn_cancel")) + " "
                    ),
                  ]
                ),
                _c(
                  "base-button",
                  {
                    staticClass: "primary",
                    class: { disabled: _vm.applyingCoupon },
                    attrs: { disabled: _vm.applyingCoupon },
                    on: { click: _vm.applyVoucher },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("coupon_renew.btn_accept")) + " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "enter-coupon",
        fn: function () {
          return [
            !_vm.redeem
              ? [
                  _c("h2", [
                    _vm._v(_vm._s(_vm.$t("coupon_renew.hl_redeem_your_code"))),
                  ]),
                  _c("h3", [
                    _vm._v(
                      _vm._s(_vm.$t("coupon_renew.sl_current_subscription"))
                    ),
                  ]),
                  _c("div", { staticClass: "grid" }, [
                    _c("div", { staticClass: "title" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("coupon_renew.hl_days_left"))),
                      ]),
                    ]),
                    _c("div", { staticClass: "title" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("coupon_renew.hl_devices"))),
                      ]),
                    ]),
                    _c("div", { staticClass: "title" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("coupon_renew.hl_subscription_type"))
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _c("span", [_vm._v(_vm._s(_vm.daysLeftText))]),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _c("span", [_vm._v(_vm._s(_vm.licenseSizeText))]),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _c("span", [_vm._v(_vm._s(_vm.renewalTypeText))]),
                    ]),
                  ]),
                  _c("h3", [
                    _vm._v(
                      _vm._s(_vm.$t("coupon_renew.sl_update_subscription"))
                    ),
                  ]),
                  _c("base-loading-input-wrapper", {
                    ref: "coupon-input",
                    staticClass: "limited-width",
                    class: {
                      error: _vm.$v.couponInput.$error,
                      disabled: _vm.isButtonDisabled,
                    },
                    attrs: {
                      type: "text",
                      placeholder: _vm.inputPlaceholder,
                      disabled: _vm.isButtonDisabled,
                      loading: _vm.validatingCoupon,
                    },
                    on: {
                      blur: function ($event) {
                        return _vm.$v.couponInput.$touch()
                      },
                      keyup: function ($event) {
                        return _vm.$v.couponInput.$touch()
                      },
                    },
                    model: {
                      value: _vm.couponInput,
                      callback: function ($$v) {
                        _vm.couponInput = $$v
                      },
                      expression: "couponInput",
                    },
                  }),
                ]
              : [
                  _c("h2", [
                    _vm._v(_vm._s(_vm.$t("coupon_redeem.hl_redeem_code"))),
                  ]),
                  _c("h3", [
                    _vm._v(
                      _vm._s(_vm.$t("coupon_redeem.sl_activate_with_code"))
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex-row" },
                    [
                      _c("base-loading-input-wrapper", {
                        ref: "input-wrapper",
                        class: {
                          error: _vm.$v.couponInput.$error,
                          disabled: _vm.isButtonDisabled,
                        },
                        attrs: {
                          id: "coupon-redeem",
                          label: _vm.$t("coupon_redeem.ntc_enter_data"),
                          placeholder: _vm.inputPlaceholder,
                          disabled: _vm.isButtonDisabled,
                          loading: _vm.validatingCoupon,
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.$v.couponInput.$touch()
                          },
                          keyup: function ($event) {
                            return _vm.$v.couponInput.$touch()
                          },
                        },
                        model: {
                          value: _vm.couponInput,
                          callback: function ($$v) {
                            _vm.couponInput = $$v
                          },
                          expression: "couponInput",
                        },
                      }),
                      _vm.showHint
                        ? _c("div", { staticClass: "hint" }, [
                            _c(
                              "a",
                              {
                                staticClass: "css-tooltip",
                                attrs: {
                                  href: "#",
                                  "tooltip-text": _vm.getHintText,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.doNothing.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "icon" }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("../assets/images/icons/icon_help.svg"),
                                      alt: "?",
                                    },
                                  }),
                                ]),
                                _c("span", { staticClass: "text" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("coupon_redeem.ntc_hint")) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                          ])
                        : _c("div", { staticClass: "hint" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.doNothing.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "icon" }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("../assets/images/icons/icon_help.svg"),
                                      alt: "?",
                                    },
                                  }),
                                ]),
                                _c("span", { staticClass: "text" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("coupon_redeem.ntc_hint")) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                    ],
                    1
                  ),
                ],
            _vm.couponInvalid
              ? [
                  _c("p", { staticClass: "error-message" }, [
                    _vm._v(" " + _vm._s(_vm.validationErrorMessage) + " "),
                  ]),
                  _c("p", {
                    domProps: { innerHTML: _vm._s(_vm.supportHelpText) },
                  }),
                ]
              : _vm._e(),
            _vm.redeem
              ? _c("p", [
                  _vm._v(
                    "* " + _vm._s(_vm.$t("coupon_redeem.form_requiredfield"))
                  ),
                ])
              : _c("p", [
                  _vm._v(
                    "* " + _vm._s(_vm.$t("coupon_renew.form_requiredfield"))
                  ),
                ]),
            _c(
              "div",
              { staticStyle: { "text-align": "right" } },
              [
                _c(
                  "base-button",
                  { staticClass: "neutral", on: { click: _vm.navigateToExit } },
                  [
                    _vm.redeem
                      ? [_vm._v(_vm._s(_vm.$t("coupon_redeem.btn_cancel")))]
                      : [_vm._v(_vm._s(_vm.$t("coupon_renew.btn_cancel")))],
                  ],
                  2
                ),
                _c(
                  "base-button",
                  {
                    staticClass: "primary",
                    class: {
                      error: _vm.$v.couponInput.$error,
                      disabled: _vm.isButtonDisabled,
                    },
                    attrs: { disabled: _vm.isButtonDisabled },
                    on: { click: _vm.validateCoupon },
                  },
                  [
                    _vm.redeem
                      ? [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("coupon_redeem.btn_continue")) +
                              " "
                          ),
                        ]
                      : [_vm._v(_vm._s(_vm.$t("coupon_renew.btn_continue")))],
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }