<template>
  <div class="voucher-item">
    <div class="heading">
      {{ heading }}
    </div>
    <div class="content">
      <span class="voucher">
        {{ voucher }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: String,
    voucher: String
  }
}
</script>

<style lang="scss" scoped>
.voucher-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  box-shadow: 0 2px 10px -5px #888;
  border-radius: 6px;

  .heading {
    font-size: 0.85714rem;
    line-height: 1.2rem;
    background: #f5f5f5;
    color: #666;
    font-style: italic;
    padding: 10px;
    min-height: 36px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .content {
    padding: 16px 8px 10px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .voucher {
    color: #6621cc;
    font-weight: 600;
    line-height: 1.14286rem;
    font-size: 1.14286rem;
  }
}

@media (min-width: $desktop-min-width) {
  .voucher-item {
    .heading {
      font-size: 1rem;
      line-height: 1.4rem;
    }
    .voucher {
      font-size: 1.71429rem;
      line-height: 1rem;
    }
  }
}
</style>
