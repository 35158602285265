var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isVisible
    ? _c(
        "base-settings-content-section",
        { staticClass: "billing-details", attrs: { id: "billing-details-id" } },
        [
          _c("h2", [_vm._v(_vm._s(_vm.$t("subscription.hl_billing_details")))]),
          _c(
            "base-settings-content-section-grid",
            [
              [
                _c("div", { staticClass: "long" }, [
                  _c("p", { attrs: { id: "text-expiration-date-title" } }, [
                    _vm._v(_vm._s(_vm.dateSectionTitle)),
                  ]),
                ]),
                _c("div", { staticClass: "short" }, [
                  _c("p", { attrs: { id: "text-expiration-date-value" } }, [
                    _vm._v(_vm._s(_vm.expirationDateFormatted)),
                  ]),
                ]),
                _vm.isAutomaticSubscription
                  ? [
                      _c("div", { staticClass: "long" }, [
                        _c("p", { attrs: { id: "text-billing-price-title" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("subscription.next_billing_price_title")
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "short" }, [
                        _c("p", { attrs: { id: "text-billing-price-value" } }, [
                          _vm._v(_vm._s(_vm.nextBillingPrice)),
                        ]),
                      ]),
                      _c("div", { staticClass: "long" }, [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "font-size": "0.75rem",
                              "font-style": "italic",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "subscription.next_billing_cancellation_info"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "short" }),
                    ]
                  : _vm._e(),
                _vm.allowChangePaymentMethod
                  ? _c("div", { staticClass: "long" }, [
                      _c("p", { attrs: { id: "text-payment-method" } }, [
                        _vm._v(_vm._s(_vm.paymentMethodText)),
                      ]),
                    ])
                  : _vm._e(),
                _vm.allowChangePaymentMethod
                  ? _c(
                      "div",
                      { staticClass: "short" },
                      [
                        _c(
                          "base-button-link-native",
                          {
                            staticClass: "neutral",
                            attrs: {
                              id: "btn-change-payment-method",
                              href: _vm.changePaymentMethodUrl,
                              "data-track-event": "navigate",
                              "data-track-name": "change-payment-method",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "subscription.btn_change_payment_method"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }