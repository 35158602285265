var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-settings-content-section",
    { staticClass: "summary", attrs: { id: "summary-id" } },
    [
      _c("h2", { attrs: { id: "hl-subscription-summary" } }, [
        _vm._v(
          " " + _vm._s(_vm.$t("subscription.hl_subscription_summary")) + " "
        ),
      ]),
      _c(
        "div",
        { staticClass: "grid", attrs: { id: "subscription-summary-item" } },
        [
          _c("subscription-view-summary-item", {
            attrs: {
              title: _vm.$t("subscription.hl_product_type"),
              content: _vm.productType,
            },
          }),
          _vm.displayExpirationDate
            ? _c("subscription-view-summary-item", {
                attrs: {
                  title: _vm.$t("subscription.hl_expiration_date"),
                  content: _vm.expirationDateFormatted,
                },
              })
            : _vm._e(),
          _c("subscription-view-summary-item", {
            attrs: {
              title: _vm.$t("subscription.hl_status"),
              content: _vm.subscriptionStatusText,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }