<template>
  <base-view ref="base-view">
    <template #top>
      <the-header is-nav-shown />
      <the-title-section :title="$t('subscription.hl_subscription')" />
      <one-col-box no-padding :style="{ 'margin-bottom': marginBottom }">
        <overview-section-sense v-if="isSenseSubscription" />
        <overview-section v-else />
        <sense-section />
        <additional-services-section />
        <subscription-action-content v-if="!isSenseSubscription" />
        <billing-details />
        <!-- <summary-section /> -->
        <!-- <renew-section /> -->
        <!-- <actions-section /> -->
        <billing-history />
        <discount-section />
        <avenue-iframe-background />
      </one-col-box>
      <!-- <base-dropdown-content
        id="main-dropdown-content"
        ref="dropdown-content"
      /> -->
    </template>
    <template #bottom>
      <the-region-picker />
      <the-help />
      <the-footer />
    </template>
  </base-view>
</template>
<script>
import AvenueIframeBackground from '@/components/AvenueIframeBackground.vue'
import TheHeader from '@/components/TheHeader.vue'
import TheHelp from '@/components/TheHelp.vue'
import TheRegionPicker from '@/components/TheRegionPicker.vue'
import TheFooter from '@/components/TheFooter.vue'
import BaseView from '@/components/BaseView.vue'
import TheTitleSection from '@/components/TheTitleSection.vue'
import OneColBox from '@/components/OneColBox.vue'
import BillingDetails from '@/components/TheSubscriptionViewBillingDetails.vue'
import BillingHistory from '@/components/TheSubscriptionViewBillingHistory.vue'
import DiscountSection from '@/components/TheSubscriptionViewDiscountSection.vue'
import OverviewSection from '@/components/TheSubscriptionViewOverviewSection.vue'
import OverviewSectionSense from '@/components/TheSubscriptionViewOverviewSectionSense.vue'
import AdditionalServicesSection from '@/components/TheSubscriptionViewAdditionalServicesSection.vue'
import SenseSection from '@/components/TheSubscriptionViewSenseSection.vue'
import SubscriptionActionContent from '@/components/SubscriptionActionContent.vue'
// import SummarySection from '@/components/TheSubscriptionViewSummarySection.vue'
// import RenewSection from '@/components/TheSubscriptionViewRenewSection.vue'
// import ActionsSection from '@/components/TheSubscriptionViewActionsSection.vue'
// import BaseDropdownContent from '@/components/BaseDropdownContent.vue'

export default {
  data() {
    return {
      marginBottom: 0
    }
  },
  components: {
    TheHeader,
    TheTitleSection,
    TheRegionPicker,
    TheHelp,
    TheFooter,
    BaseView,
    OneColBox,
    BillingHistory,
    OverviewSection,
    BillingDetails,
    DiscountSection,
    AvenueIframeBackground,
    AdditionalServicesSection,
    OverviewSectionSense,
    SenseSection,
    SubscriptionActionContent
    // SummarySection,
    // RenewSection,
    // ActionsSection,
    // BaseDropdownContent
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$root.$emit('route-entered', to.path, from.path)
      vm.$root.$emit('subscription-view-entered', to.path, from.path)
    })
  },
  mounted() {
    this.$root.$on(
      'update-content-bottom-margin',
      this.updateContentBottomMargin
    )
  },
  beforeDestroy() {
    this.$root.$off(
      'update-content-bottom-margin',
      this.updateContentBottomMargin
    )
  },
  computed: {
    isSenseSubscription() {
      return this.$store.getters['customer/contract/isSenseSubscription']
    }
  },
  methods: {
    /**
     * Makes sure that dropdown content fits to page and bottom bar height is not increased
     */
    updateContentBottomMargin(data) {
      this.$nextTick(() => {
        let start = data.start
        let topSectionElem = this.$refs['base-view'].$refs['top']
        let topSectionHeight = topSectionElem.clientHeight
        let dropdownContentRef = this.$refs['dropdown-content']
        let dropdownContentElem = dropdownContentRef.$el.firstElementChild
        let dropdownStyle = window.getComputedStyle
          ? window.getComputedStyle(dropdownContentElem, null)
          : dropdownContentElem.currentStyle
        let marginTop = parseInt(dropdownStyle.marginTop) || 0
        let marginBottom = parseInt(dropdownStyle.marginBottom) || 0
        let dropDownContentHeight =
          dropdownContentElem.clientHeight + marginTop + marginBottom
        let spaceNeeded = dropDownContentHeight - (topSectionHeight - start)

        if (spaceNeeded > 0) {
          this.marginBottom = spaceNeeded + 'px'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: $desktop-min-width) {
  .one-col-box ::v-deep .content {
    .flex-btn {
      width: auto;
      min-width: 240px;

      + .flex-btn {
        margin-left: 10px;
      }
    }
  }
}
</style>
