var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isVisible
    ? _c(
        "one-col-box-grid-content-box",
        [
          _c("h2", [_vm._v(_vm._s(_vm.$t("account.hl_other_settings")))]),
          _c(
            "p",
            [
              _vm._v(" " + _vm._s(_vm.slOtherSettings) + " "),
              _c("router-link", { attrs: { to: "/portal/order" } }, [
                _vm._v(" " + _vm._s(_vm.slOtherSettingsText) + " "),
              ]),
            ],
            1
          ),
          _vm.isDeleteUserLinkVisible
            ? _c(
                "p",
                [
                  _vm._v(" " + _vm._s(_vm.slDeleteUser) + " "),
                  _c(
                    "router-link",
                    { attrs: { to: "/portal/delete-user/confirmation" } },
                    [_vm._v(" " + _vm._s(_vm.slDeleteUserText) + " ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "base-button-link",
            {
              staticClass: "primary",
              attrs: {
                id: "btn-back-to-home",
                to: "/",
                "data-track-event": "navigate",
                "data-track-name": "back-to-home",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("account.sl_back_to_home")) + " ")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }