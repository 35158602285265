<template>
  <one-col-box-grid-content-box>
    <h2>{{ $t('delete_user_confirmation.hl_delete_result') }}</h2>
    <ul>
      <li>
        {{ $t('delete_user_confirmation.list_result_expire') }}
      </li>
      <li>
        {{ $t('delete_user_confirmation.list_result_deleted') }}
      </li>
    </ul>
  </one-col-box-grid-content-box>
</template>

<script>
import OneColBoxGridContentBox from './OneColBoxGridContentBox.vue'
export default {
  components: {
    OneColBoxGridContentBox
  }
}
</script>

<style lang="scss" scoped>
ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    color: #666;
    font-size: 1.14286rem;

    &:not(:last-child) {
      padding-bottom: 16px;
    }

    &::before {
      content: '\26ab';
      font-size: 0.4em;
      vertical-align: middle;
      display: inline-block;
      line-height: 0.4em;
      margin-top: -2px;
      color: #666;
    }
  }
}
</style>
