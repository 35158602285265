var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-view", {
    attrs: { "is-blue-bg-view": "" },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function () {
          return [
            _c("the-header", {
              attrs: { "is-nav-shown": "", "no-shadow": _vm.showStatusBar },
            }),
            _vm.showStatusBar ? _c("the-statusbar") : _vm._e(),
            _vm.showAnnouncementMigratedStandaloneIdpVpn
              ? _c("the-header-bar-announcement")
              : _vm._e(),
            _c("avenue-iframe"),
          ]
        },
        proxy: true,
      },
      {
        key: "bottom",
        fn: function () {
          return [_c("the-region-picker"), _c("the-help"), _c("the-footer")]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }