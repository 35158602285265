var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal-popup",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isOpen,
          expression: "isOpen",
        },
      ],
      staticClass: "upgrade",
      attrs: {
        id: "upgrade-popup",
        "is-wide": "",
        "display-close-button": "",
        "on-close-click": _vm.closePopup,
        "more-padding": "",
        "less-close-padding": "",
      },
    },
    [_c("upgrade-override-popup-content"), _c("upgrade-popup-content")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }