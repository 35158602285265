export default {
  methods: {
    async preHandleResponse(promiseFn) {
      // eslint-disable-next-line space-before-function-paren
      return new Promise(async (resolve, reject) => {
        let response = await promiseFn()

        if (!response) {
          resolve(response)
          return
        }

        if (response.errorCode === 'invalid_token') {
          await this.$store.dispatch('customer/fetch')
          response = await promiseFn()
        }

        if (response.errorCode === 'no_customer_session') {
          this.$router.push('/login')
        }

        resolve(response)
      })
    }
  }
}
