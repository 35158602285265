var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "the-maintenance-title-section" }, [
    _c("div", [
      _c("h1", [_vm._v(_vm._s(_vm.$t("maintenance.hl_temporarily_down")))]),
      _c("div", { staticClass: "img" }),
      _c("p", [_vm._v(_vm._s(_vm.$t("maintenance.txt_down_for_maintenance")))]),
      _c("p", [_vm._v(_vm._s(_vm.$t("maintenance.txt_thanks")))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }