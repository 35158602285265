<template>
  <base-view>
    <template #top>
      <the-header is-nav-shown />
      <the-title-section :title="mainTitle" />
      <one-col-box-grid-content>
        <convert-to-annual-content />
      </one-col-box-grid-content>
    </template>
    <template #bottom>
      <the-region-picker />
      <the-help />
      <the-footer />
    </template>
  </base-view>
</template>
<script>
import TheHeader from '@/components/TheHeader.vue'
import TheHelp from '@/components/TheHelp.vue'
import TheRegionPicker from '@/components/TheRegionPicker.vue'
import TheFooter from '@/components/TheFooter.vue'
import BaseView from '@/components/BaseView.vue'
import TheTitleSection from '@/components/TheTitleSection.vue'
import OneColBoxGridContent from '@/components/OneColBoxGridContent.vue'
import ConvertToAnnualContent from '@/components/TheConvertToAnnualViewContent.vue'

export default {
  components: {
    TheHeader,
    TheTitleSection,
    TheRegionPicker,
    TheHelp,
    TheFooter,
    BaseView,
    OneColBoxGridContent,
    ConvertToAnnualContent
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$root.$emit('route-entered', to.path, from.path)
      vm.$root.$emit('convert-to-annual-view-entered', to.path, from.path)
    })
  },
  computed: {
    mainTitle() {
      return this.$t('convert_to_annual.hl_welcome')
    }
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: $desktop-min-width) {
  .one-col-box.grid ::v-deep .content {
    grid-gap: 0 70px;
    background-position: center;
    grid-template-columns: 1fr;

    .flex-btn {
      width: auto;
      min-width: 106px;

      + .flex-btn {
        margin-left: 10px;
      }
    }
  }
}
</style>
