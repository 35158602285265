<template>
  <base-settings-content-section
    v-if="isVisible"
    class="billing-history"
    id="billing-history-id"
  >
    <h2>{{ $t('subscription.hl_billing_history') }}</h2>
    <div class="list">
      <billing-history-item
        v-for="item in billingHistory"
        :key="item.id"
        :item="item"
      />
    </div>
    <p v-html="contactToSupportText"></p>
  </base-settings-content-section>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseSettingsContentSection from './BaseSettingsContentSection.vue'
import BillingHistoryItem from './SubscriptionViewBillingHistoryItem.vue'
export default {
  components: {
    BaseSettingsContentSection,
    BillingHistoryItem
  },
  mounted() {
    this.$root.$on('subscription-view-entered', this.updateListIfVisible)
  },
  beforeDestroy() {
    this.$root.$off('subscription-view-entered', this.updateListIfVisible)
  },
  computed: {
    ...mapGetters({
      isTrial: 'customer/contract/isTrial',
      isSubUser: 'customer/contract/isSubUser',
      isSenseSubscription: 'customer/contract/isSenseSubscription'
    }),
    billingHistory() {
      return this.$store.state.customer.billingHistory
    },
    contactToSupportText() {
      let url =
        'https://onlineshop.f-secure.com/789/purl-macuseco?language=' +
        this.$store.state.ui.currentLocale
      let linkstart = '<a target="_blank" rel="noopener no" href="' + url + '">'
      let linkend = '</a>'
      return this.$t('subscription.msg_if_have_questions_contact_to_support', {
        linkstart,
        linkend
      })
    },
    isVisible() {
      return (
        !this.isTrial &&
        !this.isSubUser &&
        this.billingHistory &&
        this.billingHistory.length > 0 &&
        !this.isSenseSubscription
      )
    }
  },
  methods: {
    updateListIfVisible() {
      if (!this.isVisible) {
        return
      }
      this.$store.dispatch('customer/fetchBillingHistory')
    }
  }
}
</script>

<style lang="scss" scoped>
.list + p {
  margin-top: 50px;
  font-weight: 400;
}
</style>
