var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal-popup",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isOpen,
          expression: "isOpen",
        },
      ],
      staticClass: "subscription-reminder",
      attrs: {
        id: "subscription-reminder",
        "more-padding": "",
        "is-wide": "",
        "more-footer-padding": !_vm.isFooterTextEmpty,
      },
    },
    [
      [
        _c("h2", [_vm._v(_vm._s(_vm.$t("reminder.inactive_session")))]),
        _c("div", { staticClass: "content-row centered" }, [
          _c("div", { staticClass: "text" }, [
            _c("p", { staticStyle: { display: "inline" } }, [
              _vm._v(
                " " + _vm._s(_vm.$t("reminder.inactive_session_end_in")) + " "
              ),
            ]),
            _c("h3", { staticStyle: { display: "inline" } }, [
              _vm._v(
                " " + _vm._s(_vm.formatedCountdown || "countdown over") + " "
              ),
            ]),
            _c("p", [
              _vm._v(_vm._s(_vm.$t("reminder.inactive_session_click_refresh"))),
            ]),
          ]),
        ]),
        _c(
          "base-button",
          {
            staticClass: "primary",
            attrs: { disabled: _vm.buttonDisabled },
            on: { click: _vm.closePopup },
            nativeOn: {
              click: function ($event) {
                return _vm.closeOrNavigate()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("reminder.btn_inactive_session_refresh")))]
        ),
        _c("base-purchase-button", {
          staticClass: "neutral",
          attrs: { text: _vm.buttonText, url: _vm.buttonUrl },
        }),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }