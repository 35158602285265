<template>
  <div class="the-status-bar" :class="{ 'two-notes': showSecondNotice }">
    <div class="content-wrapper">
      <status-info
        :message="infoMessage"
        :type="mainStatus"
        :link-url="statusbarLinkUrl"
        :link-text="statusbarLinkText"
      />
      <status-info
        :message="infoMessage2"
        type="notice"
        :link-url="statusbarLinkUrl2"
        :link-text="statusbarLinkText2"
        v-if="showSecondNotice"
      />
      <other-info
        :days-until-expiration="daysUntilExpirationProcessed"
        :total-licenses="totalLicenses"
        :available-licenses="availableLicenses"
        :hide-days-until-expiration="hideDaysUntilExpiration"
      />
      <action-button v-if="isPurchaseButtonVisible" ref="ActionButton" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import StatusInfo from '@/components/TheStatusbarStatusInfo.vue'
import OtherInfo from '@/components/TheStatusbarOtherInfo.vue'
import ActionButton from '@/components/TheStatusbarActionButton.vue'
import transchoice from '@/mixins/transchoice.js'

export default {
  data() {
    return {
      focusProduct: 'Internet Security',
      resizing: false
    }
  },
  mixins: [transchoice],
  components: {
    StatusInfo,
    OtherInfo,
    ActionButton
  },
  computed: {
    ...mapState({
      daysUntilExpiration: (state) =>
        state.customer.contract.daysUntilExpiration,
      isAutomaticSubscription: (state) =>
        state.customer.contract.isAutomaticSubscription,
      isPartnerManagedSubscription: (state) =>
        state.customer.contract.isAutomaticSubscription &&
        state.customer.contract.type === 'PREPAID' &&
        state.customer.affiliate !== 'fsecure'
    }),
    ...mapGetters({
      isTrial: 'customer/contract/isTrial',
      isPrepaid: 'customer/contract/isPrepaid',
      isSubUser: 'customer/contract/isSubUser',
      expirationDateFormatted: 'customer/contract/expirationDateFormatted',
      remindBeforeDaysAmount: 'customer/contract/remindBeforeDaysAmount',
      contractHasExpired: 'customer/contract/hasExpired',
      isContractWaitingForPayment: 'customer/isContractWaitingForPayment',
      customerIsSuspended: 'customer/isSuspended',
      isPurchaseButtonVisible: 'ui/isPurchaseButtonVisible',
      productNames: 'ui/productNames',
      isIDPSubscription: 'customer/contract/isIDPSubscription',
      isFreedomeSubscription: 'customer/contract/isFreedomeSubscription',
      isTotalSubscription: 'customer/contract/isTotalSubscription',
      isTotalStandaloneInstallable:
        'customer/licenses/isTotalStandaloneInstallable'
    }),
    daysUntilExpirationProcessed() {
      if (this.customerIsSuspended) {
        return 0
      }
      if (this.isAutomaticSubscription) {
        return null
      }
      return this.daysUntilExpiration
    },
    mainStatus() {
      if (
        this.isAutomaticSubscription &&
        this.daysUntilExpiration === null &&
        this.isPrepaid
      ) {
        return 'ok'
      }

      if (this.isAutomaticSubscription && this.daysUntilExpiration >= 0) {
        return 'ok'
      }

      if (this.daysUntilExpiration > this.subscriptionOkDays) {
        return 'ok'
      }
      if (this.customerIsSuspended) {
        return 'alert'
      }
      if (this.daysUntilExpiration >= 0) {
        return 'notice'
      }
      return 'alert'
    },
    showSecondNotice() {
      return this.isContractWaitingForPayment
    },
    totalLicenses: function a() {
      if (this.customerIsSuspended) {
        return 0
      }

      if (this.contractHasExpired) {
        return 0
      }

      if (this.focusProduct === this.productNames.freedome) {
        return this.$store.state.customer.licenses.freedomeTotalLicenses
      }

      if (this.focusProduct === this.productNames.key) {
        return this.$store.state.customer.licenses.keyTotalLicenses
      }

      return this.$store.state.customer.licenses.safeTotalLicenses
    },
    availableLicenses: function a() {
      if (this.customerIsSuspended) {
        return 0
      }

      if (this.contractHasExpired) {
        return 0
      }

      if (this.focusProduct === this.productNames.freedome) {
        return this.$store.state.customer.licenses.freedomeAvailableLicenses
      }

      if (this.focusProduct === this.productNames.key) {
        return this.$store.state.customer.licenses.keyAvailableLicenses
      }

      return this.$store.state.customer.licenses.safeAvailableLicenses
    },
    subscriptionOkDays() {
      return this.remindBeforeDaysAmount
    },
    infoMessage2() {
      if (this.isContractWaitingForPayment) {
        return this.$t('statusbar.ntc_waiting_for_payment').replace(
          /{linkstart}(.*?){linkend}/g,
          ''
        )
      }
      return ''
    },
    infoMessage: function a() {
      let message = ''

      let daysUntilExpiration = this.daysUntilExpiration
      let isAutomaticSubscription = this.isAutomaticSubscription
      let isPrepaid = this.isPrepaid
      let isSubUser = this.isSubUser
      let isTrial = this.isTrial
      let subscriptionOkDays = this.subscriptionOkDays
      let isSubUserSuspended = this.customerIsSuspended
      let expirationDateFormatted = this.expirationDateFormatted
      let $t = this.$t.bind(this)
      let transchoice = this.transchoice.bind(this)

      if (isTrial) {
        if (daysUntilExpiration > subscriptionOkDays) {
          message = $t('statusbar.ntc_trial_valid_until', {
            date: expirationDateFormatted
          })
        } else if (daysUntilExpiration >= 0) {
          message = transchoice(
            $t('statusbar.ntc_trial_expires_in_days', {
              days: daysUntilExpiration
            }),
            daysUntilExpiration
          )
        } else {
          message = $t('statusbar.ntc_trial_expired')
        }
      } else if (isSubUser) {
        if (isSubUserSuspended) {
          message = $t('statusbar.ntc_subuser_subscription_expired')
        } else if (daysUntilExpiration > subscriptionOkDays) {
          message = $t('statusbar.ntc_subscription_valid_until', {
            date: expirationDateFormatted
          })
        } else if (daysUntilExpiration >= 0) {
          message = transchoice(
            $t('statusbar.ntc_subuser_subscription_expires_in_days', {
              days: daysUntilExpiration
            }),
            daysUntilExpiration
          )
        } else {
          message = $t('statusbar.ntc_subuser_subscription_expired')
        }
      } else if (isPrepaid) {
        if (
          isAutomaticSubscription &&
          daysUntilExpiration === null &&
          isPrepaid
        ) {
          if (this.isPartnerManagedSubscription) {
            message = $t('statusbar.ntc_subscription_valid_partner_managed')
          } else {
            message = $t('statusbar.ntc_subscription_valid')
          }
        } else if (daysUntilExpiration > subscriptionOkDays) {
          message = $t('statusbar.ntc_subscription_valid_until', {
            date: expirationDateFormatted
          })
        } else if (daysUntilExpiration >= 0) {
          message = transchoice(
            $t('statusbar.ntc_subscription_expires_in_days', {
              days: daysUntilExpiration
            }),
            daysUntilExpiration
          )
        } else {
          message = $t('statusbar.ntc_subscription_expired')
        }
      } else if (isAutomaticSubscription) {
        if (daysUntilExpiration >= 0) {
          message = $t('statusbar.ntc_subscription_valid')
        } else {
          message = $t('statusbar.ntc_autorenew_expired')
        }
      } else {
        if (daysUntilExpiration > subscriptionOkDays) {
          message = $t('statusbar.ntc_subscription_valid_until', {
            date: expirationDateFormatted
          })
        } else if (daysUntilExpiration >= 0) {
          message = transchoice(
            $t('statusbar.ntc_subscription_expires_in_days', {
              days: daysUntilExpiration
            }),
            daysUntilExpiration
          )
        } else {
          message = $t('statusbar.ntc_subscription_expired')
        }
      }

      return message
    },
    statusbarLinkUrl: function a() {
      let url = ''

      if (this.mainStatus === 'ok' && this.isAutomaticSubscription) {
        url = '/portal/order'
      }

      return url
    },
    statusbarLinkUrl2() {
      if (this.isContractWaitingForPayment) {
        return '/portal/order'
      }
      return ''
    },
    statusbarLinkText: function a() {
      let linkText = ''

      if (this.mainStatus === 'ok' && this.isAutomaticSubscription) {
        linkText = this.$t('statusbar.lnk_edit_subscription')
      }

      return linkText
    },
    statusbarLinkText2() {
      if (this.isContractWaitingForPayment) {
        return /{linkstart}(.*?){linkend}/g.exec(
          this.$t('statusbar.ntc_waiting_for_payment')
        )[1]
      }
      return ''
    },
    actionButtonText: function a() {
      let daysUntilExpiration = this.$store.state.customer.daysUntilExpiration
      let isAutomaticSubscription =
        this.$store.state.customer.isAutomaticSubscription
      let contractType = this.$store.state.customer.contractType
      let contractId = this.$store.state.customer.contractId

      let buttonText = ''
      let isPrepaid = contractType === 'PREPAID'
      let isTrial = contractType === 'TRIAL'
      let isSubUser = contractId <= 0
      let subscriptionOkDays = 45

      if (isSubUser) {
        buttonText = ''
      } else if (isAutomaticSubscription && daysUntilExpiration < 0) {
        buttonText = this.$t('statusbar.btn_edit_subscription')
      } else if (isPrepaid) {
        buttonText = this.$t('statusbar.btn_renew_now')
      } else if (isTrial) {
        buttonText = this.$t('statusbar.btn_buy_now')
      } else if (daysUntilExpiration > subscriptionOkDays) {
        buttonText = this.$t('statusbar.btn_buy_more')
      } else if (!isAutomaticSubscription) {
        buttonText = this.$t('statusbar.btn_renew_now')
      }

      return buttonText
    },
    hideDaysUntilExpiration() {
      if (this.isAutomaticSubscription && this.daysUntilExpiration < 0) {
        return true
      }

      return false
    }
  },
  methods: {
    updateFocusProductByUrl(to, from) {
      let newFocus = this.focusProduct
      if (to === '/portal/welcome') {
        newFocus = this.productNames.safe
      }

      if (
        to === '/portal/key' ||
        (this.isIDPSubscription && !this.isTotalStandaloneInstallable)
      ) {
        newFocus = this.productNames.key
      }

      if (to === '/portal/idp-portal') {
        if (this.isIDPSubscription && !this.isTotalStandaloneInstallable) {
          newFocus = this.productNames.key
        } else {
          newFocus = this.productNames.safe
        }
      }

      if (
        to === '/portal/freedome' ||
        (this.isFreedomeSubscription && !this.isTotalStandaloneInstallable)
      ) {
        newFocus = this.productNames.freedome
      }
      if (newFocus !== this.focusProduct) {
        this.focusProduct = newFocus
      }
    },
    sendHeightChanged() {
      this.$nextTick(() => {
        this.$root.$emit(
          'statusbar-height-changed',
          this.$el.getBoundingClientRect().height
        )
        this.resizing = false
      })
    },
    handleResize: function a() {
      if (this.resizing) {
        return
      }

      this.resizing = true

      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(this.sendHeightChanged)
      } else {
        window.setTimeout(this.sendHeightChanged, 66)
      }
    }
  },
  mounted() {
    this.$root.$on('product-view-entered', this.updateFocusProductByUrl)
    this.checkFocusProductFunc = setInterval(() => {
      this.updateFocusProductByUrl(this.$route.path, this.$route.path)
    }, 250)
    window.addEventListener('resize', this.handleResize)
    this.sendHeightChanged()
    this.$nextTick(function a() {
      if (this.$refs.ActionButton) {
        this.$refs.ActionButton.onResize()
      }
    })
  },
  updated: function a() {
    this.updateFocusProductByUrl(this.$route.path, this.$route.path)
    this.$nextTick(function a() {
      if (this.$refs.ActionButton) {
        this.$refs.ActionButton.onResize()
      }
    })
    this.sendHeightChanged()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
    clearInterval(this.checkFocusProductFunc)
    this.$root.$off('product-view-entered', this.updateFocusProductByUrl)
  }
}
</script>

<style lang="scss">
.the-status-bar {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url('../assets/images/bg-horizontal-combined-stripe.jpg');
  position: fixed;
  top: 64px;
  width: 100%;
  z-index: 3;
  background-position: center center;

  + .container {
    margin-top: 64px;
  }

  span {
    color: #fff;
    font-size: 0.9rem;
    line-height: 1rem;
  }

  a {
    color: #fff;
  }

  .loader-wrapper {
    display: inline-block;
    min-width: 34px;
    line-height: 23px;

    img {
      width: 18px;
      height: 18px;
      vertical-align: text-top;
    }
  }

  .circle {
    display: inline-block;
    border-radius: 14px;
    padding: 0 8px;
    line-height: 23px;

    > span {
      display: inline-block;
      height: 20px;
    }

    &.blue {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  > div {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: stretch;
  }

  .action-button a {
    min-width: 103px;
    background: $primary-color;
    display: inline-block;
    line-height: 64px;
    text-align: center;
    color: #fff;
    text-decoration: none;
    padding: 0px 25px;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .status-info {
    padding: 6px 12px 6px 16px;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    min-height: 64px;

    a {
      white-space: nowrap;
    }

    .icon {
      width: 26px;
      height: 26px;
      display: inline-block;
      margin: 0 6px 0 0;
      vertical-align: text-bottom;
      flex: 1 0 auto;
      max-width: 26px;

      img {
        max-width: 100%;
        max-height: 100%;
        vertical-align: top;
      }
    }

    span:last-child {
      flex: 1 1 auto;
    }
  }

  &.two-notes {
    .other-info {
      display: none;
    }
  }

  .other-info {
    padding: 6px 10px;
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;

    > span {
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      ~ span {
        margin-left: 10px;
      }

      > span:first-child {
        display: inline-block;
        padding-bottom: 3px;
        margin-right: 5px;
      }
    }
  }
}

@media (min-width: $desktop-min-width) {
  .the-status-bar {
    top: 100px;

    + .container {
      margin-top: 38px;
    }

    > div {
      max-width: 960px;
      margin: 0 auto;
    }

    .action-button a {
      line-height: 38px;
    }

    .other-info > span > span:first-child {
      padding-bottom: 2px;
    }

    .status-info {
      min-height: 0;

      .icon {
        flex: 0 0 auto;
      }

      span:last-child {
        padding-bottom: 2px;
      }
    }

    &.two-notes {
      .other-info {
        display: flex;
      }
    }
  }
}

@media (min-width: 986px) {
  .the-status-bar {
    .status-info {
      padding: 6px 12px 6px 0;
    }
  }
}

@media (max-width: 400px) {
  .the-status-bar {
    .other-info > span:nth-child(2) {
      display: none;
    }
    &.two-notes {
      .status-info:first-child {
        display: none;
      }
      .other-info {
        display: flex;
      }
    }
  }
}
</style>
