var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "status-info" }, [
    _c("span", { staticClass: "icon" }, [
      _c("img", { attrs: { src: _vm.iconUrl, alt: "" } }),
    ]),
    _c(
      "span",
      { staticClass: "message" },
      [
        _vm._v(" " + _vm._s(_vm.message) + " "),
        _vm.linkUrl
          ? _c("router-link", { attrs: { to: _vm.linkUrl } }, [
              _vm._v(_vm._s(_vm.linkText)),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }