<template>
  <div class="the-maintenance-title-section">
    <div>
      <h1>{{ $t('login.hl_token_error') }}</h1>
      <p>{{ $t('login.txt_token_error') }}</p>
    </div>
  </div>
</template>
<script>
export default {}
</script>

<style lang="scss" scoped>
.the-maintenance-title-section {
  padding: 16px;

  h1 {
    font-size: 1.71429rem;
    color: $primary-color;
    font-weight: 600;
    text-align: center;
    margin-bottom: 24px;
  }

  p {
    font-weight: 300;
    text-align: center;
  }
}
@media (min-width: $desktop-min-width) {
  .the-maintenance-title-section {
    padding: 32px;

    h1 {
      margin-bottom: 40px;
    }

    p {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
