var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", { staticClass: "the-header-bar-announcement" }, [
    _vm.showVpnAnnouncement
      ? _c("div", [
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.$t("dashboard.announcement_for_migrated_vpn_standalone")
              )
            ),
          ]),
        ])
      : _vm._e(),
    _vm.showIdpAnnouncement
      ? _c("div", [
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.$t("dashboard.announcement_for_migrated_idp_standalone")
              ) + " "
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }