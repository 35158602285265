var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("h1", [
      _vm._v(_vm._s(_vm.$t("registration.hl_complete_coupon_registration"))),
    ]),
    _c("div", { staticClass: "tab", class: { "tab-active": _vm.step === 1 } }, [
      _vm._m(0),
      _vm._v(
        " " + _vm._s(_vm.$t("complete_registration.step_1_tab_title")) + " "
      ),
    ]),
    _c("div", { staticClass: "tab tab-middle" }, [
      _vm.step === 1
        ? _c("span", { staticClass: "icon-step" }, [_vm._v("2")])
        : _c("span", { staticClass: "icon" }, [
            _c("img", {
              attrs: {
                src: require("../assets/images/icons/icon_status_green_fill_small.svg"),
                alt: "",
              },
            }),
          ]),
      _vm._v(
        " " + _vm._s(_vm.$t("complete_registration.step_2_tab_title")) + " "
      ),
    ]),
    _c("div", { staticClass: "tab", class: { "tab-active": _vm.step === 3 } }, [
      _c("span", { staticClass: "icon-step" }, [_vm._v("3")]),
      _vm._v(
        " " + _vm._s(_vm.$t("complete_registration.step_3_tab_title")) + " "
      ),
    ]),
    _c(
      "div",
      { staticClass: "tab-content" },
      [
        _vm.step === 1
          ? [
              _c("img", {
                attrs: {
                  src: require("../assets/images/account-activation-account-created.svg"),
                  alt: "",
                },
              }),
              _c("h3", [
                _vm._v(_vm._s(_vm.$t("complete_registration.step_1_heading"))),
              ]),
              _c(
                "base-button-link",
                {
                  staticClass: "primary",
                  attrs: { to: "/portal/coupon/redeem" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("complete_registration.step_1_btn_label")) +
                      " "
                  ),
                ]
              ),
            ]
          : [
              _c("div", { staticClass: "step-3" }, [
                _vm._m(1),
                _c(
                  "div",
                  { staticClass: "step-3-desc" },
                  [
                    [
                      _c("h3", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("complete_registration.step_3_heading_1", {
                                service_name: _vm.productName,
                              })
                            ) +
                            " "
                        ),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("complete_registration.step_3_body_1", {
                              license_size: _vm.licenseSize,
                            })
                          ) +
                          " "
                      ),
                    ],
                    _c(
                      "div",
                      { staticClass: "btn-action" },
                      [
                        _c(
                          "base-button-link",
                          {
                            staticClass: "primary",
                            attrs: { to: "/portal/welcome" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "complete_registration.step_3_btn_label"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]),
            ],
      ],
      2
    ),
    _vm.step === 1
      ? _c("div", { staticClass: "tab-footer" }, [
          _c(
            "p",
            [
              _vm._v(" " + _vm._s(_vm.haveNoCodeText) + " "),
              _c("router-link", { attrs: { to: "/portal/start-trial" } }, [
                _vm._v(_vm._s(_vm.haveNoCodeLinkText)),
              ]),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("img", {
        attrs: {
          src: require("../assets/images/icons/icon_status_green_fill_small.svg"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "step-3-image" }, [
      _c("img", {
        attrs: {
          src: require("../assets/images/account-activation-success.svg"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }