<template>
  <one-col-box-grid-content-box>
    <h2>{{ $t('account.hl_contact') }}</h2>
    <p>{{ $t('account.sl_contact') }}</p>
    <base-button-link
      id="btn-edit-account"
      class="primary"
      to="/portal/contact/update"
      data-track-event="navigate"
      data-track-name="account-update"
    >
      {{ $t('account.btn_editdetails') }}
    </base-button-link>
    <base-button-link
      id="btn-mobile-logout"
      class="primary mobile-only"
      to="/logout"
      data-track-event="navigate"
      data-track-name="logout"
    >
      {{ $t('account.btn_log_out') }}
    </base-button-link>
  </one-col-box-grid-content-box>
</template>

<script>
import OneColBoxGridContentBox from './OneColBoxGridContentBox.vue'
import BaseButtonLink from '@/components/BaseButtonLink.vue'
export default {
  components: {
    OneColBoxGridContentBox,
    BaseButtonLink
  }
}
</script>

<style lang="scss" scoped></style>
