var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "other-info" }, [
    _vm.showDaysUntilExpiration
      ? _c("span", { staticClass: "days-until-expiration" }, [
          _c("span", { staticClass: "text" }, [
            _vm._v(_vm._s(_vm.$t("statusbar.ntc_daysleft"))),
          ]),
          _c("span", { staticClass: "circle blue" }, [
            _c("span", { staticClass: "value" }, [
              _vm._v(_vm._s(_vm.daysUntilExpirationShown)),
            ]),
          ]),
        ])
      : _vm._e(),
    _c("span", { staticClass: "licenses" }, [
      _c("span", { staticClass: "text" }, [
        _vm._v(_vm._s(_vm.$t("statusbar.ntc_remaining_x_licenses"))),
      ]),
      _vm.availableLicenses !== null
        ? _c("span", { staticClass: "circle blue" }, [
            _c("span", { staticClass: "value" }, [
              _vm.totalLicenses > 0
                ? _c("span", { staticClass: "available" }, [
                    _vm._v(_vm._s(_vm.availableLicenses)),
                  ])
                : _vm._e(),
              _vm.totalLicenses > 0 ? _c("span", [_vm._v(" / ")]) : _vm._e(),
              _c("span", { staticClass: "total" }, [
                _vm._v(_vm._s(_vm.totalLicenses)),
              ]),
            ]),
          ])
        : _c("span", { staticClass: "loader-wrapper" }, [
            _c("img", {
              attrs: { src: require("@/assets/images/spinner-white.svg") },
            }),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }