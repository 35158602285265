<template>
  <div class="login-left-section">
    <div class="iframe-wrapper">
      <dynamic-iframe title="login" :d-src="iframeSource" />
    </div>
  </div>
</template>
<script>
import iframeSource from '@/mixins/iframeSource'
import DynamicIframe from '@/components/DynamicIframe.vue'
export default {
  data() {
    return {
      loginUrl: process.env.VUE_APP_LOGIN_IFRAME_URL
    }
  },
  components: {
    DynamicIframe
  },
  mixins: [iframeSource],
  computed: {
    iframeSource() {
      return this.alterIframeUrlByParams(this.loginUrl, 'login')
    }
  }
}
</script>

<style lang="scss" scoped>
.login-left-section {
  background: #ffffff;
  height: 100%;

  .iframe-wrapper {
    padding: 8px 38px 0 38px;
    line-height: 0;

    iframe {
      width: 100%;
      height: 100%;
      min-height: 512px;
    }
  }
}
// IE11
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .login-left-section {
    .iframe-wrapper {
      iframe {
        min-height: 518px;
      }
    }
  }
}
</style>
