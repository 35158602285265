<template>
  <div class="view-content">
    <div class="apply-coupon" v-if="couponValid">
      <slot name="apply-coupon" />
    </div>
    <div class="enter-coupon" v-else>
      <slot name="enter-coupon" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    couponValid: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
.apply-coupon {
  i {
    color: $font-color;
  }

  p {
    color: $font-color;
  }

  .grid {
    margin-bottom: 0;

    + p {
      margin-bottom: 30px;
      margin-top: 10px;
    }

    > div {
      > span {
        font-weight: 700;
      }
      &:nth-child(1) {
        grid-row: 1;
        grid-column: 1;
      }
      &:nth-child(2) {
        grid-row: 4;
        grid-column: 1;
      }
      &:nth-child(3) {
        grid-row: 7;
        grid-column: 1;
      }
      &:nth-child(4) {
        grid-row: 1;
        grid-column: 2;
      }
      &:nth-child(5) {
        grid-row: 4;
        grid-column: 2;
      }
      &:nth-child(6) {
        grid-row: 7;
        grid-column: 2;
      }
      &:nth-child(7) {
        grid-row: 2;
        grid-column: 2;
      }
      &:nth-child(8) {
        grid-row: 5;
        grid-column: 2;
      }
      &:nth-child(9) {
        grid-row: 8;
        grid-column: 2;
      }
      &:nth-child(10) {
        grid-row: 3;
        grid-column: 2;
      }
      &:nth-child(11) {
        grid-row: 6;
        grid-column: 2;
      }
      &:nth-child(12) {
        grid-row: 9;
        grid-column: 2;
      }
      &:nth-child(n + 7) {
        > span {
          font-weight: 400;
          font-style: italic;
        }
      }
    }
  }
}
.success-notice {
  + h3 {
    margin-top: 40px;
  }

  h3 {
    color: $ok-color;
    font-size: 1.71429rem;
    margin: 0 0 5px 0;

    > span.icon {
      width: 26px;
      height: 26px;
      vertical-align: top;
      display: inline-block;

      > img {
        vertical-align: top;
      }
    }
  }
}
input {
  transition-duration: 0.2s;
}
input.error {
  border-color: $danger-color;
}
p {
  &.error-message {
    color: $danger-color;
    padding: 7px 0;
    font-size: 1em;
    margin-top: 0;
  }
}
h2 {
  font-size: 2rem;
  color: $primary-color;
  font-weight: 600;
  line-height: 1.21886em;
  margin: 20px 0 40px 0;
}

h3 {
  font-size: 1.57143rem;
  font-weight: 600;
  margin: 20px 0;
}

.flex-btn + .flex-btn {
  margin-top: 10px;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  color: #666;
  background: #f5f5f5;
  padding: 10px 12px;
  margin-bottom: 40px;

  > div {
    > span {
      font-weight: 600;
      font-size: 1rem;
    }
    &:nth-child(1) {
      grid-row: 1;
      grid-column: 1;
    }
    &:nth-child(2) {
      grid-row: 2;
      grid-column: 1;
    }
    &:nth-child(3) {
      grid-row: 3;
      grid-column: 1;
    }
    &:nth-child(4) {
      grid-row: 1;
      grid-column: 2;
    }
    &:nth-child(5) {
      grid-row: 2;
      grid-column: 2;
    }
    &:nth-child(6) {
      grid-row: 3;
      grid-column: 2;
    }
  }
}
.input-wrapper {
  &.loading {
    position: relative;

    &::before {
      content: '';
      background: url('../assets/images/spinner.svg');
      background-repeat: repeat;
      background-size: auto;
      position: absolute;
      z-index: 2;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center right;
      height: 50px;
    }
  }

  + .flex-btn {
    margin-top: 40px;
  }

  + p:not(.error-message) {
    margin-top: 32px;
    color: #666;
    margin-bottom: 16px;
  }
}

@media (min-width: $desktop-min-width) {
  .apply-coupon {
    .grid {
      border-bottom: 1px solid #dedede;

      > div {
        &.title {
          grid-row: auto;
          grid-column: auto;
          border-bottom: 1px solid #dedede;
        }
        &.value {
          grid-row: auto;
          grid-column: auto;
          background: none;
          border-top: none;
          border-bottom: none;
        }
      }
    }
  }
  .view-content {
    text-align: left;
  }
  h2 {
    font-size: 2.57143rem;
  }
  h3 {
    text-align: left;
  }
  p {
    text-align: left;
  }
  .input-wrapper {
    text-align: left;

    &.limited-width {
      max-width: 345px;

      &.loading {
        &::before {
          max-width: 345px;
        }
      }
    }
  }
  input {
    width: auto;
    min-width: 350px;
  }
  .grid {
    text-align: left;
    background: none;
    border-top: none;
    border-bottom: none;
    padding: 0;

    > div {
      padding: 12px 10px;

      &.value {
        border-top: 1px solid #dedede;
        border-bottom: 1px solid #dedede;
        background: #f5f5f5;

        > span {
          font-weight: 500;
        }
      }
      &.title {
        padding-top: 0;
      }
      > span {
        font-size: 1.14286rem;
      }
    }
  }
  .flex-btn {
    width: auto;
    min-width: 145px;

    + .flex-btn {
      margin-top: 0;
      margin-left: 10px;
    }
  }
  .grid {
    grid-template-columns: repeat(3, 1fr);

    > div {
      &.title {
        grid-row: auto;
        grid-column: auto;
      }
      &.value {
        grid-row: auto;
        grid-column: auto;
      }
    }
  }
}

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .grid {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr;

    > div {
      &:nth-child(1) {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
      }
      &:nth-child(2) {
        -ms-grid-row: 2;
        -ms-grid-column: 1;
      }
      &:nth-child(3) {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
      }
      &:nth-child(4) {
        -ms-grid-row: 1;
        -ms-grid-column: 2;
      }
      &:nth-child(5) {
        -ms-grid-row: 2;
        -ms-grid-column: 2;
      }
      &:nth-child(6) {
        -ms-grid-row: 3;
        -ms-grid-column: 2;
      }
    }
  }
  .apply-coupon {
    .grid {
      > div {
        &:nth-child(1) {
          -ms-grid-row: 1;
          -ms-grid-column: 1;
        }
        &:nth-child(2) {
          -ms-grid-row: 4;
          -ms-grid-column: 1;
        }
        &:nth-child(3) {
          -ms-grid-row: 7;
          -ms-grid-column: 1;
        }
        &:nth-child(4) {
          -ms-grid-row: 1;
          -ms-grid-column: 2;
        }
        &:nth-child(5) {
          -ms-grid-row: 4;
          -ms-grid-column: 2;
        }
        &:nth-child(6) {
          -ms-grid-row: 7;
          -ms-grid-column: 2;
        }
        &:nth-child(7) {
          -ms-grid-row: 2;
          -ms-grid-column: 2;
        }
        &:nth-child(8) {
          -ms-grid-row: 5;
          -ms-grid-column: 2;
        }
        &:nth-child(9) {
          -ms-grid-row: 8;
          -ms-grid-column: 2;
        }
        &:nth-child(10) {
          -ms-grid-row: 3;
          -ms-grid-column: 2;
        }
        &:nth-child(11) {
          -ms-grid-row: 6;
          -ms-grid-column: 2;
        }
        &:nth-child(12) {
          -ms-grid-row: 9;
          -ms-grid-column: 2;
        }
      }
    }
  }
}

@media screen and (-ms-high-contrast: active) and (min-width: $desktop-min-width),
  screen and (-ms-high-contrast: none) and (min-width: $desktop-min-width) {
  .grid {
    -ms-grid-columns: 1fr 1fr 1fr;

    > div {
      &:nth-child(1) {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
      }
      &:nth-child(2) {
        -ms-grid-row: 1;
        -ms-grid-column: 2;
      }
      &:nth-child(3) {
        -ms-grid-row: 1;
        -ms-grid-column: 3;
      }
      &:nth-child(4) {
        -ms-grid-row: 2;
        -ms-grid-column: 1;
      }
      &:nth-child(5) {
        -ms-grid-row: 2;
        -ms-grid-column: 2;
      }
      &:nth-child(6) {
        -ms-grid-row: 2;
        -ms-grid-column: 3;
      }
    }
  }

  .apply-coupon {
    .grid {
      > div {
        &:nth-child(1) {
          -ms-grid-row: 1;
          -ms-grid-column: 1;
        }
        &:nth-child(2) {
          -ms-grid-row: 1;
          -ms-grid-column: 2;
        }
        &:nth-child(3) {
          -ms-grid-row: 1;
          -ms-grid-column: 3;
        }
        &:nth-child(4) {
          -ms-grid-row: 2;
          -ms-grid-column: 1;
        }
        &:nth-child(5) {
          -ms-grid-row: 2;
          -ms-grid-column: 2;
        }
        &:nth-child(6) {
          -ms-grid-row: 2;
          -ms-grid-column: 3;
        }
        &:nth-child(7) {
          -ms-grid-row: 3;
          -ms-grid-column: 1;
        }
        &:nth-child(8) {
          -ms-grid-row: 3;
          -ms-grid-column: 2;
        }
        &:nth-child(9) {
          -ms-grid-row: 3;
          -ms-grid-column: 3;
        }
        &:nth-child(10) {
          -ms-grid-row: 4;
          -ms-grid-column: 1;
        }
        &:nth-child(11) {
          -ms-grid-row: 4;
          -ms-grid-column: 2;
        }
        &:nth-child(12) {
          -ms-grid-row: 4;
          -ms-grid-column: 3;
        }
      }
    }
  }
}
</style>
