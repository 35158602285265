export default {
  formatPrice(price, currency, locale) {
    if (!currency) {
      return ''
    }
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 2
    })

    return formatter.format(price)
  }
}
