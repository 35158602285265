var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "action-button" },
    [
      _c("base-purchase-button", {
        ref: "BasePurchaseButton",
        style: { height: _vm.heightCss },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }