var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-header-bar-arrow-menu", {
    attrs: {
      id: "account-menu",
      "data-track-event": "navigate",
      "data-track-name": "account-menu",
      "data-body-class-on-open": "narrow-menu-open",
      to: "/portal/contact",
    },
    scopedSlots: _vm._u([
      {
        key: "main-link",
        fn: function () {
          return [
            _c(
              "span",
              {
                staticClass: "circle blue css-tooltip",
                attrs: { "tooltip-text": _vm.customerEmail },
              },
              [_vm._v(_vm._s(_vm.customerFullNameOrEmailFirstLetter))]
            ),
            _c("span", [_vm._v("Menu")]),
          ]
        },
        proxy: true,
      },
      {
        key: "menu-items",
        fn: function () {
          return [
            _c(
              "base-header-bar-link",
              {
                attrs: {
                  "data-track-event": "navigate",
                  "data-track-name": "account-details",
                  to: "/portal/contact",
                },
              },
              [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("header.lnk_account_settings"))),
                ]),
              ]
            ),
            _c(
              "base-header-bar-link",
              {
                attrs: {
                  "data-track-event": "navigate",
                  "data-track-name": "logout",
                  to: "/logout",
                },
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("header.lnk_log_out")))])]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }