var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal-popup",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isOpen,
          expression: "isOpen",
        },
      ],
      staticClass: "activation",
      attrs: {
        id: "activation-instructions-popup",
        "is-wide": "",
        "display-close-button": "",
        "on-close-click": _vm.closeWithOptionalRefresh,
        "more-padding": "",
        "less-close-padding": "",
        "top-close-id": "activation-instructions-top-close",
      },
    },
    [
      _c("popup-content", {
        attrs: {
          "close-popup": _vm.closeWithOptionalRefresh,
          "service-id": _vm.serviceId,
          title: _vm.title,
          "list-title": _vm.listTitle,
          "list-subtitle": _vm.listSubtitle,
          "list-items": _vm.listItems,
          "footer-start-text": _vm.footerStartText,
          "footer-terms-link": _vm.footerTermsLink,
          "footer-text-in-between-links": _vm.footerTextInBetweenLinks,
          "footer-privacy-link": _vm.footerPrivacyLink,
          "footer-end-text": _vm.footerEndText,
          "terms-and-conditions-url": _vm.termsAndConditionsUrl,
          "privacy-policy-url": _vm.privacyPolicyUrl,
          vouchers: _vm.vouchers,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }