var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "one-col-box-grid-content-box",
    [
      _vm.showSuccess
        ? [
            _c("h2", [_vm._v(_vm._s(_vm.$t("cancel_autorenew.hl_cancelled")))]),
            _c("p", [_vm._v(_vm._s(_vm.noticeCancelled))]),
            _c(
              "base-button-link",
              {
                staticClass: "neutral",
                attrs: { to: "/portal/order" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.resetData.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("cancel_autorenew.btn_back")) + " ")]
            ),
          ]
        : _vm.showError
        ? [
            _c("h2", [
              _vm._v(_vm._s(_vm.$t("cancel_autorenew.hl_cancel_error"))),
            ]),
            _c("p", [
              _vm._v(" " + _vm._s(_vm.$t("cancel_autorenew.ntc_cancel_error"))),
              _c("br"),
              _vm._v(
                _vm._s(_vm.$t("cancel_autorenew.ntc_cancel_error_retry")) + " "
              ),
            ]),
            _c(
              "base-button-link",
              { staticClass: "primary", attrs: { to: "/portal/order" } },
              [_vm._v(" " + _vm._s(_vm.$t("cancel_autorenew.btn_back")) + " ")]
            ),
          ]
        : [
            _c("h2", [
              _vm._v(_vm._s(_vm.$t("cancel_autorenew.hl_cancel_subscription"))),
            ]),
            _c("p", [_vm._v(_vm._s(_vm.noticeCancel))]),
            _c(
              "base-button-link",
              {
                staticClass: "alert",
                class: { loading: _vm.loading },
                attrs: { "prevent-click": "", to: "/portal/order" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.cancelAutorenew.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("cancel_autorenew.btn_cancelsubscription")) +
                    " "
                ),
              ]
            ),
            _c(
              "base-button-link",
              {
                staticClass: "neutral",
                attrs: { to: "/portal/order" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.resetData.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("cancel_autorenew.btn_cancelcancelsubscription")
                    ) +
                    " "
                ),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }