<template>
  <base-settings-content-section id="discount-code-id" v-if="isVisible">
    <h2>{{ $t('coupon_renew.hl_discount_code') }}</h2>
    <div class="discount-applied" v-if="isDiscountApplied">
      <p class="success">
        <span class="icon">
          <img
            src="../assets/images/icons/icon_status_green_small.svg"
            alt=""
          />
        </span>
        <span>
          {{ $t('coupon_renew.code_applied') }}
        </span>
      </p>
      <div class="price-block">
        <p class="price">
          <span class="original-price center-strike">{{
            originalPriceProcessed
          }}</span
          >&nbsp;&nbsp;&nbsp;&nbsp;<span class="discounted-price">{{
            discountedPriceProcessed
          }}</span>
        </p>
        <p class="text legend" v-if="isAutomaticSubscription">
          {{ $t('coupon_renew.discounted_price_billed') }}
        </p>
        <base-purchase-button class="primary" v-else />
      </div>
    </div>
    <div class="discount-form" v-else>
      <base-loading-input-wrapper
        type="text"
        :placeholder="inputPlaceholder"
        v-model="codeInput"
        @blur="resetForm"
        @keyup="validateFormErrors"
        class="one-line-desktop"
        :class="{ error: formError }"
        ref="code-input-wrapper"
        :loading="isApplyingCode"
        :disabled="isApplyingCode"
      />
      <template v-if="formError">
        <p v-if="!$v.codeInput.required" class="error-message">
          {{ $t('coupon_renew.form_field_required') }}
        </p>
        <p v-else class="error-message">
          {{ $t('subscription.error_discount_code_invalid') }}
        </p>
      </template>
      <base-button
        class="neutral"
        :class="{ disabled: isButtonDisabled }"
        @click="validateCode"
        :disabled="isButtonDisabled"
        data-track-event="navigate"
        data-track-name="apply-discount"
      >
        {{ $t('coupon_renew.btn_apply_code') }}
      </base-button>
    </div>
  </base-settings-content-section>
</template>

<script>
import DiscountService from '@/services/DiscountService.js'
import PriceService from '@/services/PriceService.js'
import { mapState, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import BaseSettingsContentSection from './BaseSettingsContentSection.vue'
import BaseButton from './BaseButton.vue'
import BaseLoadingInputWrapper from './BaseLoadingInputWrapper.vue'
import BasePurchaseButton from './BasePurchaseButton'
import DataMixin from '@/mixins/data'

export default {
  data() {
    return {
      isApplyingCode: false,
      discountIsValid: null,
      codeInput: '',
      sentInput: '',
      originalPrice: '',
      discountedPrice: '',
      currency: '',
      priceLocale: '',
      isLoading: false
    }
  },
  mixins: [DataMixin],
  components: {
    BaseSettingsContentSection,
    BaseLoadingInputWrapper,
    BaseButton,
    BasePurchaseButton
  },
  activated() {
    if (!this.isLoading && this.isVisible) {
      this.isLoading = true
      DiscountService.fetchDiscountedPrice().then((response) => {
        if (!response || response.errorCode) {
          this.isLoading = false
          return
        }
        this.originalPrice = response.originalPrice
        this.discountedPrice = response.discountedPrice
        this.currency = response.currency
        this.isLoading = false

        if (!this.originalPrice && !this.discountedPrice) {
          this.$store.dispatch('customer/fetch')
        }
      })
    }
  },
  computed: {
    ...mapState({
      isAutomaticSubscription: (state) =>
        state.customer.contract.isAutomaticSubscription,
      totalPrice: (state) => state.ui.cart.total.price,
      totalPriceCurrency: (state) => state.ui.cart.total.currency,
      idpPrice: (state) => state.ui.cart.idp.price,
      idpPriceCurrency: (state) => state.ui.cart.idp.currency,
      freedomePrice: (state) => state.ui.cart.freedome.price,
      freedomePriceCurrency: (state) => state.ui.cart.freedome.currency,
      safePrice: (state) => state.ui.cart.safe.price,
      safePriceCurrency: (state) => state.ui.cart.safe.currency,
      discountAllowed: (state) => state.customer.contract.discountAllowed,
      currentLocale: (state) => state.ui.currentLocale,
      contractDiscountApplied: (state) =>
        state.customer.contract.isDiscountApplied
    }),
    ...mapGetters({
      isSenseSubscription: 'customer/contract/isSenseSubscription'
    }),
    formError() {
      return this.discountIsValid === false || this.$v.codeInput.$error
    },
    inputPlaceholder() {
      return this.$t('coupon_renew.msg_type_discount_code') + ' ...'
    },
    isButtonDisabled() {
      return this.$v.codeInput.$dirty && this.$v.codeInput.$error
    },
    isVisible() {
      return (
        (this.discountAllowed || this.isDiscountApplied) &&
        !this.isSenseSubscription
      )
    },
    isDiscountApplied() {
      return this.discountIsValid || this.contrectDiscountAppliedConfirmed
    },
    contrectDiscountAppliedConfirmed() {
      return (
        this.contractDiscountApplied &&
        this.originalPrice &&
        this.discountedPrice &&
        this.originalPrice !== this.discountedPrice
      )
    },
    originalPriceProcessed() {
      return PriceService.formatPrice(
        this.originalPrice,
        this.currency,
        this.currentLocale.replace('_', '-')
      )
    },
    discountedPriceProcessed() {
      return PriceService.formatPrice(
        this.discountedPrice,
        this.currency,
        this.currentLocale.replace('_', '-')
      )
    }
  },
  methods: {
    async validateCode() {
      if (this.$v.codeInput.$invalid) {
        this.$v.codeInput.$touch()
        this.$refs['code-input-wrapper'].$refs['input'].$el.focus()
        return
      }

      this.isApplyingCode = true
      this.sentInput = this.codeInput
      const response = await this.preHandleResponse(() =>
        DiscountService.postDiscountApply(
          this.codeInput,
          this.$store.state.customer.token
        )
      )

      if (!response) {
        this.isApplyingCode = false
        return
      }

      this.isApplyingCode = false
      this.discountedPrice = response.discountedPrice
      this.originalPrice = response.originalPrice
      this.currency = response.currency
      this.priceLocale = response.locale
      this.discountIsValid = !response.errorCode

      if (this.discountIsValid) {
        this.$store.dispatch('ui/fetchCart')
      }
    },
    validateFormErrors() {
      this.$v.codeInput.$touch()
      this.discountIsValid = null
    },
    resetForm() {
      this.$v.codeInput.$reset()
    }
  },
  validations: {
    codeInput: {
      required
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  &.error-message {
    color: $danger-color;
    padding: 7px 0;
    font-size: 1em;
    margin-top: 0;
  }
}
.price-block {
  text-align: center;

  p {
    margin: 16px 0;
  }
}

.center-strike {
  position: relative;
  white-space: nowrap;

  &::before {
    border-top: 1px solid #666666;
    position: absolute;
    content: '';
    right: 0;
    bottom: 42%;
    left: -17%;
    width: 130%;
  }
}

.original-price {
  font-size: 1.45rem;
  font-weight: 400;
  color: #666666;
  vertical-align: middle;
}

.discounted-price {
  color: #000;
  font-size: 1.7rem;
  font-weight: 400;
  vertical-align: middle;
}

p.success {
  margin: 0;

  > span {
    color: $ok-color;
    &.icon {
      width: 26px;
      height: 26px;
      display: inline-block;
      vertical-align: top;
      margin-right: 5px;
      margin-top: -1px;

      > img {
        vertical-align: top;
      }
    }
  }
}
@media (min-width: $desktop-min-width) {
  .discount-applied {
    display: flex;

    p.success {
      flex: 1 0 auto;
    }

    .price-block {
      p {
        margin-top: 0;
      }
    }
  }
  .discount-form {
    margin-bottom: 5px;
  }
  ::v-deep input {
    min-width: 281px;
  }
  .error-message {
    position: absolute;
    padding-bottom: 0;
    margin-bottom: 0;
    padding-top: 3px;
    margin-left: 13px;

    + .flex-btn {
      margin-top: 0;
      margin-left: 10px;
    }
  }
}
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .center-strike {
    &::before {
      bottom: 26%;
    }
  }
}
</style>
