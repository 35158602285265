<template>
  <div
    class="avenue-iframe"
    v-if="customerIsActivated"
    :style="{ marginTop: top, height }"
  >
    <iframe
      :src="iframeUrlSafe"
      :style="{
        position: showSafeIframe ? 'relative' : 'absolute',
        visibility: showSafeIframe ? 'visible' : 'hidden',
        height: currentIframeHeight
      }"
      frameborder="0"
      @load="markSafeLoadedAndLoadNextIframe"
      ref="safe-iframe"
      v-if="loadSafeIframe"
    />
    <iframe
      v-if="loadFreedomeIframe"
      :src="iframeUrlFreedome"
      :style="{
        position: showFreedomeIframe ? 'relative' : 'absolute',
        visibility: showFreedomeIframe ? 'visible' : 'hidden',
        height: currentIframeHeight
      }"
      frameborder="0"
      @load="markFreedomeLoadedAndLoadNextIframe"
      ref="freedome-iframe"
    />
    <iframe
      v-if="loadKeyIframe"
      :src="iframeUrlKey"
      :style="{
        position: showKeyIframe ? 'relative' : 'absolute',
        visibility: showKeyIframe ? 'visible' : 'hidden',
        height: currentIframeHeight
      }"
      frameborder="0"
      @load="markKeyLoadedAndLoadNextIframe"
      ref="key-iframe"
    />
    <iframe
      v-if="loadKeyIdpIframe"
      :src="iframeUrlKeyIdp"
      :style="{
        position: showKeyIdpIframe ? 'relative' : 'absolute',
        visibility: showKeyIdpIframe ? 'visible' : 'hidden',
        height: currentIframeHeight
      }"
      frameborder="0"
      @load="markKeyIdpLoadedAndLoadNextIframe"
      ref="idp-iframe"
    />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'

export default {
  data() {
    return {
      iframePath: '',
      visibleIframe: '',
      safeIframeLoaded: false,
      freedomeIframeLoaded: false,
      keyIframeLoaded: false,
      keyIdpIframeLoaded: false,
      headerHeight: 50,
      statusbarHeight: 0,
      iframeResizerLoaded: false,
      iframeResizerToldHeight: 578,
      resizing: false,
      windowInnerHeight: null,
      windowInnerWidth: null,
      windowResized: false,
      showIframes: true,
      allowSafeIframeToBeLoaded: false,
      allowFreedomeIframeToBeLoaded: false,
      allowKeyIframeToBeLoaded: false,
      allowKeyIdpIframeToBeLoaded: false,
      iframeResizerScriptsAdded: false
    }
  },
  mounted() {
    this.$root.$on('statusbar-height-changed', this.updateStatusbarHeight)
    this.$root.$on('header-height-changed', this.updateHeaderHeight)
    this.$root.$on(
      'product-view-entered',
      this.updateVisibleIframeAndInitialise
    )
    this.$root.$on('purchase-occured', this.reloadAllIframesIfDataloaded)
    this.$root.$on('product-view-route-updated', this.updateVisibleIframe)
    this.$root.$on('product-view-route-left', this.updateVisibleIframe)
    this.$root.$on(
      'customer-api-call-finished',
      this.updateVisibleIframeAndInitialise
    )
  },
  beforeDestroy() {
    this.$root.$off('statusbar-height-changed', this.updateStatusbarHeight)
    this.$root.$off('header-height-changed', this.updateHeaderHeight)
    this.$root.$off(
      'product-view-entered',
      this.updateVisibleIframeAndInitialise
    )
    this.$root.$off('purchase-occured', this.reloadAllIframesIfDataloaded)
    this.$root.$off('product-view-route-updated', this.updateVisibleIframe)
    this.$root.$off('product-view-route-left', this.updateVisibleIframe)
    this.$root.$off(
      'customer-api-call-finished',
      this.updateVisibleIframeAndInitialise
    )
  },
  activated() {
    this.windowInnerWidth = window.innerWidth
    this.windowInnerHeight = window.innerHeight
    window.addEventListener('resize', this.onResize)
  },
  deactivated() {
    window.removeEventListener('resize', this.onResize)
    this.windowResized = false
  },
  watch: {
    allIframesLoaded(newValue, oldValue) {
      if (oldValue === false && newValue === true) {
        this.$root.$emit('all-product-iframes-loaded')
      }
    },
    iframeResizerActive(newValue, oldValue) {
      if (newValue === true && oldValue === false) {
        document.body.classList.add('iframe-resizer-active')
      }

      if (newValue === false && oldValue === true) {
        document.body.classList.remove('iframe-resizer-active')
      }
    },
    iframeUrlSafe(newValue, oldValue) {
      this.safeIframeLoaded = false
      this.freedomeIframeLoaded = false
      this.keyIframeLoaded = false
      this.keyIdpIframeLoaded = false
      this.allowFreedomeIframeToBeLoaded = false
      this.allowKeyIframeToBeLoaded = false
      this.allowKeyIdpIframeToBeLoaded = false
    },
    iframeUrlKey(newValue, oldValue) {
      if (this.isIDPSubscription) {
        this.keyIframeLoaded = false
        this.keyIdpIframeLoaded = false
        this.allowKeyIdpIframeToBeLoaded = false
      }
    }
  },
  computed: {
    ...mapGetters({
      customerIsActive: 'customer/isActive',
      isSafeSubscription: 'customer/contract/isSafeSubscription',
      isIDPSubscription: 'customer/contract/isIDPSubscription',
      isFreedomeSubscription: 'customer/contract/isFreedomeSubscription',
      isTotalSubscription: 'customer/contract/isTotalSubscription',
      isEppSubscription: 'customer/contract/isEppSubscription',
      isEppIdpSubscription: 'customer/contract/isEppIdpSubscription',
      isEppVpnSubscription: 'customer/contract/isEppVpnSubscription',
      isTotalStandardSubscription:
        'customer/contract/isTotalStandardSubscription',
      isTotalStandardIdMonitoringSubscription:
        'customer/contract/isTotalStandardIdMonitoringSubscription',
      isSafeInstallable: 'customer/licenses/isSafeInstallable',
      isFreedomeInstallable: 'customer/licenses/isFreedomeInstallable',
      isKeyInstallable: 'customer/licenses/isKeyInstallable',
      isIdmInstallable: 'customer/licenses/isIdmInstallable',
      isTotalStandaloneInstallable:
        'customer/licenses/isTotalStandaloneInstallable',
      customerIsSuspended: 'customer/isSuspended'
    }),
    ...mapState({
      appDataLoaded: (state) => state.customer.remoteDataLoaded,
      customerIsLoggedIn: (state) => state.customer.isLoggedIn,
      iframeUrl: (state) => state.customer.safeAvenueIframeUrl,
      uiCurrentLocale: (state) => state.ui.currentLocale
    }),
    visibleIframeElement() {
      if (this.visibleIframe === 'KEY') {
        return this.$refs['key-iframe']
      }

      if (this.visibleIframe === 'FREEDOME') {
        return this.$refs['freedome-iframe']
      }

      if (this.visibleIframe === 'IDP') {
        return this.$refs['idp-iframe']
      }

      return this.$refs['safe-iframe']
    },
    customerIsActivated() {
      return (
        this.customerIsLoggedIn &&
        this.appDataLoaded &&
        (this.customerIsActive || this.customerIsSuspended)
      )
    },
    currentIframeHeight() {
      return this.iframeResizerActive &&
        this.spaceToFill > this.iframeResizerToldHeight
        ? this.iframeResizerToldHeight + 'px'
        : '100%'
    },
    iframeResizerActive() {
      if (!this.iframeResizerLoaded || !this.iframeResizerToldHeight) {
        return false
      }

      return this.spaceToFill < this.iframeResizerToldHeight
    },
    spaceToFill() {
      let footerHeight = this.windowInnerWidth > 768 ? 45 : 0
      return this.windowInnerHeight - (parseInt(this.top) + footerHeight)
    },
    height() {
      if (this.iframeResizerLoaded && this.iframeResizerToldHeight) {
        if (!this.iframeResizerActive) {
          return this.spaceToFill + 'px'
        }

        return this.iframeResizerToldHeight + 'px'
      }

      return 'calc(100% - ' + this.top + ')'
    },
    top() {
      return parseInt(this.statusbarHeight) + parseInt(this.headerHeight) + 'px'
    },
    iframeUrlSafe() {
      if (!this.loadSafeIframe || !this.allowSafeIframeToBeLoaded) {
        return ''
      }

      return this.iframeUrlAltered
    },
    iframeUrlFreedome() {
      if (!this.loadFreedomeIframe || !this.allowFreedomeIframeToBeLoaded) {
        return ''
      }

      return this.iframeUrlAltered + '#/privacy/'
    },
    iframeUrlKey() {
      if (!this.loadKeyIframe || !this.allowKeyIframeToBeLoaded) {
        return ''
      }

      return this.iframeUrlAltered + '#/password/'
    },
    iframeUrlKeyIdp() {
      if (!this.loadKeyIdpIframe || !this.allowKeyIdpIframeToBeLoaded) {
        return ''
      }
      // This one has to be v5 URL, and everything else that refer to this url has to be updated as well.
      let idpUrl = this.iframeUrlAltered.replace('?', '/?').split('/')
      idpUrl[4] = '-v5'
      idpUrl.splice(6, 0, 'id-protection')
      idpUrl = idpUrl.join('/')
      return idpUrl
    },
    iframeUrlAltered() {
      let lang = this.uiCurrentLocale.replace('_', '-').toLowerCase()
      let patt = /&lang=../
      return this.iframeUrl.replace(patt, '&lang=' + lang)
    },
    showSafeIframe() {
      return this.visibleIframe === 'SAFE'
    },
    showFreedomeIframe() {
      return this.visibleIframe === 'FREEDOME'
    },
    showKeyIframe() {
      return this.visibleIframe === 'KEY'
    },
    showKeyIdpIframe() {
      return this.visibleIframe === 'IDP'
    },
    loadSafeIframe() {
      return this.isSafeInstallable && this.showIframes
    },
    loadFreedomeIframe() {
      return this.isFreedomeInstallable && this.showIframes
    },
    loadKeyIframe() {
      return this.isKeyInstallable && this.showIframes
    },
    loadKeyIdpIframe() {
      return this.isIdmInstallable && this.showIframes
    },
    allIframesLoaded() {
      if (this.isFreedomeSubscription && !this.isTotalStandaloneInstallable) {
        return this.freedomeIframeLoaded
      } else if (this.isIDPSubscription && !this.isTotalStandaloneInstallable) {
        return this.keyIframeLoaded && this.keyIdpIframeLoaded
      } else if (
        this.isSafeSubscription ||
        this.isEppSubscription ||
        this.isEppVpnSubscription
      ) {
        return this.safeIframeLoaded
      }
      return (
        this.safeIframeLoaded &&
        this.keyIdpIframeLoaded &&
        (this.freedomeIframeLoaded || !this.isFreedomeInstallable) &&
        (this.keyIframeLoaded || !this.isKeyInstallable)
      )
    }
  },
  methods: {
    onResizeFrame() {
      this.windowInnerWidth = window.innerWidth
      this.windowInnerHeight = window.innerHeight
      this.resizing = false
      this.windowResized = true
    },
    onResize() {
      if (this.resizing) {
        return
      }

      this.resizing = true

      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(this.onResizeFrame)
      } else {
        setTimeout(this.onResizeFrame, 66)
      }
    },
    updateVisibleIframe(toPath, fromPath) {
      if (toPath === '/portal/welcome') {
        this.visibleIframe = 'SAFE'
      }

      // this is separated because we do not want people going to /portal/key and get to see freedome iframe
      if (this.isFreedomeSubscription && !this.isTotalStandaloneInstallable) {
        this.visibleIframe = 'FREEDOME'
      }

      // this is separated because we do not want people going to /portal/freedome and get to see key iframe
      if (this.isIDPSubscription && !this.isTotalStandaloneInstallable) {
        this.visibleIframe = 'KEY'
      }

      if (toPath === '/portal/freedome') {
        this.visibleIframe = 'FREEDOME'
      }

      if (toPath === '/portal/key') {
        this.visibleIframe = 'KEY'
      }

      if (toPath === '/portal/idp-portal') {
        this.visibleIframe = 'IDP'
      }
    },
    updateVisibleIframeAndInitialise() {
      this.updateVisibleIframe(this.$route.path)
      this.updateIframeInitialVisibility()
    },
    markSafeLoadedAndLoadNextIframe() {
      if (!this.iframeUrlSafe) {
        return
      }
      this.safeIframeLoaded = true
      this.loadNextIframeWithDelay()
    },
    markFreedomeLoadedAndLoadNextIframe() {
      if (!this.iframeUrlFreedome) {
        return
      }
      this.freedomeIframeLoaded = true
      this.loadNextIframeWithDelay()
    },
    markKeyLoadedAndLoadNextIframe() {
      if (!this.iframeUrlKey) {
        return
      }
      this.keyIframeLoaded = true
      this.loadNextIframeWithDelay()
    },
    markKeyIdpLoadedAndLoadNextIframe() {
      if (!this.iframeUrlKeyIdp) {
        return
      }
      this.keyIdpIframeLoaded = true
      this.loadNextIframeWithDelay()
    },
    updateHeaderHeight(headerHeight) {
      this.headerHeight = headerHeight + 'px'
    },
    updateStatusbarHeight(statusbarHeight) {
      this.statusbarHeight = statusbarHeight + 'px'
    },
    loadNextIframeWithDelay() {
      setTimeout(() => {
        this.allowLoadNextIframe()
      }, 2000)
    },
    allowLoadNextIframe() {
      if (!this.safeIframeLoaded && this.isSafeInstallable) {
        this.allowSafeIframeToBeLoaded = true
        return
      }
      if (!this.keyIdpIframeLoaded && this.isIdmInstallable) {
        this.allowKeyIdpIframeToBeLoaded = true
        return
      }
      if (!this.freedomeIframeLoaded && this.isFreedomeInstallable) {
        this.allowFreedomeIframeToBeLoaded = true
        return
      }
      if (!this.keyIframeLoaded && this.isKeyInstallable) {
        this.allowKeyIframeToBeLoaded = true
      }
    },
    updateIframeInitialVisibility() {
      if (
        this.loadSafeIframe &&
        ((this.isIDPSubscription && this.isTotalStandaloneInstallable) ||
          (this.isFreedomeSubscription && this.isTotalStandaloneInstallable) ||
          this.isTotalSubscription ||
          this.isSafeSubscription ||
          this.isEppSubscription ||
          this.isEppIdpSubscription ||
          this.isEppVpnSubscription ||
          this.isTotalStandardSubscription ||
          this.isTotalStandardIdMonitoringSubscription)
      ) {
        this.allowSafeIframeToBeLoaded = true
        return
      }

      if (this.isIDPSubscription && !this.isTotalStandaloneInstallable) {
        this.allowKeyIframeToBeLoaded = true
        return
      }

      if (this.isFreedomeSubscription && !this.isTotalStandaloneInstallable) {
        this.allowFreedomeIframeToBeLoaded = true
        return
      }

      if (this.loadSafeIframe && this.showSafeIframe) {
        this.allowSafeIframeToBeLoaded = true
      }
      if (this.loadFreedomeIframe && this.showFreedomeIframe) {
        this.allowFreedomeIframeToBeLoaded = true
      }
      if (this.loadKeyIframe && this.showKeyIframe) {
        this.allowKeyIframeToBeLoaded = true
      }
      if (this.loadKeyIdpIframe && this.showKeyIdpIframe) {
        this.allowKeyIdpIframeToBeLoaded = true
      }
    },
    reloadAllIframes() {
      this.safeIframeLoaded = false
      this.freedomeIframeLoaded = false
      this.keyIframeLoaded = false
      this.keyIdpIframeLoaded = false
      this.showIframes = false
      this.$nextTick(() => {
        this.showIframes = true
      })
    },
    reloadAllIframesIfDataloaded() {
      if (!this.appDataLoaded) {
        return
      }

      this.reloadAllIframes()
    }
  }
}
</script>
<style lang="scss">
.avenue-iframe {
  height: calc(100% - 138px);
  // min-height: 578px;
  line-height: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  margin-top: 138px;
  bottom: 0;
  left: 0;
  right: 0;

  iframe {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    background-image: url('../assets/images/spinner.svg'),
      linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    transition: opacity 200ms, visibility 200ms;
  }

  &.loading {
    &::before {
      visibility: visible;
      opacity: 1;
    }
  }
}
body.iframe-resizer-active {
  min-height: 100vh;
  height: auto;

  #app {
    min-height: 100vh;
    height: auto;

    div.base-view {
      min-height: 100vh;
      height: auto;
      padding-bottom: 0;

      .top {
        padding-bottom: 0;
      }

      .avenue-iframe {
        position: relative;
      }
    }
  }
}
</style>
