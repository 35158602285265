import { render, staticRenderFns } from "./TheHeaderBarAnnouncement.vue?vue&type=template&id=1b5284ec&scoped=true"
import script from "./TheHeaderBarAnnouncement.vue?vue&type=script&lang=js"
export * from "./TheHeaderBarAnnouncement.vue?vue&type=script&lang=js"
import style0 from "./TheHeaderBarAnnouncement.vue?vue&type=style&index=0&id=1b5284ec&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b5284ec",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins/workspace/myfs_client/app_myfs_client_stg/buildsources/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1b5284ec')) {
      api.createRecord('1b5284ec', component.options)
    } else {
      api.reload('1b5284ec', component.options)
    }
    module.hot.accept("./TheHeaderBarAnnouncement.vue?vue&type=template&id=1b5284ec&scoped=true", function () {
      api.rerender('1b5284ec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/TheHeaderBarAnnouncement.vue"
export default component.exports