<template>
  <base-settings-content-section class="overview" id="overview-id">
    <h2>{{ $t('subscription.hl_overview') }}</h2>
    <subscription-product-overview-content />
  </base-settings-content-section>
</template>

<script>
import transchoice from '@/mixins/transchoice.js'
import BaseSettingsContentSection from './BaseSettingsContentSection.vue'
import SubscriptionProductOverviewContent from './SubscriptionProductOverviewContent.vue'

export default {
  mixins: [transchoice],
  components: {
    BaseSettingsContentSection,
    SubscriptionProductOverviewContent
  }
}
</script>

<style lang="scss" scoped>
.grid p.bold {
  font-weight: 600;
}
.grid p.centered {
  text-align: center;
}
.grid p.renewal {
  margin-bottom: 0;
}
#div-sense-status {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: $desktop-min-width) {
  .grid {
    &.bigger-gap {
      grid-gap: 15px 20px;
    }
    p.renewal {
      margin-bottom: 3px;
    }
  }
  .desktop-fill {
    min-height: 50px;
  }
  .overview {
    > h2 {
      margin-bottom: 16px;
    }
  }
}
</style>
