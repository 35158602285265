<template>
  <base-coupon-view-content :couponValid="couponValid">
    <template #apply-coupon>
      <div style="text-align: center">
        <img src="../assets/images/coupon-valid.svg" />
      </div>
      <h1>{{ $t('coupon_renew.hl_valid_code') }}</h1>
      <strong v-if="redeem">{{
        $t('coupon_redeem.ntc_want_to_activate')
      }}</strong>
      <strong v-else>
        <template v-if="sameProduct">
          {{
            $t('coupon_renew.ntc_want_renew_same_product', {
              servicename: newProductName
            })
          }}
        </template>
        <template v-else>
          {{
            $t('coupon_renew.ntc_want_renew', {
              old_servicename: oldProductName,
              servicename: newProductName
            })
          }}
        </template>
      </strong>
      <span style="display: block" v-if="redeem">{{
        containsLengthOfProtectionText
      }}</span>
      <br />
      <div class="grid-4" v-if="!redeem">
        <div class="value">
          <span>{{ $t('coupon_apply.label_subscription_current') }}</span>
        </div>
        <div class="value">
          <span>
            {{ currentDaysLeftText }}
          </span>
        </div>
        <div class="value">
          <span>{{ licenseSizeText }}</span>
        </div>
        <div class="value">
          <span>{{ oldProductName }}</span>
        </div>
        <div class="value">
          <span>{{ $t('coupon_apply.label_subscription_new') }}</span>
        </div>
        <div class="value">
          <span>
            {{ newDaysLeftText }}
          </span>
        </div>
        <div class="value">
          <span v-if="isDeviceCountDowngrade" class="downgrade">{{
            $t('subscription.x_devices', { maxdevices: newDeviceCount })
          }}</span>
          <span v-else>{{
            $t('subscription.x_devices', { maxdevices: newDeviceCount })
          }}</span>
        </div>
        <div class="value">
          <span v-if="isProductChange" class="downgrade">{{
            newProductName
          }}</span>
          <span v-else>{{ newProductName }}</span>
        </div>
      </div>
      <span
        style="display: block"
        v-if="newServiceKey === 'FREEDOME' && redeem"
      >
        {{ $t('coupon_apply.label_freedome_info_login_required') }}
      </span>
      <p v-if="!redeem" v-html="hintText"></p>
      <template v-if="applyingCoupon">
        <div>
          <picture>
            <img
              src="@/assets/images/spinner.svg"
              alt=""
              class="applying-coupon-spinner"
            />
          </picture>
        </div>
      </template>
      <div style="text-align: right">
        <base-button
          class="neutral"
          :class="{ disabled: applyingCoupon }"
          @click="resetState"
          :disabled="applyingCoupon"
        >
          {{ $t('coupon_renew.btn_cancel') }}
        </base-button>
        <base-button
          class="primary"
          :class="{ disabled: applyingCoupon }"
          @click="applyVoucher"
          :disabled="applyingCoupon"
        >
          {{ $t('coupon_renew.btn_accept') }}
        </base-button>
      </div>
    </template>
    <template #enter-coupon>
      <template v-if="!redeem">
        <h2>{{ $t('coupon_renew.hl_redeem_your_code') }}</h2>
        <h3>{{ $t('coupon_renew.sl_current_subscription') }}</h3>
        <div class="grid">
          <div class="title">
            <span>{{ $t('coupon_renew.hl_days_left') }}</span>
          </div>
          <div class="title">
            <span>{{ $t('coupon_renew.hl_devices') }}</span>
          </div>
          <div class="title">
            <span>{{ $t('coupon_renew.hl_subscription_type') }}</span>
          </div>
          <div class="value">
            <span>{{ daysLeftText }}</span>
          </div>
          <div class="value">
            <span>{{ licenseSizeText }}</span>
          </div>
          <div class="value">
            <span>{{ renewalTypeText }}</span>
          </div>
        </div>
        <h3>{{ $t('coupon_renew.sl_update_subscription') }}</h3>
        <base-loading-input-wrapper
          type="text"
          v-model="couponInput"
          :placeholder="inputPlaceholder"
          @blur="$v.couponInput.$touch()"
          @keyup="$v.couponInput.$touch()"
          :class="{ error: $v.couponInput.$error, disabled: isButtonDisabled }"
          :disabled="isButtonDisabled"
          ref="coupon-input"
          :loading="validatingCoupon"
          class="limited-width"
        />
      </template>
      <template v-else>
        <h2>{{ $t('coupon_redeem.hl_redeem_code') }}</h2>
        <h3>{{ $t('coupon_redeem.sl_activate_with_code') }}</h3>
        <div class="flex-row">
          <base-loading-input-wrapper
            id="coupon-redeem"
            :label="$t('coupon_redeem.ntc_enter_data')"
            v-model="couponInput"
            :placeholder="inputPlaceholder"
            ref="input-wrapper"
            @blur="$v.couponInput.$touch()"
            @keyup="$v.couponInput.$touch()"
            :class="{
              error: $v.couponInput.$error,
              disabled: isButtonDisabled
            }"
            :disabled="isButtonDisabled"
            :loading="validatingCoupon"
          />
          <div class="hint" v-if="showHint">
            <a
              href="#"
              @click.prevent="doNothing"
              class="css-tooltip"
              :tooltip-text="getHintText"
            >
              <span class="icon">
                <img src="../assets/images/icons/icon_help.svg" alt="?" />
              </span>
              <span class="text">
                {{ $t('coupon_redeem.ntc_hint') }}
              </span>
            </a>
          </div>
          <div class="hint" v-else>
            <a href="#" @click.prevent="doNothing">
              <span class="icon">
                <img src="../assets/images/icons/icon_help.svg" alt="?" />
              </span>
              <span class="text">
                {{ $t('coupon_redeem.ntc_hint') }}
              </span>
            </a>
          </div>
        </div>
      </template>
      <template v-if="couponInvalid">
        <p class="error-message">
          {{ validationErrorMessage }}
        </p>
        <p v-html="supportHelpText"></p>
      </template>
      <p v-if="redeem">* {{ $t('coupon_redeem.form_requiredfield') }}</p>
      <p v-else>* {{ $t('coupon_renew.form_requiredfield') }}</p>
      <div style="text-align: right">
        <base-button class="neutral" @click="navigateToExit">
          <template v-if="redeem">{{
            $t('coupon_redeem.btn_cancel')
          }}</template>
          <template v-else>{{ $t('coupon_renew.btn_cancel') }}</template>
        </base-button>
        <base-button
          class="primary"
          @click="validateCoupon"
          :class="{ error: $v.couponInput.$error, disabled: isButtonDisabled }"
          :disabled="isButtonDisabled"
        >
          <template v-if="redeem">
            {{ $t('coupon_redeem.btn_continue') }}
          </template>
          <template v-else>{{ $t('coupon_renew.btn_continue') }}</template>
        </base-button>
      </div>
    </template>
  </base-coupon-view-content>
</template>

<script>
import transchoice from '@/mixins/transchoice.js'
import coupon from '@/mixins/coupon.js'
import BaseCouponViewContent from './BaseCouponViewContent.vue'
import BaseLoadingInputWrapper from './BaseLoadingInputWrapper.vue'
import BaseButton from './BaseButton.vue'
import SegmentService from '@/services/SegmentService'

export default {
  props: {
    redeem: {
      type: Boolean,
      default: false
    }
  },
  mixins: [transchoice, coupon],
  data() {
    return {
      hint: false
    }
  },
  components: {
    BaseCouponViewContent,
    BaseLoadingInputWrapper,
    BaseButton
  },
  computed: {
    getHintText() {
      return this.hintText
    },
    showHint() {
      this.hasHint()
      return this.hint
    }
  },
  methods: {
    doNothing() {},

    hasHint() {
      let promotion = this.$store.state.customer.promotionName
      Promise.all([SegmentService.fetchSegmentData(promotion)]).then(
        (values) => {
          let segmentXML = values[0]
          let hintXML = segmentXML.documentElement.querySelector('hint')
          this.hint = hintXML.textContent === 'true'

          let hintTextXML = segmentXML.documentElement.querySelector('hintText')
          this.hintText = hintTextXML.textContent
        }
      )
    },
    redirectToSuccess() {
      if (this.redeem) {
        this.$router.push('/portal/coupon/success?redeem=1&fromIncompleteReg=1')
      } else {
        this.$router.push('/portal/coupon/success')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
h2,
h3 {
  color: black;
  text-align: left;
}
.applying-coupon-spinner {
  max-height: 32px;
}
.input-wrapper ::v-deep {
  label {
    span {
      color: #666;
      margin-bottom: 5px;
      display: inline-block;
    }
  }
}
.hint {
  display: none;

  a {
    text-decoration: none;
  }

  .icon {
    display: inline-block;
    width: 26px;
    height: 26px;

    img {
      vertical-align: middle;
    }
  }
}
.flex-row + p:not(.error-message) {
  margin-top: 32px;
  color: #666;
  margin-bottom: 16px;
}
.downgrade {
  color: red;
}
@media (min-width: $desktop-min-width) {
  .flex-row {
    display: flex;
    align-items: flex-end;

    .input-wrapper {
      margin-right: 20px;
    }

    .hint {
      display: block;
      width: 40%;
      min-height: 43px;

      .css-tooltip {
        position: relative;
      }

      .css-tooltip:hover::after {
        content: attr(tooltip-text);
        position: absolute;
        background: #ffffff;
        border-radius: 3px;
        white-space: wrap;
        top: 35px;
        line-height: 16px;
        color: #000;
        padding: 6px 10px;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 0px 9px #888888;
        width: 190px;
        height: 200px;
        margin-top: -260px;
        margin-left: -20px;
        z-index: 10;
        transition: opacity 0.1s ease;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        justify-content: center;
        text-align: center;
      }

      a {
        display: inline-block;
        line-height: 0;

        &::after {
          opacity: 0;
          visibility: hidden;
          content: '';
          border: 1px solid #f5f5f5;
          background-image: url('../assets/images/popup.png'),
            linear-gradient(#fff, #fff);
          background-repeat: no-repeat;
          background-position: center;
          box-shadow: 0px 0px 9px #888888;
          width: 217px;
          height: 217px;
          display: block;
          position: absolute;
          margin-top: -260px;
          margin-left: -20px;
          z-index: 10;
          transition: opacity 0.1s ease;
        }

        &:hover {
          &::after {
            opacity: 1;
            visibility: visible;
          }
        }

        span.text {
          line-height: 0.5rem;
        }
      }
    }
  }
}
.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  //border-top: 1px solid #dedede;
  //border-bottom: 1px solid #dedede;
  color: #666;
  //background: #f5f5f5;
  //padding: 10px 12px;
  margin-bottom: 40px;

  > div {
    padding: 10px 0;
    border-bottom: 1px solid #dedede;
    > span {
      font-weight: 600;
      font-size: 1rem;
    }
    &:nth-child(1) {
      grid-row: 1;
      grid-column: 1;
    }
    &:nth-child(2) {
      grid-row: 1;
      grid-column: 2;
    }
    &:nth-child(3) {
      grid-row: 1;
      grid-column: 3;
    }
    &:nth-child(4) {
      grid-row: 1;
      grid-column: 4;
    }
    &:nth-child(5) {
      grid-row: 2;
      grid-column: 1;
    }
    &:nth-child(6) {
      grid-row: 2;
      grid-column: 2;
    }
    &:nth-child(7) {
      grid-row: 2;
      grid-column: 3;
    }
    &:nth-child(8) {
      grid-row: 2;
      grid-column: 4;
    }
  }
}
</style>
