<template>
  <div id="the-overlay" v-show="isOpen"></div>
</template>
<script>
import popupMixin from '@/mixins/popup.js'
export default {
  mixins: [popupMixin],
  mounted() {
    this.$root.$on('route-entered', this.closePopup)
  },
  beforeDestroy() {
    this.$root.$off('route-entered', this.closePopup)
  }
}
</script>
<style lang="scss" scoped>
#the-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
</style>
