var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-settings-content-section",
    { attrs: { id: "manage-your-subscription-id" } },
    [
      _c("h2", { attrs: { id: "hl-option-header" } }, [
        _vm._v(_vm._s(_vm.$t("subscription.option_header"))),
      ]),
      _c("base-settings-content-section-grid", [
        _c("div", { staticClass: "short" }, [
          _c("div", { staticClass: "half" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.optionCode,
                    expression: "optionCode",
                  },
                ],
                attrs: { id: "subscription-action-select" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.optionCode = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.optionChange($event)
                    },
                  ],
                },
              },
              [
                _c(
                  "option",
                  {
                    attrs: { selected: "", disabled: "", value: "placeholder" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("subscription.option_placeholder")) +
                        " "
                    ),
                  ]
                ),
                _vm.contractHasExpired
                  ? [
                      _vm.isPurchaseButtonVisible
                        ? _c("option", { attrs: { value: "buy" } }, [
                            _vm._v(" " + _vm._s(_vm.purchaseButtonText) + " "),
                          ])
                        : _vm._e(),
                      _vm.downgradeUrl
                        ? _c("option", { attrs: { value: "downgrade" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("subscription.option_downgrade")
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c("option", { attrs: { value: "redeem-code" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("subscription.option_redeem_code")) +
                            " "
                        ),
                      ]),
                    ]
                  : [
                      _vm.isPurchaseButtonVisible
                        ? _c("option", { attrs: { value: "buy" } }, [
                            _vm._v(" " + _vm._s(_vm.purchaseButtonText) + " "),
                          ])
                        : _vm._e(),
                      _vm.downgradeUrl
                        ? _c("option", { attrs: { value: "downgrade" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("subscription.option_downgrade")
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _vm.isAutomaticSubscription && !_vm.isContinuousPrepaid
                        ? _c("option", { attrs: { value: "cancel-ar" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("subscription.option_cancel_ar")
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _vm.allowMRtoAR
                        ? _c("option", { attrs: { value: "mr-to-ar" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("subscription.option_switch_to_ar")
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _vm.allowConvertToAnnual
                        ? _c(
                            "option",
                            { attrs: { value: "convert-to-annual" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "subscription.option_convert_to_annual"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.couponRenewalAllowed
                        ? _c("option", { attrs: { value: "enter-code" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("subscription.option_enter_code")
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ],
              ],
              2
            ),
          ]),
          _c(
            "div",
            { staticClass: "half second-half" },
            [
              _vm.optionCode === "buy"
                ? [
                    _vm.contractHasExpired
                      ? [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("subscription.subscription_ended")
                              ) +
                              " "
                          ),
                        ]
                      : this.isBuyMoreCart
                      ? [
                          _vm._v(
                            _vm._s(
                              _vm.$t("subscription.option_buy_more_subtext")
                            )
                          ),
                        ]
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm.optionCode === "downgrade"
                ? [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("subscription.option_downgrade_subtext")
                        ) +
                        " "
                    ),
                  ]
                : _vm.optionCode === "redeem-code"
                ? [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("subscription.option_redeem_code_subtext")
                        ),
                      },
                    }),
                  ]
                : _vm.optionCode === "cancel-ar"
                ? void 0
                : _vm.optionCode === "mr-to-ar"
                ? [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("subscription.option_switch_to_ar_subtext")
                        )
                    ),
                    _c("br"),
                    _c(
                      "router-link",
                      { attrs: { to: "/portal/enable_autorenew_reason" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "subscription.option_switch_to_ar_subtext_link"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                : _vm.optionCode === "convert-to-annual"
                ? [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "subscription.option_convert_to_annual_subtext"
                          )
                        )
                    ),
                    _c("br"),
                  ]
                : _vm.optionCode === "enter-code" &&
                  !_vm.isContinuousPrepaid &&
                  !_vm.isTrial &&
                  !_vm.inCouponWhitelistedSegment
                ? [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("subscription.option_enter_code_subtext", {
                            voucherRedemptionDateFormatted:
                              _vm.voucherRedemptionDateFormatted,
                          })
                        ),
                      },
                    }),
                  ]
                : _vm.optionCode === "enter-code"
                ? [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t(
                            "subscription.option_enter_code_subtext_prepaid_ar"
                          )
                        ),
                      },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]),
        _c(
          "div",
          { staticClass: "long" },
          [
            _vm.optionCode === "buy"
              ? [
                  _c("base-purchase-button", {
                    staticClass: "primary",
                    attrs: { id: "btn-overview-buy" },
                  }),
                ]
              : _vm.optionCode === "downgrade"
              ? [
                  _c("base-purchase-button", {
                    staticClass: "primary",
                    attrs: {
                      id: "btn-overview-downgrade",
                      text: _vm.$t("subscription.option_downgrade"),
                      url: _vm.downgradeUrl,
                      "data-track-event": "navigate",
                      "data-track-name": "downgrade",
                      noClickBind: "",
                    },
                  }),
                ]
              : _vm.optionCode === "redeem-code"
              ? [
                  _c(
                    "base-button-link",
                    {
                      staticClass: "primary",
                      attrs: {
                        id: "btn-overview-redeem-code",
                        to: "/portal/coupon/redeem",
                        "data-track-event": "navigate",
                        "data-track-name": "redeem-code",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("subscription.option_redeem_code")) +
                          " "
                      ),
                    ]
                  ),
                ]
              : _vm.optionCode === "cancel-ar"
              ? [
                  _c(
                    "base-button-link",
                    {
                      staticClass: "primary",
                      attrs: {
                        id: "btn-overview-cancel-ar",
                        to: "/portal/cancel_autorenew",
                        "data-track-event": "navigate",
                        "data-track-name": "cancel-autorenew",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("subscription.lnk_cancel_subscription")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]
              : _vm.optionCode === "mr-to-ar"
              ? [
                  _c(
                    "base-button-link",
                    {
                      staticClass: "primary",
                      attrs: {
                        id: "btn-overview-mr-to-ar",
                        to: "/portal/enable_autorenew",
                        "data-track-event": "navigate",
                        "data-track-name": "mr-to-ar",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("subscription.option_switch_to_ar")) +
                          " "
                      ),
                    ]
                  ),
                ]
              : _vm.optionCode === "convert-to-annual"
              ? [
                  _c(
                    "base-button-link",
                    {
                      staticClass: "primary",
                      attrs: {
                        id: "btn-overview-convert-to-annual",
                        to: "/portal/convert_to_annual",
                        "data-track-event": "navigate",
                        "data-track-name": "convert-to-annual",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("subscription.option_convert_to_annual_btn")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]
              : _vm.optionCode === "enter-code" && _vm.inCouponRenewalPeriod
              ? [
                  _c(
                    "base-button-link",
                    {
                      staticClass: "primary",
                      attrs: {
                        id: "btn-overview-renew",
                        to: "/portal/coupon/renew",
                        "data-track-event": "navigate",
                        "data-track-name": "coupon-renew",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("subscription.option_enter_code")) +
                          " "
                      ),
                    ]
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c("br"),
      _vm.senseStatusText
        ? [
            _c("h2", { attrs: { id: "hl-f-secure-sense" } }, [
              _vm._v("F-Secure SENSE"),
            ]),
            _c("base-settings-content-section-grid", [
              _c("div", { staticClass: "long" }, [
                _c("p", { attrs: { id: "sense-status" } }, [
                  _vm._v(_vm._s(_vm.senseStatusText)),
                ]),
              ]),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }