<template>
  <div class="list">
    <div class="item">
      <div class="title">{{ $t('subscription.hl_product_type') }}</div>
      <div class="title">{{ $t('subscription.hl_license_amount') }}</div>
      <div class="title">{{ $t('subscription.hl_expiration_date') }}</div>
      <div class="title">{{ $t('subscription.hl_renewal') }}</div>
      <div class="value">{{ productType }}</div>
      <div class="value">{{ licenseSize }}</div>
      <div class="value">{{ expirationDateFormatted }}</div>
      <div class="value">{{ renewalTypeText }}</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState({
      licenseSize: (state) => state.customer.contract.licenseSize,
      isAutomaticSubscription: (state) =>
        state.customer.contract.isAutomaticSubscription,
      isPartnerManagedSubscription: (state) =>
        state.customer.contract.isAutomaticSubscription &&
        state.customer.contract.type === 'PREPAID' &&
        state.customer.affiliate !== 'fsecure',
      subscriptionPromotionName: (state) => state.customer.promotionShowName,
      senseStatus: (state) => state.customer.senseStatus,
      couponRenewalAllowed: (state) =>
        state.customer.contract.couponRenewalAllowed,
      sensePurchaseUrl: (state) => state.ui.sensePurchaseUrl
    }),
    ...mapGetters({
      isSafeSubscription: 'customer/contract/isSafeSubscription',
      isIDPSubscription: 'customer/contract/isIDPSubscription',
      isFreedomeSubscription: 'customer/contract/isFreedomeSubscription',
      isTotalSubscription: 'customer/contract/isTotalSubscription',
      isTotalIDMSubscription: 'customer/contract/isTotalIDMSubscription',
      isManualSubscription: 'customer/contract/isManualSubscription',
      isMonthly: 'customer/contract/isMonthly',
      isYearly: 'customer/contract/isYearly',
      isPrepaid: 'customer/contract/isPrepaid',
      isContinuousPrepaid: 'customer/contract/isContinuousPrepaid',
      isTrial: 'customer/contract/isTrial',
      isSubUser: 'customer/contract/isSubUser',
      expirationDateFormatted: 'customer/contract/expirationDateFormatted',
      isPurchaseButtonVisible: 'ui/isPurchaseButtonVisible',
      productNames: 'ui/productNames',
      contractHasExpired: 'customer/contract/hasExpired',
      isUnified: 'customer/isUnified',
      getFullUnifiedProductName: 'customer/contract/getFullUnifiedProductName'
    }),
    productType() {
      if (this.isUnified) {
        return this.getFullUnifiedProductName
      } else {
        if (this.isSafeSubscription) {
          return this.productNames.safeFull
        }

        if (this.isIDPSubscription) {
          return this.productNames.keyFull
        }

        if (this.isFreedomeSubscription) {
          return this.productNames.freedomeFull
        }

        if (this.isTotalIDMSubscription) {
          return this.productNames.totalIdmFull
        }

        if (this.isTotalSubscription) {
          return this.productNames.totalFull
        }
      }
      return ''
    },
    renewalTypeText() {
      if (this.isAutomaticSubscription) {
        if (this.isPartnerManagedSubscription) {
          return this.$t('subscription.billing_managed_by_x', {
            promotion: this.subscriptionPromotionName
          })
        } else if (this.isPrepaid) {
          return this.$t('subscription.autorenew_non_specified')
        }

        if (this.isMonthly) {
          return this.$t('subscription.autorenew_monthly')
        }

        if (this.isYearly) {
          return this.$t('subscription.autorenew_yearly')
        }

        return this.$t('subscription.automatic_renewal')
      }

      if (this.isManualSubscription) {
        return this.$t('subscription.manual_renewal')
      }

      if (this.isTrial) {
        return this.$t('subscription.manual_renewal')
      }

      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  + .item {
    border-top: 1px solid #dedede;
    margin-top: 10px;
    padding-top: 10px;
  }
}

.title {
  color: #666;
  font-weight: 600;
  font-size: 1.14286rem;
  grid-column: 1;
}

.value {
  color: $font-color;
  font-weight: 300;
  font-size: 1.14286rem;
  grid-column: 2;
  text-align: right;

  &:nth-child(5) {
    grid-row: 1;
  }

  &:nth-child(6) {
    grid-row: 2;
  }

  &:nth-child(7) {
    grid-row: 3;
  }

  &:nth-child(8) {
    grid-row: 4;
  }
}

@media (min-width: $desktop-min-width) {
  .item {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;

    + .item {
      border-top: none;
      margin-top: 16px;
      padding-top: 0;
    }

    &:not(:first-child) .title {
      display: none;
    }

    .title {
      grid-column: auto;
    }

    .value {
      grid-column: auto;
      grid-row: auto;
      text-align: left;
    }
  }
}

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .item {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr;

    .title {
      &:nth-child(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-child(2) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }
      &:nth-child(3) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-child(4) {
        -ms-grid-column: 1;
        -ms-grid-row: 4;
      }
    }

    .value {
      &:nth-child(5) {
        -ms-grid-column: 2;
        -ms-grid-row: 1;
      }
      &:nth-child(6) {
        -ms-grid-column: 2;
        -ms-grid-row: 2;
      }
      &:nth-child(7) {
        -ms-grid-column: 2;
        -ms-grid-row: 3;
      }
      &:nth-child(8) {
        -ms-grid-column: 2;
        -ms-grid-row: 4;
      }
    }
  }
}

@media screen and (-ms-high-contrast: active) and (min-width: $desktop-min-width),
  screen and (-ms-high-contrast: none) and (min-width: $desktop-min-width) {
  .item {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;

    .title {
      &:nth-child(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-child(2) {
        -ms-grid-column: 2;
        -ms-grid-row: 1;
      }
      &:nth-child(3) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-child(4) {
        -ms-grid-column: 4;
        -ms-grid-row: 1;
      }
    }

    .value {
      &:nth-child(5) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }
      &:nth-child(6) {
        -ms-grid-column: 2;
        -ms-grid-row: 2;
      }
      &:nth-child(7) {
        -ms-grid-column: 3;
        -ms-grid-row: 2;
      }
      &:nth-child(8) {
        -ms-grid-column: 4;
        -ms-grid-row: 2;
      }
    }
  }
}
</style>
