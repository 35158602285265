var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-button-link-native",
    {
      attrs: {
        href: _vm.purchaseUrl,
        "data-track-event": _vm.trackingEventComputed,
        "data-track-name": _vm.trackingNameComputed,
      },
      on: {
        click: function ($event) {
          _vm.noClickBind ? "" : _vm.navigateOrOpenPopup($event)
        },
      },
      nativeOn: {
        click: function ($event) {
          return _vm.doNothingIfAlreadyClicked.apply(null, arguments)
        },
      },
    },
    [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }