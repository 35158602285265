var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-header-bar-link",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isVisible,
          expression: "isVisible",
        },
      ],
      attrs: {
        "data-track-event": "navigate",
        "data-track-name": _vm.dataTrackName,
        to: _vm.toUrl,
      },
    },
    [_c("span", [_vm._v(_vm._s(_vm.productName))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }