var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("one-col-box-grid-content-box", [
    _c("h2", [
      _vm._v(_vm._s(_vm.$t("delete_user_confirmation.hl_delete_result"))),
    ]),
    _c("ul", [
      _c("li", [
        _vm._v(
          " " +
            _vm._s(_vm.$t("delete_user_confirmation.list_result_expire")) +
            " "
        ),
      ]),
      _c("li", [
        _vm._v(
          " " +
            _vm._s(_vm.$t("delete_user_confirmation.list_result_deleted")) +
            " "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }