<template>
  <base-settings-content-section id="additional-services-id" v-if="isVisible">
    <h2 id="hl-additional-services">
      {{ $t('subscription.hl_additional_services') }}
    </h2>
    <div class="grid">
      <service-item
        v-for="service in filteredAdditionalServices"
        :key="service.id"
        :service-id="service.id"
      />
    </div>
  </base-settings-content-section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import BaseSettingsContentSection from './BaseSettingsContentSection.vue'
import ServiceItem from './SubscriptionViewAdditionalServiceItem.vue'
export default {
  components: {
    BaseSettingsContentSection,
    ServiceItem
  },
  computed: {
    ...mapState({
      additionalServices: (state) => state.customer.additionalServices
    }),
    ...mapGetters({
      isSenseSubscription: 'customer/contract/isSenseSubscription'
    }),
    filteredAdditionalServices() {
      return this.additionalServices.filter(
        (item) =>
          item.name !== 'SenseSDK' &&
          item.name !== 'SomeBuddy' &&
          item.name !== 'IDTR'
      )
    },
    isVisible() {
      return (
        this.filteredAdditionalServices.length > 0 && !this.isSenseSubscription
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
}

@media (min-width: $desktop-min-width) {
  .grid {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 80px;
  }
}

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .grid {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    -ms-grid-rows: 1fr 20px;

    > div {
      &:nth-child(1) {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
      }
      &:nth-child(2) {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
      }
      &:nth-child(3) {
        -ms-grid-row: 5;
        -ms-grid-column: 1;
      }
      &:nth-child(4) {
        -ms-grid-row: 7;
        -ms-grid-column: 1;
      }
      &:nth-child(5) {
        -ms-grid-row: 9;
        -ms-grid-column: 1;
      }
      &:nth-child(6) {
        -ms-grid-row: 11;
        -ms-grid-column: 1;
      }
    }
  }
}
@media screen and (-ms-high-contrast: active) and (min-width: $desktop-min-width),
  screen and (-ms-high-contrast: none) and (min-width: $desktop-min-width) {
  .grid {
    display: -ms-grid;
    -ms-grid-columns: 1fr 80px 1fr 80px 1fr;
    -ms-grid-rows: 1fr 80px 1fr 80px 1fr 80px;

    > div {
      &:nth-child(1) {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
      }
      &:nth-child(2) {
        -ms-grid-row: 3;
        -ms-grid-column: 3;
      }
      &:nth-child(3) {
        -ms-grid-row: 5;
        -ms-grid-column: 5;
      }
      &:nth-child(4) {
        -ms-grid-row: 7;
        -ms-grid-column: 7;
      }
      &:nth-child(5) {
        -ms-grid-row: 9;
        -ms-grid-column: 9;
      }
      &:nth-child(6) {
        -ms-grid-row: 11;
        -ms-grid-column: 11;
      }
    }
  }
}
</style>
