import { render, staticRenderFns } from "./BaseModalPopup.vue?vue&type=template&id=2b90b1e0&scoped=true"
import script from "./BaseModalPopup.vue?vue&type=script&lang=js"
export * from "./BaseModalPopup.vue?vue&type=script&lang=js"
import style0 from "./BaseModalPopup.vue?vue&type=style&index=0&id=2b90b1e0&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b90b1e0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins/workspace/myfs_client/app_myfs_client_stg/buildsources/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2b90b1e0')) {
      api.createRecord('2b90b1e0', component.options)
    } else {
      api.reload('2b90b1e0', component.options)
    }
    module.hot.accept("./BaseModalPopup.vue?vue&type=template&id=2b90b1e0&scoped=true", function () {
      api.rerender('2b90b1e0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/BaseModalPopup.vue"
export default component.exports