<template>
  <base-modal-popup
    id="subscription-reminder"
    class="subscription-reminder"
    v-show="isOpen"
    more-padding
    is-wide
    :more-footer-padding="!isFooterTextEmpty"
  >
    <template>
      <h2>{{ $t('reminder.inactive_session') }}</h2>
      <div class="content-row centered">
        <div class="text">
          <p style="display: inline">
            {{ $t('reminder.inactive_session_end_in') }}
          </p>
          <h3 style="display: inline">
            {{ formatedCountdown || 'countdown over' }}
          </h3>
          <p>{{ $t('reminder.inactive_session_click_refresh') }}</p>
        </div>
      </div>
      <base-button
        class="primary"
        :disabled="buttonDisabled"
        @click="closePopup"
        @click.native="closeOrNavigate()"
        >{{ $t('reminder.btn_inactive_session_refresh') }}</base-button
      >
      <base-purchase-button
        class="neutral"
        :text="buttonText"
        :url="buttonUrl"
      />
    </template>
  </base-modal-popup>
</template>

<script>
import popup from '@/mixins/popup.js'
import { mapState } from 'vuex'
import transchoice from '@/mixins/transchoice.js'
import BaseModalPopup from './BaseModalPopup.vue'
import BaseButton from './BaseButton.vue'
import BasePurchaseButton from './BasePurchaseButton.vue'

import * as moment from 'moment'
import 'moment-duration-format'

export default {
  data() {
    const countdownDuration = 1 * 60 // 1min
    return {
      clicked: false,
      countdown: countdownDuration,
      stopCountdown: null,
      countdownStart: Date.now(),
      countdownTimer: countdownDuration
    }
  },
  props: {
    inactiveShowOnMount: {
      type: Boolean,
      default: false
    }
  },
  mixins: [transchoice, popup],
  components: {
    BaseModalPopup,
    BaseButton,
    BasePurchaseButton
  },
  watch: {
    isOpen(newValue, oldValue) {
      if (
        (oldValue === false && newValue === true) ||
        (oldValue === true && newValue === false)
      ) {
        this.$store.commit('ui/SET_IS_INACTIVE_POPUP_OPEN', newValue)
      }
    }
  },
  computed: {
    ...mapState({}),

    buttonText() {
      return this.$t('account.btn_log_out')
    },
    buttonUrl() {
      return '/logout'
    },
    buttonDisabled() {
      return this.countdownTimer <= 0
    },
    isFooterTextEmpty() {
      return this.footerText === ''
    },
    formatedCountdown() {
      return moment.duration(this.countdownTimer, 'seconds').format('m:ss')
    }
  },
  mounted() {
    if (this.$props.inactiveShowOnMount) {
      this.handleShowingInactivePopup()
    }

    this.stopCountdown = setInterval(() => {
      // this.countdownTimer -= 1
      let milliseconds = Date.now() - this.countdownStart
      let secondsLeft = Math.ceil(this.countdown - milliseconds / 1000)
      this.countdownTimer = secondsLeft
      if (this.countdownTimer <= 0) {
        clearInterval(this.stopCountdown)
      }
    }, 1000)
  },
  beforeDestroy() {},
  methods: {
    closeOrNavigate() {
      this.closePopup()
      this.isOpen = false
      clearInterval(this.stopCountdown)
    },
    handleShowingInactivePopup() {
      if (this.isInactive) {
        return
      }
      this.isOpen = true
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-popup.wide ::v-deep {
  .footer {
    p:first-child {
      max-width: 400px;
      margin: 0 auto;
    }

    p + .flex-btn {
      margin-top: 20px;
    }

    &.more-padding {
      padding: 50px 20px;

      p + .flex-btn {
        margin-top: 40px;
      }
    }
  }

  .box {
    max-width: 640px;
    flex: auto;
    width: 100%;

    .popup-content {
      padding-bottom: 45px;
    }
  }
}

h2 {
  margin-bottom: 36px;
}

.primary:disabled {
  cursor: not-allowed;
  background-color: #2693ff;
}

.flex-btn + .flex-btn {
  margin-top: 10px;
}

h3 {
  font-size: 1.28571rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0.288em;
  color: $warning-color;
}

p {
  margin: 0;
  color: #666;
  line-height: 1.5rem;

  &.teaser {
    background-color: #f5f5f5;
    display: inline-block;
    font-style: italic;
    font-weight: normal;
    line-height: 33px;
    margin-top: 25px;
    padding: 0 10px;
  }
}

.content-row {
  display: flex;
  text-align: left;
  align-items: center;
  margin: 0 auto 40px auto;
  max-width: 485px;

  p:first-child + p {
    margin-top: 36px;
  }

  &.centered {
    justify-content: center;
    text-align: center;
  }

  &.valign-to-top {
    align-items: flex-start;
  }

  &.no-bottom-padding {
    margin-bottom: 0;
  }
}

.icon {
  width: 44px;

  + .text {
    padding-left: 20px;
    width: 100%;
  }

  img {
    vertical-align: top;
  }
}

@media (min-width: $desktop-min-width) {
  .modal-popup.wide ::v-deep {
    .box {
      .popup-content {
        padding-bottom: 50px;
      }
    }
  }
  .modal-popup.ok ::v-deep {
    .box {
      .popup-content {
        padding-bottom: 55px;
      }
    }
  }
  h3 {
    font-size: 1.71429rem;
  }
  .icon {
    width: 64px;
  }
  .flex-btn {
    width: auto;

    + .flex-btn {
      margin-left: 10px;
    }
  }
}
</style>
