<template>
  <section class="bottom">
    <div v-if="showNavButtons">
      <base-header-bar-link
        data-track-event="navigate"
        :data-track-name="dataTrackName"
        to="/portal/welcome"
      >
        <span>{{ productName }}</span>
      </base-header-bar-link>
      <base-header-bar-link
        v-if="showMonitoring"
        data-track-event="navigate"
        data-track-name="id-monitoring"
        to="/portal/idp-portal"
      >
        <span>{{ $t('dashboard.id_monitoring') }}</span>
      </base-header-bar-link>
      <shared-product-link
        v-for="sharedProductShortName in sharedProductShortNames"
        :key="sharedProductShortName"
        :product-short-name="sharedProductShortName"
      />
    </div>
    <div v-else></div>
    <div>
      <base-header-bar-link
        id="top-subscription-link"
        data-track-event="navigate"
        data-track-name="subscription"
        to="/portal/order"
        v-if="!isSubUser"
        >{{ $t('header.lnk_subscription') }}</base-header-bar-link
      >
      <the-header-bar-account-menu />
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import BaseHeaderBarLink from './BaseHeaderBarLink.vue'
import TheHeaderBarAccountMenu from './TheHeaderBarAccountMenu.vue'
import SharedProductLink from './TheHeaderBarSecondSectionProductLink.vue'
export default {
  components: {
    BaseHeaderBarLink,
    TheHeaderBarAccountMenu,
    SharedProductLink
  },
  computed: {
    ...mapState({
      sharedProducts: (state) => state.customer.sharedProducts
    }),
    ...mapGetters({
      isSubUser: 'customer/contract/isSubUser',
      isSenseSubscription: 'customer/contract/isSenseSubscription',
      isSafeSubscription: 'customer/contract/isSafeSubscription',
      isIDPSubscription: 'customer/contract/isIDPSubscription',
      isFreedomeSubscription: 'customer/contract/isFreedomeSubscription',
      productNames: 'ui/productNames',
      unifiedProductNames: 'ui/unifiedProductNames',
      sharedProductShortNames: 'customer/sharedProductShortNames',
      isIdmInstallable: 'customer/licenses/isIdmInstallable',
      isTotalStandaloneInstallable:
        'customer/licenses/isTotalStandaloneInstallable',
      isUnified: 'customer/isUnified'
    }),
    showNavButtons() {
      return !this.isSenseSubscription
    },
    productName() {
      if (this.isTotalStandaloneInstallable) {
        return 'F-Secure'
      }
      if (this.isUnified) {
        if (this.isIDPSubscription) {
          return this.unifiedProductNames.idp
        } else if (this.isFreedomeSubscription) {
          return this.unifiedProductNames.vpn
        }
      } else {
        if (this.isIDPSubscription) {
          return this.productNames.key
        } else if (this.isFreedomeSubscription) {
          return this.productNames.freedome
        }
      }
      return 'F-Secure'
    },
    showIDPWeb() {
      return this.$store.state.ui.showIDPWeb
    },
    showMonitoring() {
      return this.isIdmInstallable && this.showIDPWeb
    },
    dataTrackName() {
      if (this.isTotalStandaloneInstallable) {
        return 'portal-fsecure'
      }
      if (this.isIDPSubscription) {
        return 'portal-key'
      } else if (this.isFreedomeSubscription) {
        return 'portal-freedome'
      }
      return 'portal-fsecure'
    }
  }
}
</script>

<style lang="scss" scoped>
section {
  display: none;
}

@media (min-width: $desktop-min-width) {
  section {
    display: flex;
    justify-content: space-between;
    height: 0;
    margin-top: 1px;
    position: relative;

    &::before {
      content: '';
      display: block;
      background: #f2f2f2;
      height: 1px;
      top: -1px;
      width: 100%;
      position: absolute;
      z-index: 3;
    }

    > div:first-child {
      flex: 1 0 auto;
    }

    > div:last-child {
      > a {
        &::after {
          content: '';
          display: inline-block;
          width: 1px;
          background-color: #f2f2f2;
          height: 30px;
          vertical-align: middle;
          margin-left: 16px;
        }
      }
    }

    span {
      font-size: 15px;
    }
  }
}
</style>
