<template>
  <div
    class="arrow-menu"
    :class="{ 'menu-open': isOpen }"
    @mouseenter="openMenu"
    @mouseleave="closeMenu"
  >
    <base-header-bar-link
      class="main-link"
      :to="$props.to"
      disabled
      @click.native.prevent="togglePopup"
    >
      <slot name="main-link" />
      <span class="arrow-icon">
        <img src="@/assets/images/icons/dashboard_icon_arrow_up.svg" alt />
        <img src="@/assets/images/icons/dashboard_icon_arrow_down.svg" alt />
      </span>
    </base-header-bar-link>
    <div class="menu-items">
      <slot name="menu-items" />
    </div>
  </div>
</template>

<script>
import popupMixin from '@/mixins/popup'
import BaseHeaderBarLink from './BaseHeaderBarLink.vue'
export default {
  mixins: [popupMixin],
  components: {
    BaseHeaderBarLink
  },
  props: {
    to: {
      type: String,
      required: true
    },
    hoverDisabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isOpen(newValue, OldValue) {
      this.$root.$emit('toggle_popup', {
        elementId: 'the-overlay',
        action: newValue ? 'open' : 'close',
        eventInitiator: this.$el
      })
    }
  },
  mounted() {
    this.$root.$on('toggle_popup', this.onTogglePopup)
  },
  beforeDesgroy() {
    this.$root.$off('toggle_popup', this.onTogglePopup)
  },
  methods: {
    openMenu() {
      if (this.hoverDisabled) {
        return
      }

      this.openPopup()
    },
    closeMenu() {
      if (this.hoverDisabled) {
        return
      }

      this.closePopup()
    },
    onTogglePopup(data) {
      if (this.isOpen && data.elementId !== 'the-overlay') {
        this.closePopup()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: $desktop-min-width) {
  .arrow-menu {
    position: relative;
    display: inline-block;

    &.menu-open {
      div {
        transform: translateY(0);
      }

      .arrow-icon {
        img:first-child {
          display: inline-block;
        }

        img:last-child {
          display: none;
        }
      }
    }

    div {
      transform: translateY(-100%);
      position: absolute;
      background: white;
      width: 100%;
      left: 0;
      transition: transform 100ms ease-in-out;

      a {
        width: 100%;
        padding: 0 16px;
        color: $font-color;
        display: flex;
        line-height: 18px;
        min-height: 50px;
        align-items: center;
        word-wrap: anywhere;
        height: 0; /* ie11 fix */
        -ms-flex-flow: row wrap;
        text-decoration: none;

        &:hover {
          color: $primary-color;
          &::before {
            left: 0;
            width: 4px;
            height: 100%;
          }
        }
      }
    }

    > a {
      position: relative;
      z-index: 2;
      background: #fff;

      span {
        color: $primary-color;
      }
    }
  }
}
</style>
