<template>
  <div class="container">
    <img src="../assets/images/start-trial-logo.svg" alt />
    <div style="text-align: left">
      <h1>{{ $t('complete_registration.start_trial_heading') }}</h1>
      <div
        class="tag"
        v-html="
          $t('complete_registration.start_trial_tag', {
            license_period_in_days: licensePeriodInDays
          })
        "
      ></div>
      <h3>
        {{
          $t('complete_registration.start_trial_sub_heading', {
            full_product_name: fullProductName,
            license_period_in_days: licensePeriodInDays
          })
        }}
      </h3>
      <span>
        {{
          $t('complete_registration.start_trial_desc', {
            license_period_in_days: licensePeriodInDays
          })
        }}
      </span>
      <ul v-if="defaultTrialConfig.serviceKey === 'TOTAL'">
        <li>{{ $t('complete_registration.start_trial_product_safe') }}</li>
        <li>{{ $t('complete_registration.start_trial_product_freedome') }}</li>
        <li>{{ $t('complete_registration.start_trial_product_idp') }}</li>
      </ul>
      <ul v-else-if="defaultTrialConfig.productVariant === 'total_standard'">
        <li>{{ unifiedProductNames.epp }}</li>
        <li>{{ unifiedProductNames.unifiedVpn }}</li>
        <li>{{ unifiedProductNames.unifiedIdp }}</li>
      </ul>
      <div style="text-align: right">
        <base-button class="primary" @click="startTrial">
          <span v-show="!startingTrial">{{
            $t('complete_registration.start_trial_btn_label')
          }}</span>
          <picture v-show="startingTrial">
            <img
              src="@/assets/images/spinner-white.svg"
              alt=""
              style="color: white; max-width: 30%"
            />
          </picture>
        </base-button>
      </div>
    </div>
  </div>
</template>
<script>
import BaseButton from './BaseButton.vue'
import CustomerService from '@/services/CustomerService'
import DataMixin from '@/mixins/data'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      startingTrial: false
    }
  },
  mixins: [DataMixin],
  components: {
    BaseButton
  },
  computed: {
    ...mapGetters({
      productNames: 'ui/productNames',
      unifiedProductNames: 'ui/unifiedProductNames',
      defaultTrialConfig: 'customer/defaultTrialConfig'
    }),
    licensePeriodInDays() {
      // Default trial config in the backend
      return this.defaultTrialConfig.licensePeriodInDays
    },
    fullProductName() {
      let serviceKey = this.defaultTrialConfig.serviceKey
      let productVariant = this.defaultTrialConfig.productVariant

      // productVariant is used instead of isUnified, because it should based on the default segment's is_unified
      // instead of current segment's is_unified if customer starts trial
      if (productVariant !== null) {
        if (productVariant === 'total_epp') {
          return this.unifiedProductNames.eppFull
        } else if (productVariant === 'total_vpn') {
          return this.unifiedProductNames.vpnFull
        } else if (productVariant === 'total_idp') {
          return this.unifiedProductNames.idpFull
        } else if (productVariant === 'total_epp_vpn') {
          return this.unifiedProductNames.eppVpnFull
        } else if (productVariant === 'total_epp_idp') {
          return this.unifiedProductNames.eppIdpFull
        } else if (
          productVariant === 'total_standard' ||
          productVariant === 'total_standard_with_sense'
        ) {
          return this.unifiedProductNames.totalFull
        } else if (productVariant === 'total_epp_scam') {
          return this.unifiedProductNames.eppScam
        } else if (productVariant === 'total_epp_idp_scam') {
          return this.unifiedProductNames.eppIdpScam
        } else if (productVariant === 'total_epp_vpn_scam') {
          return this.unifiedProductNames.eppVpnScam
        }
      } else {
        if (serviceKey === 'IDP') {
          return this.productNames.keyFull
        } else if (serviceKey === 'FREEDOME') {
          return this.productNames.freedomeFull
        } else if (serviceKey === 'TOTAL') {
          return this.productNames.totalFull
        }
        return this.productNames.safeFull
      }
      return ''
    }
  },
  methods: {
    async startTrial() {
      if (this.startingTrial) {
        return
      }

      this.startingTrial = true
      const response = await this.preHandleResponse(() =>
        CustomerService.postActivateWithoutVoucher(
          this.$store.state.customer.token
        )
      )

      if (!response || response.errorCode) {
        this.startingTrial = false
        return
      }

      this.$store.dispatch('customer/fetch')
      this.$store.dispatch('customer/fetchBillingHistory')
      this.$store.dispatch('ui/fetchCart')
      this.$store.dispatch('ui/fetchCustomizations')
      this.$store.dispatch('ui/fetchCouponShareProductNames')
      this.$router.push('/portal/welcome')
    }
  }
}
</script>
<style lang="scss" scoped>
@media (min-width: $desktop-min-width) {
  .container {
    text-align: center;
    h1 {
      padding-bottom: 15px;
    }
    .icon {
      img {
        width: 21px;
        margin-bottom: -6px;
      }
    }
    .icon-step {
      color: white;
      background-color: #2693ff;
      padding: 2px 6px 2px 6px;
      border-radius: 18px;
      font-size: 0.75rem;
    }

    .flex-btn {
      width: auto;
      margin-top: 16px;
    }
    .tag {
      background-color: #00a538;
      color: white;
      border-radius: 5px;
      padding: 0px 6px;
      font-size: 0.75rem;
    }
  }
}
</style>
