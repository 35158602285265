<template>
  <base-modal-popup
    id="activation-popup"
    class="activation"
    v-show="isOpen"
    is-wide
    display-close-button
    :on-close-click="closeWithOptionalRefresh"
    more-padding
    less-close-padding
    top-close-id="additional-service-top-close"
  >
    <popup-content
      :close-popup="closeOrShowInstructions"
      :info-text="infoText"
      :is-info-left-aligned="isInfoLeftAligned"
      :is-activated-only-on-myfs="isActivatedOnlyOnMyfs"
      :show-instructions-popup="showInstructionsPopup"
      :close-button-text="closeButtonText"
      :service-name="serviceName"
      :learnmore-url="learnmoreUrl"
      :terms-url="termsUrl"
      @service-activated="enableRefreshOnClose"
      :service-id="serviceId"
    />
  </base-modal-popup>
</template>

<script>
import ActivationService from '@/services/ActivationService.js'
import popupMixin from '@/mixins/popup.js'
import BaseModalPopup from './BaseModalPopup.vue'
import PopupContent from './TheAdditionalServiceActivationPopupContent.vue'

export default {
  data() {
    return {
      infoText: '',
      serviceName: '',
      learnmoreUrl: '',
      termsUrl: '',
      refreshOnClose: false,
      serviceId: null,
      isInfoLeftAligned: false,
      isActivatedOnlyOnMyfs: false,
      showInstructionsPopup: false
    }
  },
  props: {
    closeByEsc: {
      type: Boolean,
      default: true
    }
  },
  mixins: [popupMixin],
  components: {
    BaseModalPopup,
    PopupContent
  },
  mounted() {
    let serviceId = parseInt(this.$route.query.activate_service)
    console.log(serviceId)
    ActivationService.isEligible(serviceId).then((response) => {
      if (!response || response.errorCode || !response.customerIsEligible) {
        return
      }

      ActivationService.getServiceDetails(serviceId).then((response) => {
        if (!response || response.errorCode) {
          return
        }

        this.serviceId = serviceId
        let serviceNameKey =
          response.documentElement.querySelector('nameLocKey').textContent
        this.serviceName = this.$t(serviceNameKey)
        let infoTextKey =
          response.documentElement.querySelector('infoLocKey').textContent
        this.infoText = this.$t(infoTextKey, {
          servicename: this.serviceName,
          line_break: '<span style="width:100%;display:inline-block"></span>'
        })
        this.termsUrl =
          response.documentElement.querySelector('termsUrl').textContent
        this.learnmoreUrl =
          response.documentElement.querySelector('learnMoreUrl').textContent
        this.isInfoLeftAligned =
          (response.documentElement.querySelector('isInfoLeftAligned') || {})
            .textContent === 'true'
        this.isActivatedOnlyOnMyfs =
          (
            response.documentElement.querySelector('isActivatedOnlyOnMyfs') ||
            {}
          ).textContent === 'true'
        this.showInstructionsPopup =
          (
            response.documentElement.querySelector('showInstructionsPopup') ||
            {}
          ).textContent === 'true'
        this.isOpen = true
      })
    })
  },
  computed: {
    closeButtonText() {
      if (this.showInstructionsPopup) {
        return this.$t('activation.btn_see_instructions')
      }

      return ''
    }
  },
  methods: {
    enableRefreshOnClose() {
      this.refreshOnClose = true
    },
    openInstructionsPopup() {
      this.$root.$emit('toggle_popup', {
        elementId: 'activation-instructions-popup',
        action: 'open',
        eventInitiator: this.$el,
        data: {
          serviceId: this.serviceId
        }
      })
    },
    closeWithOptionalRefresh() {
      if (this.refreshOnClose) {
        this.$store.dispatch('customer/fetch').then((response) => {
          this.$root.$emit('update-fsdata')
        })
      }
      this.closePopup()
    },
    closeOrShowInstructions() {
      this.closeWithOptionalRefresh()
      if (this.showInstructionsPopup) {
        this.openInstructionsPopup()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-popup.wide.activation ::v-deep {
  > .box {
    max-width: 782px;

    .popup-content {
      padding-bottom: 42px;
    }
  }

  h2 {
    font-size: 2.2rem;
  }
}
</style>
