<template>
  <div class="item" :class="itemCssClass">
    <div class="title">
      <h3>{{ title }}</h3>
    </div>
    <div class="content">
      <p class="value">{{ content }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    content: {
      type: String
    }
  },
  computed: {
    itemCssClass() {
      return this.title.replace(' ', '-').toLowerCase()
    }
  }
}
</script>

<style lang="scss" scoped>
.title h3 {
  color: #666;
  font-weight: 700;
  font-size: 1.14286rem;
  margin-top: 0;
  margin-bottom: 0;
}
.content {
  p {
    margin: 0;
  }
}
</style>
