<template>
  <one-col-box-grid-content-box v-if="isVisible">
    <h2>{{ $t('account.hl_other_settings') }}</h2>
    <p>
      {{ slOtherSettings }}
      <router-link to="/portal/order">
        {{ slOtherSettingsText }}
      </router-link>
    </p>
    <p v-if="isDeleteUserLinkVisible">
      {{ slDeleteUser }}
      <router-link to="/portal/delete-user/confirmation">
        {{ slDeleteUserText }}
      </router-link>
    </p>
    <base-button-link
      id="btn-back-to-home"
      class="primary"
      to="/"
      data-track-event="navigate"
      data-track-name="back-to-home"
    >
      {{ $t('account.sl_back_to_home') }}
    </base-button-link>
  </one-col-box-grid-content-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import OneColBoxGridContentBox from './OneColBoxGridContentBox.vue'
import BaseButtonLink from '@/components/BaseButtonLink.vue'
export default {
  components: {
    OneColBoxGridContentBox,
    BaseButtonLink
  },
  computed: {
    ...mapState({
      isAutomaticSubscription: (state) =>
        state.customer.contract.isAutomaticSubscription
    }),
    ...mapGetters({
      isPrepaid: 'customer/contract/isPrepaid',
      isSubUser: 'customer/contract/isSubUser',
      isSenseSubscription: 'customer/contract/isSenseSubscription'
    }),
    slOtherSettings() {
      return this.$t('account.sl_other_settings').replace(
        /{linkstart}(.*?){linkend}/g,
        ''
      )
    },
    slOtherSettingsText() {
      return /{linkstart}(.*?){linkend}/g.exec(
        this.$t('account.sl_other_settings')
      )[1]
    },
    slDeleteUser() {
      return this.$t('account.sl_delete_user').replace(
        /{linkstart}(.*?){linkend}/g,
        ''
      )
    },
    slDeleteUserText() {
      return /{linkstart}(.*?){linkend}/g.exec(
        this.$t('account.sl_delete_user')
      )[1]
    },
    isVisible() {
      return (
        !(this.isAutomaticSubscription && this.isPrepaid) &&
        !this.isSubUser &&
        !this.isSenseSubscription
      )
    },
    isDeleteUserLinkVisible() {
      return !this.isAutomaticSubscription
    }
  }
}
</script>

<style lang="scss" scoped></style>
