<template>
  <base-view is-blue-bg-view overlayed>
    <template #top>
      <the-header is-nav-shown />
      <one-col-box no-transform add-space-to-top apply-top-margin>
        <the-incomplete-registration-content />
      </one-col-box>
    </template>
    <template #bottom>
      <the-region-picker />
      <the-help />
      <the-footer />
    </template>
  </base-view>
</template>
<script>
import TheHeader from '@/components/TheHeader.vue'
import TheHelp from '@/components/TheHelp.vue'
import TheRegionPicker from '@/components/TheRegionPicker.vue'
import TheFooter from '@/components/TheFooter.vue'
import BaseView from '@/components/BaseView.vue'
import TheIncompleteRegistrationContent from '@/components/TheIncompleteRegistrationContent.vue'
import OneColBox from '@/components/OneColBox.vue'

export default {
  components: {
    OneColBox,
    TheHeader,
    TheRegionPicker,
    TheHelp,
    TheFooter,
    BaseView,
    TheIncompleteRegistrationContent
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$root.$emit('route-entered', to.path, from.path)
      vm.$root.$emit('coupon-redeem-view-entered', to.path, from.path)
    })
  }
}
</script>

<style lang="scss" scoped>
::v-deep .one-col-box {
  min-height: 445px;

  .content {
    padding: 20px;
  }
}

@media (min-width: $desktop-min-width) {
  ::v-deep .one-col-box {
    max-width: 960px;

    .content {
      padding: 48px 60px 40px 60px;
    }
  }
}
</style>
