var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "voucher-item" }, [
    _c("div", { staticClass: "heading" }, [
      _vm._v(" " + _vm._s(_vm.heading) + " "),
    ]),
    _c("div", { staticClass: "content" }, [
      _c("span", { staticClass: "voucher" }, [
        _vm._v(" " + _vm._s(_vm.voucher) + " "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }