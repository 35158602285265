<template>
  <base-button-link-native
    :href="purchaseUrl"
    :data-track-event="trackingEventComputed"
    :data-track-name="trackingNameComputed"
    @click="noClickBind ? '' : navigateOrOpenPopup($event)"
    @click.native="doNothingIfAlreadyClicked"
  >
    {{ buttonText }}
  </base-button-link-native>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import BaseButtonLinkNative from './BaseButtonLinkNative.vue'
export default {
  data() {
    return {
      clicked: false
    }
  },
  props: {
    text: String,
    url: String,
    trackingEvent: String,
    trackingName: String,
    noClickBind: Boolean
  },
  components: {
    BaseButtonLinkNative
  },
  computed: {
    ...mapState({
      safeBuyUrl: (state) => state.ui.cart.safe.url,
      idpBuyUrl: (state) => state.ui.cart.idp.url,
      freedomeBuyUrl: (state) => state.ui.cart.freedome.url,
      totalBuyUrl: (state) => state.ui.cart.total.url,
      upgradeOverrideBuyUrl: (state) => state.ui.cart.upgrade_override.url
    }),
    ...mapGetters({
      purchaseUrlDefault: 'ui/purchaseUrl',
      isRenewCart: 'ui/isRenewCart',
      isBuyNowCart: 'ui/isBuyNowCart',
      isBuyMoreCart: 'ui/isBuyMoreCart'
    }),
    purchaseUrl() {
      if (this.$props.url) {
        return this.$props.url
      }

      return this.purchaseUrlDefault
    },
    buttonText() {
      if (this.$props.text) {
        return this.$props.text
      }

      if (this.isRenewCart) {
        return this.$t('subscription.btn_renew_now')
      }

      if (this.isBuyNowCart) {
        return this.$t('subscription.btn_buy_now')
      }

      if (this.isBuyMoreCart) {
        return this.$t('subscription.btn_buy_more')
      }

      return ''
    },
    trackingEventComputed() {
      if (this.$props.trackingEvent) {
        return this.$props.trackingEvent
      }

      return 'purchase'
    },
    trackingNameComputed() {
      if (this.$props.trackingName) {
        return this.$props.trackingName
      }

      if (this.isRenewCart) {
        return 'renew-now'
      }

      if (this.isBuyNowCart) {
        return 'buy-now'
      }

      if (this.isBuyMoreCart) {
        return 'buy-more'
      }

      return ''
    }
  },
  methods: {
    navigateOrOpenPopup(event) {
      if (this.$props.url) {
        const newLocation = this.$router.resolve(this.$props.url)

        if (newLocation) {
          event.preventDefault()
          this.$router.push(newLocation.resolved.path)
        }
      }

      if (
        (this.safeBuyUrl || this.idpBuyUrl || this.freedomeBuyUrl) &&
        (this.totalBuyUrl || this.upgradeOverrideBuyUrl)
      ) {
        event.preventDefault()
        this.$router.push('/portal/product/upgrade')
      }
    },
    doNothingIfAlreadyClicked(event) {
      if (!this.noClickBind) {
        return
      }

      if (this.clicked) {
        event.preventDefault()
      }

      this.clicked = true
    }
  }
}
</script>
