<template>
  <base-view>
    <template #top>
      <the-header is-nav-shown />
      <the-title-section
        :title="$t('coupon_share.hl_more_products')"
        :sub-title="$t('coupon_share.sl_more_products')"
        is-even-bottom-padding
      />
      <one-col-box>
        <coupon-share-content :product-short-name="productShortName" />
      </one-col-box>
    </template>
    <template #bottom>
      <the-region-picker />
      <the-help />
      <the-footer />
    </template>
  </base-view>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue'
import TheHelp from '@/components/TheHelp.vue'
import TheRegionPicker from '@/components/TheRegionPicker.vue'
import TheFooter from '@/components/TheFooter.vue'
import BaseView from '@/components/BaseView.vue'
import TheTitleSection from '@/components/TheTitleSection.vue'
import OneColBox from '@/components/OneColBox.vue'
import CouponShareContent from '@/components/TheCouponShareViewContent.vue'

export default {
  data() {
    return {
      productShortName: ''
    }
  },
  components: {
    TheHeader,
    TheRegionPicker,
    TheHelp,
    TheFooter,
    BaseView,
    TheTitleSection,
    OneColBox,
    CouponShareContent
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (
        vm.remoteDataLoaded &&
        vm.sharedProductShortNames.includes(to.params.product)
      ) {
        vm.productShortName = to.params.product
      }
      if (
        !vm.productShortName &&
        vm.remoteDataLoaded &&
        vm.sharedProductShortNames.length > 1
      ) {
        vm.productShortName = vm.sharedProductShortNames[0]
      }
      vm.$root.$emit('route-entered', to.path, from.path)
      vm.$root.$emit('coupon-share-view-entered', to.path, from.path)
    })
  },
  computed: {
    sharedProductShortNames() {
      return this.$store.getters['customer/sharedProductShortNames']
    },
    remoteDataLoaded() {
      return this.$store.state.customer.remoteDataLoaded
    }
  },
  methods: {
    updateFocusProductIfNeeded() {
      const productSuggestion = this.$route.params.product
      const isSuggessedProductValidated =
        this.productShortName === productSuggestion
      if (
        !isSuggessedProductValidated &&
        this.sharedProductShortNames.includes(productSuggestion)
      ) {
        this.productShortName = productSuggestion
      } else if (!isSuggessedProductValidated) {
      }
    }
  },
  mounted() {
    this.$root.$on(
      'customer-api-call-finished',
      this.updateFocusProductIfNeeded
    )
  },
  beforeDestroy() {
    this.$root.$off(
      'customer-api-call-finished',
      this.updateFocusProductIfNeeded
    )
  }
}
</script>

<style lang="scss" scoped>
.one-col-box ::v-deep {
  min-height: auto;
}
@media (min-width: 768px) {
  .one-col-box ::v-deep {
    > .content {
      padding: 0 40px;
    }
  }
}
</style>
