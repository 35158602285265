<template>
  <base-settings-content-section id="manage-your-subscription-id">
    <h2 id="hl-option-header">{{ $t('subscription.option_header') }}</h2>
    <base-settings-content-section-grid>
      <div class="short">
        <div class="half">
          <select
            @change="optionChange($event)"
            v-model="optionCode"
            id="subscription-action-select"
          >
            <option selected disabled value="placeholder">
              {{ $t('subscription.option_placeholder') }}
            </option>
            <template v-if="contractHasExpired">
              <option v-if="isPurchaseButtonVisible" value="buy">
                {{ purchaseButtonText }}
              </option>
              <option v-if="downgradeUrl" value="downgrade">
                {{ $t('subscription.option_downgrade') }}
              </option>
              <option value="redeem-code">
                {{ $t('subscription.option_redeem_code') }}
              </option>
            </template>
            <template v-else>
              <option v-if="isPurchaseButtonVisible" value="buy">
                {{ purchaseButtonText }}
              </option>
              <option v-if="downgradeUrl" value="downgrade">
                {{ $t('subscription.option_downgrade') }}
              </option>
              <option
                v-if="isAutomaticSubscription && !isContinuousPrepaid"
                value="cancel-ar"
              >
                {{ $t('subscription.option_cancel_ar') }}
              </option>
              <option v-if="allowMRtoAR" value="mr-to-ar">
                {{ $t('subscription.option_switch_to_ar') }}
              </option>
              <option v-if="allowConvertToAnnual" value="convert-to-annual">
                {{ $t('subscription.option_convert_to_annual') }}
              </option>
              <option v-if="couponRenewalAllowed" value="enter-code">
                {{ $t('subscription.option_enter_code') }}
              </option>
            </template>
          </select>
        </div>
        <div class="half second-half">
          <template v-if="optionCode === 'buy'">
            <template v-if="contractHasExpired">
              {{ $t('subscription.subscription_ended') }}
            </template>
            <template v-else-if="this.isBuyMoreCart">{{
              $t('subscription.option_buy_more_subtext')
            }}</template>
          </template>
          <template v-if="optionCode === 'downgrade'">
            {{ $t('subscription.option_downgrade_subtext') }}
          </template>
          <template v-else-if="optionCode === 'redeem-code'"
            ><span v-html="$t('subscription.option_redeem_code_subtext')"
          /></template>
          <template v-else-if="optionCode === 'cancel-ar'"></template>
          <template v-else-if="optionCode === 'mr-to-ar'">
            {{ $t('subscription.option_switch_to_ar_subtext') }}<br />
            <router-link to="/portal/enable_autorenew_reason">
              {{ $t('subscription.option_switch_to_ar_subtext_link') }}
            </router-link>
          </template>
          <template v-else-if="optionCode === 'convert-to-annual'">
            {{ $t('subscription.option_convert_to_annual_subtext') }}<br />
          </template>
          <template
            v-else-if="
              optionCode === 'enter-code' &&
              !isContinuousPrepaid &&
              !isTrial &&
              !inCouponWhitelistedSegment
            "
            ><span
              v-html="
                $t('subscription.option_enter_code_subtext', {
                  voucherRedemptionDateFormatted: voucherRedemptionDateFormatted
                })
              "
          /></template>
          <template v-else-if="optionCode === 'enter-code'"
            ><span
              v-html="$t('subscription.option_enter_code_subtext_prepaid_ar')"
          /></template>
        </div>
      </div>
      <div class="long">
        <template v-if="optionCode === 'buy'">
          <base-purchase-button id="btn-overview-buy" class="primary" />
        </template>
        <template v-else-if="optionCode === 'downgrade'">
          <base-purchase-button
            id="btn-overview-downgrade"
            class="primary"
            :text="$t('subscription.option_downgrade')"
            :url="downgradeUrl"
            data-track-event="navigate"
            data-track-name="downgrade"
            noClickBind
          />
        </template>
        <template v-else-if="optionCode === 'redeem-code'">
          <base-button-link
            id="btn-overview-redeem-code"
            class="primary"
            to="/portal/coupon/redeem"
            data-track-event="navigate"
            data-track-name="redeem-code"
          >
            {{ $t('subscription.option_redeem_code') }}
          </base-button-link>
        </template>
        <template v-else-if="optionCode === 'cancel-ar'">
          <base-button-link
            id="btn-overview-cancel-ar"
            class="primary"
            to="/portal/cancel_autorenew"
            data-track-event="navigate"
            data-track-name="cancel-autorenew"
          >
            {{ $t('subscription.lnk_cancel_subscription') }}
          </base-button-link>
        </template>
        <template v-else-if="optionCode === 'mr-to-ar'">
          <base-button-link
            id="btn-overview-mr-to-ar"
            class="primary"
            to="/portal/enable_autorenew"
            data-track-event="navigate"
            data-track-name="mr-to-ar"
          >
            {{ $t('subscription.option_switch_to_ar') }}
          </base-button-link>
        </template>
        <template v-else-if="optionCode === 'convert-to-annual'">
          <base-button-link
            id="btn-overview-convert-to-annual"
            class="primary"
            to="/portal/convert_to_annual"
            data-track-event="navigate"
            data-track-name="convert-to-annual"
          >
            {{ $t('subscription.option_convert_to_annual_btn') }}
          </base-button-link>
        </template>
        <template
          v-else-if="optionCode === 'enter-code' && inCouponRenewalPeriod"
        >
          <base-button-link
            id="btn-overview-renew"
            class="primary"
            to="/portal/coupon/renew"
            data-track-event="navigate"
            data-track-name="coupon-renew"
          >
            {{ $t('subscription.option_enter_code') }}
          </base-button-link>
        </template>
      </div>
    </base-settings-content-section-grid>
    <br />
    <template v-if="senseStatusText">
      <h2 id="hl-f-secure-sense">F-Secure SENSE</h2>
      <base-settings-content-section-grid>
        <div class="long">
          <p id="sense-status">{{ senseStatusText }}</p>
        </div>
      </base-settings-content-section-grid>
    </template>
  </base-settings-content-section>
</template>

<script>
import BaseSettingsContentSection from './BaseSettingsContentSection.vue'
import BaseSettingsContentSectionGrid from './BaseSettingsContentSectionGrid.vue'
import { mapState, mapGetters } from 'vuex'
import BasePurchaseButton from './BasePurchaseButton.vue'
import BaseButtonLink from './BaseButtonLink.vue'

export default {
  data() {
    return {
      optionCode: 'placeholder'
    }
  },
  watch: {
    $route() {
      this.optionCode = 'placeholder'
    }
  },
  components: {
    BaseSettingsContentSection,
    BaseSettingsContentSectionGrid,
    BasePurchaseButton,
    BaseButtonLink
  },
  computed: {
    ...mapState({
      isAutomaticSubscription: (state) =>
        state.customer.contract.isAutomaticSubscription,
      couponRenewalAllowed: (state) =>
        state.customer.contract.couponRenewalAllowed,
      inCouponRenewalPeriod: (state) =>
        state.customer.contract.inCouponRenewalPeriod,
      senseStatus: (state) => state.customer.senseStatus,
      allowMRtoAR: (state) =>
        state.customer.options &&
        state.customer.options.mr_to_ar &&
        state.customer.options.mr_to_ar.conversion_allowed,
      allowConvertToAnnual: (state) =>
        state.customer.options &&
        state.customer.options.annual_conversion &&
        state.customer.options.annual_conversion.conversion_allowed,
      downgradeUrl: (state) => state.ui.cart.downgrade
    }),
    ...mapGetters({
      contractHasExpired: 'customer/contract/hasExpired',
      isPurchaseButtonVisible: 'ui/isPurchaseButtonVisible',
      isContinuousPrepaid: 'customer/contract/isContinuousPrepaid',
      isTrial: 'customer/contract/isTrial',
      isRenewCart: 'ui/isRenewCart',
      isBuyNowCart: 'ui/isBuyNowCart',
      isBuyMoreCart: 'ui/isBuyMoreCart',
      voucherRedemptionDateFormatted:
        'customer/contract/voucherRedemptionDateFormatted',
      inCouponWhitelistedSegment: 'customer/contract/inCouponWhitelistedSegment'
    }),
    senseStatusText() {
      if (this.senseStatus === 'ordered') {
        return this.$t('subscription.txt_sense_ordered')
      }

      if (this.senseStatus === 'shipped') {
        return this.$t('subscription.txt_sense_shipped')
      }

      if (this.senseStatus === 'activated') {
        return this.$t('subscription.txt_sense_activated')
      }
      return ''
    },
    purchaseButtonText() {
      if (this.isRenewCart) {
        return this.$t('subscription.option_renew_now')
      }

      if (this.isBuyNowCart) {
        return this.$t('subscription.option_buy_now')
      }

      if (this.isBuyMoreCart) {
        return this.$t('subscription.option_buy_more')
      }
      return ''
    }
  },
  methods: {
    optionChange($event) {
      this.optionCode = $event.target.value
    }
  }
}
</script>

<style lang="scss" scoped>
.short {
  text-align: left !important;
}
.long {
  text-align: right;
}
.half {
  min-width: 50%;
  max-width: 50%;
  vertical-align: middle;
  display: table-cell;
  select {
    min-width: 100%;
    padding: 10px;
  }
}

.second-half {
  padding: 0 20px;
}
</style>
