<template>
  <iframe
    v-if="doExist"
    :src="iframeUrlManaged"
    frameborder="0"
    @load="onLoad"
  ></iframe>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  data() {
    return {
      isActive: false
    }
  },
  activated() {
    this.isActive = true
  },
  deactivated() {
    this.isActive = false
  },
  computed: {
    ...mapGetters({
      customerIsActive: 'customer/isActive',
      isSenseSubscription: 'customer/contract/isSenseSubscription'
    }),
    ...mapState({
      appDataLoaded: (state) => state.customer.remoteDataLoaded,
      customerIsLoggedIn: (state) => state.customer.isLoggedIn,
      iframeUrl: (state) => state.customer.safeAvenueIframeUrl
    }),
    iframeUrlManaged() {
      if (this.doExist && this.isActive) {
        return this.iframeUrl
      }

      return ''
    },
    doExist() {
      return (
        this.appDataLoaded &&
        this.customerIsLoggedIn &&
        this.url &&
        !this.isSenseSubscription
      )
    }
  },
  methods: {
    onLoad() {
      if (!this.isActive) {
        return
      }

      setTimeout(() => {
        this.$root.$emit('all-product-iframes-loaded')
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
iframe {
  visibility: hidden;
  height: 0;
  width: 0;
  display: block;
}
</style>
