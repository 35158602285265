import DataService from './DataService'
export default {
  async postDiscountApply(discountInput, token) {
    return DataService.post(
      process.env.VUE_APP_API_BASE_URL + '/apply_discount',
      {
        discount_code: discountInput,
        token
      }
    )
  },
  async fetchDiscountedPrice() {
    return DataService.get(process.env.VUE_APP_API_BASE_URL + '/get_discount')
  }
}
