var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-view", {
    ref: "base-view",
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function () {
          return [
            _c("the-header", { attrs: { "no-second-section": "" } }),
            _c("the-title-section", {
              attrs: {
                title: _vm.$t("registration.hl_fsecure_service"),
                subTitle: _vm.$t(
                  "registration.hl_protection_for_all_your_needs"
                ),
              },
            }),
            _c(
              "one-col-box",
              { attrs: { "no-padding": "" } },
              [_c("the-token-error-section")],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "bottom",
        fn: function () {
          return [_c("the-region-picker"), _c("the-help"), _c("the-footer")]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }