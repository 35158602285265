var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "one-col-box-grid-content-box",
    [
      _vm.showSuccess
        ? [
            _c("h2", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("convert_to_annual.hl_conversion_success")) +
                  " "
              ),
            ]),
            _c("p", [
              _c("span", { staticClass: "icon" }, [
                _c("img", {
                  attrs: {
                    src: require("../assets/images/icons/icon_status_green_fill_small.svg"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("convert_to_annual.conversion_success_desc", {
                      currency: _vm.billingCurrency,
                      nextBillingDate: _vm.nextBillingDate,
                      amount: _vm.billingAmount,
                    })
                  ) +
                  " "
              ),
            ]),
            _c(
              "base-button-link",
              {
                staticClass: "primary",
                attrs: { to: "/portal/order" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.resetData.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("convert_to_annual.btn_conversion_success_back")
                    ) +
                    " "
                ),
              ]
            ),
          ]
        : [
            _c("h2", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("convert_to_annual.hl_convert_to_annual")) +
                  " "
              ),
            ]),
            _c("p", [
              _vm._v(
                " " + _vm._s(_vm.$t("convert_to_annual.hl_description")) + " "
              ),
            ]),
            _c(
              "base-button-link",
              {
                staticClass: "primary",
                class: { loading: _vm.loading },
                attrs: {
                  "prevent-click": "",
                  to: "/portal/order",
                  "data-track-event": "navigate",
                  "data-track-name": "confirmed-monthly-to-annual",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.convertToAnnual.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("convert_to_annual.btn_confirm")) + " "
                ),
              ]
            ),
            _c(
              "base-button-link",
              {
                staticClass: "neutral",
                attrs: { to: "/portal/order" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.resetData.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("convert_to_annual.btn_cancel_back")) +
                    " "
                ),
              ]
            ),
            _c("p", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("convert_to_annual.hl_description2", {
                      nextBillingDate: _vm.nextBillingDate,
                      currency: _vm.billingCurrency,
                      amount: _vm.billingAmount,
                    })
                  ) +
                  " "
              ),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }