<template>
  <div class="the-maintenance-title-section">
    <div>
      <h1>{{ $t('maintenance.hl_temporarily_down') }}</h1>
      <div class="img" />
      <p>{{ $t('maintenance.txt_down_for_maintenance') }}</p>
      <p>{{ $t('maintenance.txt_thanks') }}</p>
    </div>
  </div>
</template>
<script>
export default {}
</script>

<style lang="scss" scoped>
.the-maintenance-title-section {
  padding: 16px;

  h1 {
    font-size: 1.71429rem;
    color: $primary-color;
    font-weight: 600;
    text-align: center;
    margin-bottom: 24px;
  }

  p {
    font-weight: 300;
    text-align: center;
  }

  .img {
    width: 88px;
    height: 88px;
    margin-left: auto;
    margin-right: auto;
    background-image: url('../assets/images/error_server.svg');
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin-bottom: 10px;
  }
}
@media (min-width: $desktop-min-width) {
  .the-maintenance-title-section {
    padding: 32px;

    h1 {
      margin-bottom: 40px;
    }

    p {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }

    .img {
      width: 160px;
      height: 160px;
      margin-bottom: 20px;
    }
  }
}
</style>
