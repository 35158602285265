import DataService from './DataService'

export default {
  async fetchServiceAdditionalData(serviceId) {
    return DataService.get(
      process.env.VUE_APP_API_BASE_URL +
        `/additional_service/get_additional_info?serviceId=${serviceId}`
    )
  },
  async postActivation(serviceId, token) {
    return DataService.post(
      process.env.VUE_APP_API_BASE_URL + '/additional_service/activate',
      { serviceId, token }
    )
  },
  async getServiceDetails(serviceId) {
    return DataService.get(`/services/${serviceId}.xml`, 'xml')
  },
  async isEligible(serviceId) {
    return DataService.get(
      process.env.VUE_APP_API_BASE_URL +
        `/additional_service/is_customer_eligible?serviceId=${serviceId}`
    )
  }
}
