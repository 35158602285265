import DataService from './DataService'

export default {
  async fetchVoucherUpdate(couponInput) {
    return DataService.get(
      process.env.VUE_APP_API_BASE_URL +
        '/get_voucher_update?voucher=' +
        couponInput.trim()
    )
  },
  async postVoucherApply(couponInput, token) {
    return DataService.post(
      process.env.VUE_APP_API_BASE_URL + '/apply_voucher',
      {
        voucher: couponInput.trim(),
        token
      }
    )
  }
}
