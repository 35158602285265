<template>
  <div class="container" style="padding-top: 80px">
    <img src="../assets/images/coupon-apply-success.svg" />
    <h1>{{ $t('coupon_apply.success_heading') }}</h1>
  </div>
</template>
<script>
export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$root.$emit('route-entered', to.path, from.path)
      vm.$root.$emit('coupon-apply-success-view-entered', to.path, from.path)
    })
  },
  data() {
    return {
      redeem: false,
      serviceKey: null,
      fromIncompleteRegistration: false
    }
  },
  methods: {
    redirect() {
      if (this.redeem) {
        if (this.fromIncompleteRegistration) {
          this.$router.push(`/portal/complete-registration?step=3`)
        } else {
          this.$router.push('/portal/welcome')
        }
      } else {
        this.$router.push('/portal/welcome')
      }
    }
  },
  mounted() {
    this.serviceKey = this.$route.query.serviceKey
    if (this.$route.query.redeem) {
      this.redeem = true
    }
    if (this.$route.query.fromIncompleteReg) {
      this.fromIncompleteRegistration = true
    }
    setTimeout(() => {
      this.redirect()
    }, 5000)
  }
}
</script>
<style lang="scss" scoped>
@media (min-width: $desktop-min-width) {
  .container {
    text-align: center;
  }
}
</style>
