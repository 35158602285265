export default {
  methods: {
    transchoice(string, value) {
      if (!string) {
        return ''
      }

      let choices = string.split('|').sort().reverse()
      let valtoInfVal = null
      let valtoInfText = null
      let infToValVal = null
      let infToValText = null

      for (const choiceEntry of Object.entries(choices)) {
        const choice = choiceEntry[1]
        let infToVal = /\[-Inf,(\d)\](.*)/g.exec(choice)

        if (infToVal) {
          let tmpVal = parseInt(infToVal[1])
          infToValText = infToVal[2]
          if (value < tmpVal) {
            return infToValText
          }
          infToValVal = tmpVal
          continue
        }

        let valToInf = /\[(\d),Inf\](.*)/g.exec(choice)

        if (valToInf) {
          let tmpVal = parseInt(valToInf[1])
          valtoInfText = valToInf[2]
          if (value > tmpVal) {
            return valtoInfText
          }
          valtoInfVal = tmpVal
          continue
        }

        let valVal = /\{(\d)\}(.*)/g.exec(choice)

        if (valVal) {
          let tmpVal = parseInt(valVal[1])
          if (tmpVal === value) {
            return valVal[2]
          }
          continue
        } else if (valtoInfVal && value === valtoInfVal) {
          return choice
        } else if (
          infToValVal !== null &&
          valtoInfVal !== null &&
          infToValVal < valtoInfVal &&
          value === infToValVal
        ) {
          return infToValText
        }
      }

      return ''
    }
  }
}
