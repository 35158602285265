<template>
  <one-col-box-grid-content-box v-if="isVisible">
    <h2>{{ $t('account.hl_notification_settings') }}</h2>
    <p v-html="bodyText" />
  </one-col-box-grid-content-box>
</template>

<script>
import { mapGetters } from 'vuex'
import OneColBoxGridContentBox from './OneColBoxGridContentBox.vue'
import CustomerService from '@/services/CustomerService'

export default {
  data() {
    return {
      sfmcUrl: null
    }
  },
  components: {
    OneColBoxGridContentBox
  },
  async mounted() {
    const newData = await CustomerService.fetchMarketingInfo()
    this.sfmcUrl = newData.url
  },
  computed: {
    ...mapGetters({
      isSubUser: 'customer/contract/isSubUser'
    }),
    isVisible() {
      return !this.isSubUser
    },
    bodyText() {
      if (this.sfmcUrl) {
        return this.$t('account.sl_notification_settings_url', {
          linkstart: `<a href="${this.sfmcUrl}" target="_blank">`,
          linked: '</a>'
        })
      }
      return this.$t('account.sl_notification_settings')
    }
  }
}
</script>

<style lang="scss" scoped></style>
