<template>
  <div class="grid">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-gap: 0;
  grid-template-columns: 1fr;

  p {
    margin: 3px 0;
    font-weight: 300;
    color: $font-color;
  }

  .short {
    p {
      font-size: 1.214rem;
    }

    + .long {
      margin-top: 20px;
    }
  }
}

@media (min-width: $desktop-min-width) {
  .grid {
    grid-gap: 7px 20px;
    grid-template-columns: 1fr 240px;

    .short + .long {
      margin-top: 0;
    }

    > div {
      &.long {
        display: flex;
        align-items: center;
      }

      &.short {
        text-align: right;
      }
    }
  }
}

@media screen and (-ms-high-contrast: active) and (min-width: $desktop-min-width),
  screen and (-ms-high-contrast: none) and (min-width: $desktop-min-width) {
  .grid {
    display: -ms-grid;
    -ms-grid-columns: 1fr 20px 240px;
    -ms-grid-rows: 1fr 7px 1fr 7px 1fr 7px 1fr;

    > div {
      &:nth-child(1) {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
      }
      &:nth-child(2) {
        -ms-grid-row: 1;
        -ms-grid-column: 3;
      }
      &:nth-child(3) {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
      }
      &:nth-child(4) {
        -ms-grid-row: 3;
        -ms-grid-column: 3;
      }
      &:nth-child(5) {
        -ms-grid-row: 5;
        -ms-grid-column: 1;
      }
      &:nth-child(6) {
        -ms-grid-row: 5;
        -ms-grid-column: 3;
      }
      &:nth-child(7) {
        -ms-grid-row: 7;
        -ms-grid-column: 1;
      }
      &:nth-child(8) {
        -ms-grid-row: 7;
        -ms-grid-column: 3;
      }
    }
  }
}
</style>
