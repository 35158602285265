import { mapState, mapGetters } from 'vuex'
import VoucherService from '@/services/VoucherService'
import { required } from 'vuelidate/lib/validators'
import transchoice from '@/mixins/transchoice'
import DataMixin from '@/mixins/data'

export default {
  data() {
    return {
      couponInput: '',
      sentInput: '',
      applyingCoupon: false,
      validatingCoupon: false,
      couponValid: false,
      couponInvalid: false,
      newDaysLeft: null,
      newDeviceCount: null,
      oldDeviceCount: null,
      oldServiceKey: null,
      newServiceKey: null,
      oldProductVariant: null,
      newProductVariant: null,
      couponPromotionName: 'F-Secure',
      affiliate: null,
      validationError: null,
      isDeviceCountDowngrade: false,
      isProductChange: false
    }
  },
  mixins: [transchoice, DataMixin],
  computed: {
    ...mapState({
      daysUntilExpiration: (state) =>
        state.customer.contract.daysUntilExpiration,
      licenseSize: (state) => state.customer.contract.licenseSize,
      isAutomaticSubscription: (state) =>
        state.customer.contract.isAutomaticSubscription,
      isPartnerManagedSubscription: (state) =>
        state.customer.contract.isAutomaticSubscription &&
        state.customer.contract.type === 'PREPAID' &&
        state.customer.affiliate !== 'fsecure',
      subscriptionPromotionName: (state) => state.customer.promotionShowName
    }),
    ...mapGetters({
      isManualSubscription: 'customer/contract/isManualSubscription',
      expirationDateFormatted: 'customer/contract/expirationDateFormatted',
      isTrial: 'customer/contract/isTrial',
      productNames: 'ui/productNames',
      unifiedProductNames: 'ui/unifiedProductNames',
      voucherRedemptionDateFormatted:
        'customer/contract/voucherRedemptionDateFormatted'
    }),
    isButtonDisabled() {
      return this.validatingCoupon
    },
    supportHelpText() {
      return this.$t('coupon_renew.ntc_contact', {
        linkstart:
          '<a href="https://www.f-secure.com/support-for-home" target="_blank" rel="noopener">',
        linkend: '</a>'
      })
    },
    containsLengthOfProtectionText() {
      if (this.newDaysLeft === null && this.couponValid) {
        if (this.isPartnerManagedCoupon) {
          return this.$t('coupon_renew.ntc_code_contains_partner_billed', {
            devices: this.newDeviceCount
          })
        } else {
          return this.$t('coupon_renew.ntc_code_contains_continuous', {
            devices: this.newDeviceCount
          })
        }
      }

      return this.$t('coupon_renew.ntc_code_contains', {
        days: this.newDaysLeft,
        devices: this.newDeviceCount
      })
    },
    licenseSizeText() {
      return this.$t('subscription.x_devices', { maxdevices: this.licenseSize })
    },
    daysLeftText() {
      if (this.isPartnerManagedSubscription) {
        return this.managedBySubscriptionPromotion
      }
      return this.isAutomaticSubscription
        ? this.$t('subscription.txt_continuous')
        : this.daysUntilExpirationText
    },
    currentDaysLeftText() {
      if (this.isAutomaticSubscription) {
        if (this.isPartnerManagedSubscription) {
          return this.managedBySubscriptionPromotion
        } else {
          return this.$t('subscription.txt_continuous')
        }
      }
      return this.transchoice(
        this.$t('coupon_renew.di_day_quantity', {
          quantity: this.daysUntilExpiration
        }),
        this.daysUntilExpiration
      )
    },
    managedByCouponPromotion() {
      return this.$t('subscription.txt_managed_by_x', {
        promotion: this.couponPromotionName
      })
    },
    managedBySubscriptionPromotion() {
      return this.$t('subscription.txt_managed_by_x', {
        promotion: this.subscriptionPromotionName
      })
    },
    isPartnerManagedCoupon() {
      return (
        this.affiliate !== 'fsecure' &&
        this.newDaysLeft === null &&
        this.couponValid
      )
    },
    newDaysLeftText() {
      if (this.newDaysLeft === null && this.couponValid) {
        if (this.isPartnerManagedCoupon) {
          return this.managedByCouponPromotion
        } else {
          return this.$t('subscription.txt_continuous')
        }
      }
      return this.transchoice(
        this.$t('coupon_renew.di_day_quantity', {
          quantity: this.newDaysLeft
        }),
        this.newDaysLeft
      )
    },
    daysUntilExpirationText() {
      return this.transchoice(
        this.$t('coupon_renew.di_day_quantity', {
          quantity: this.daysUntilExpiration
        }),
        this.daysUntilExpiration
      )
    },
    newRenewalTypeText() {
      if (this.newDaysLeft === null && this.couponValid) {
        if (this.isPartnerManagedCoupon) {
          return this.managedByCouponPromotion
        } else {
          return this.$t('subscription.automatic_renewal')
        }
      }

      return this.renewalTypeText
    },
    renewalTypeText() {
      if (this.isTrial) {
        return this.$t('subscription.manual_renewal')
      }

      if (this.isAutomaticSubscription) {
        if (this.isPartnerManagedSubscription) {
          return this.managedBySubscriptionPromotion
        } else {
          return this.$t('subscription.automatic_renewal')
        }
      }

      if (this.isManualSubscription) {
        return this.$t('subscription.manual_renewal')
      }

      return ''
    },
    hintText() {
      if (
        this.isDeviceCountDowngrade === true ||
        this.isProductChange === 'product_downgrade'
      ) {
        let currentProductName = this.getProductName(
          this.oldServiceKey,
          this.oldProductVariant
        )
        return this.$t('coupon_renew.ntc_hint_downgrade', {
          product_name: currentProductName,
          license_size: this.oldDeviceCount
        })
      } else if (this.isProductChange === 'product_change') {
        return this.$t('coupon_renew.ntc_hint_change')
      }
      return this.$t('coupon_renew.ntc_hint_devices')
    },
    inputPlaceholder() {
      return this.$t('coupon_renew.el_subscription_code') + ' *'
    },
    validationErrorMessage() {
      if (this.validationError === 'invalid_voucher') {
        return this.$t('coupon_renew.error_coupon_not_found', {
          code: this.sentInput
        })
      }

      if (this.validationError === 'voucher_used_up') {
        return this.$t('coupon_renew.coupon_limit_reached')
      }

      if (this.validationError === 'voucher_already_used') {
        return this.$t('coupon_renew.coupon_limit_reached')
      }

      if (this.validationError === 'promotion_not_allowed_in_location') {
        return this.$t('coupon_renew.error_campaign_not_available_in_country')
      }

      if (this.validationError === 'voucher_not_allowed_in_location') {
        return this.$t('coupon_renew.error_code_not_available')
      }

      if (this.validationError === 'operation_not_allowed_for_ar') {
        return this.$t('coupon_renew.error_coupon_not_allowed_for_continuous')
      }

      if (this.validationError === 'subscription_still_valid') {
        return this.$t('coupon_renew.subscription_still_valid')
      }

      if (this.validationError === 'try_again_later') {
        return this.$t('coupon_renew.error_try_again_later')
      }

      if (this.validationError === 'invalid_redemption_period') {
        return this.$t('coupon_renew.error_invalid_redemption_period', {
          voucherRedemptionDateFormatted: this.voucherRedemptionDateFormatted
        })
      }

      return this.$t('coupon_renew.coupon_service_incorrect')
    }
  },

  methods: {
    resetState() {
      this.couponInput = ''
      this.sentInput = ''
      this.applyingCoupon = false
      this.validatingCoupon = false
      this.couponValid = false
      this.couponInvalid = false
      this.newDaysLeft = null
      this.newDeviceCount = null
      this.oldDeviceCount = null
      this.oldServiceKey = null
      this.newServiceKey = null
      this.oldProductVariant = null
      this.newProductVariant = null
      this.couponPromotionName = 'F-Secure'
      this.affiliate = null
      this.validationError = null
      this.isDeviceCountDowngrade = null
      this.isProductChange = null
      this.$v.couponInput.$reset()
    },
    navigateToExit() {
      this.$router.push('/portal/order')
    },
    async applyVoucher() {
      if (this.applyingCoupon) {
        return
      }
      this.applyingCoupon = true
      const data = await this.preHandleResponse(() =>
        VoucherService.postVoucherApply(
          this.couponInput,
          this.$store.state.customer.token
        )
      )

      if (!data) {
        this.applyingCoupon = false
        this.validatingCoupon = false
        this.couponValid = false
        return
      }

      if (data.errorCode) {
        this.applyingCoupon = false
        this.validatingCoupon = false
        this.couponInvalid = true
        this.couponValid = false
        this.sentInput = this.couponInput
        this.validationError = data.errorCode
        return
      }

      this.$store.dispatch('customer/fetch').then((response) => {
        this.$store.dispatch('customer/fetchBillingHistory')
        this.$store.dispatch('ui/fetchCart')
        this.$store.dispatch('ui/fetchCustomizations')
        this.$store.dispatch('ui/fetchCouponShareProductNames')

        // this.$store.commit('customer/SET_JUST_REGISTERED', false)

        this.resetState()
      })
      this.redirectToSuccess()
    },
    getProductName(serviceKey, productVariant) {
      let key = productVariant || serviceKey
      let productNameMap = {
        SAFE: this.productNames.safe,
        IDP: this.productNames.key,
        FREEDOME: this.productNames.freedome,
        TOTAL: this.productNames.total,
        TOTAL_IDM: this.productNames.totalIdm,
        total_epp: this.unifiedProductNames.epp,
        total_vpn: this.unifiedProductNames.vpn,
        total_idp: this.unifiedProductNames.idp,
        total_epp_vpn: this.unifiedProductNames.eppVpn,
        total_epp_idp: this.unifiedProductNames.eppIdp,
        total_vpn_idp: this.unifiedProductNames.vpnIdp,
        total_standard: this.unifiedProductNames.total,
        total_standard_with_sense: this.unifiedProductNames.total,
        total_standard_id_monitoring_only:
          this.unifiedProductNames.totalIdMonitoring,
        total_epp_scam: this.unifiedProductNames.eppScam,
        total_epp_idp_scam: this.unifiedProductNames.eppIdpScam,
        total_epp_vpn_scam: this.unifiedProductNames.eppVpnScam
      }
      let productName = productNameMap[key]
      return productName
    },
    async validateCoupon() {
      if (this.$v.couponInput.$invalid) {
        this.$refs['coupon-input'].$el.focus()
        this.$v.couponInput.$touch()
        return
      }

      this.validatingCoupon = true
      const data = await this.preHandleResponse(() =>
        VoucherService.fetchVoucherUpdate(this.couponInput)
      )

      if (!data) {
        this.validatingCoupon = false
        this.couponValid = false
        return
      }

      if (data.errorCode) {
        this.validatingCoupon = false
        this.couponInvalid = true
        this.couponValid = false
        this.sentInput = this.couponInput
        this.validationError = data.errorCode
        return
      }

      this.$root.$emit('update-fsdata-pagename', 'coupon-renewal-confirmation')

      this.newDaysLeft = data.newDaysLeft
      this.newDeviceCount = data.newDeviceCount
      this.oldDeviceCount = data.oldDeviceCount
      if ('promotionName' in data) {
        this.couponPromotionName = data.promotionName
      }
      if ('affiliate' in data) {
        this.affiliate = data.affiliate
      }

      this.oldServiceKey = data.oldServiceKey
      this.newServiceKey = data.newServiceKey
      this.newProductVariant = data.newProductVariant
      this.oldProductVariant = data.oldProductVariant
      this.sameProduct = data.sameProduct
      this.oldProductName = this.getProductName(
        this.oldServiceKey,
        this.oldProductVariant
      )
      this.newProductName = this.getProductName(
        this.newServiceKey,
        this.newProductVariant
      )
      this.validatingCoupon = false
      this.isDeviceCountDowngrade = data.isDeviceCountDowngrade
      this.isProductChange = data.isProductChange
      this.couponValid = true
    }
  },
  validations: {
    couponInput: {
      required
    }
  }
}
