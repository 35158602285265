var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "one-col-box-grid-content-box",
    [
      _c("h2", [
        _vm._v(_vm._s(_vm.$t("delete_user_confirmation.hl_delete_user"))),
      ]),
      _c("p", [
        _vm._v(
          _vm._s(_vm.$t("delete_user_confirmation.question_if_delete_user"))
        ),
      ]),
      _c("p", [
        _vm._v(
          _vm._s(_vm.$t("delete_user_confirmation.delete_result_with_contract"))
        ),
      ]),
      _c(
        "base-button",
        {
          staticClass: "alert",
          class: { loading: _vm.loading },
          on: { click: _vm.deleteUser },
        },
        [_vm._v(" " + _vm._s(_vm.$t("delete_user_confirmation.yes")) + " ")]
      ),
      _c(
        "base-button-link",
        { staticClass: "neutral", attrs: { to: "/portal/contact" } },
        [_vm._v(" " + _vm._s(_vm.$t("delete_user_confirmation.no")) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }