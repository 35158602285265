<template>
  <one-col-box-grid-content-box>
    <h2>{{ $t('account.hl_email') }}</h2>
    <base-loading-input-wrapper
      id="input-change-email"
      type="text"
      v-model="emailInput"
      @blur="touchIfChangedOrReset"
      @keyup="touchIfChangedOrReset"
      :class="{ error: $v.emailInput.$error }"
      :disabled="loading"
      :loading="loading"
    />
    <template v-if="$v.emailInput.$error">
      <p v-if="!$v.emailInput.required" class="error-message">
        {{ $t('account.form_field_required') }}
      </p>
      <p v-if="!$v.emailInput.email" class="error-message">
        {{ $t('account.sl_invalid_email_error') }}
      </p>
    </template>
    <p v-if="showEmailInUseErrorMessage" class="error-message">
      {{ $t('account.sl_email_already_in_use') }}
    </p>
    <p v-if="showGenericErrorMessage" class="error-message">
      {{ $t('account.sl_email_generic_error') }}
    </p>
    <base-button
      id="btn-change-email"
      class="primary"
      :class="{ disabled: isButtonDisabled }"
      :disabled="isButtonDisabled"
      @click="changeEmail"
      data-track-event="navigate"
      data-track-name="change-email"
    >
      {{ $t('account.btn_save_changes') }}
    </base-button>
    <p v-if="showSuccessMessage" class="success-message">
      {{ $t('account.sl_email_saved') }}
    </p>
  </one-col-box-grid-content-box>
</template>

<script>
import BaseLoadingInputWrapper from './BaseLoadingInputWrapper.vue'
import { required, email } from 'vuelidate/lib/validators'
import OneColBoxGridContentBox from './OneColBoxGridContentBox.vue'
import BaseButton from '@/components/BaseButton.vue'
import CustomerService from '@/services/CustomerService'
import DataMixin from '@/mixins/data'
export default {
  data() {
    return {
      loading: false,
      emailInput: '',
      emailInputOriginal: '',
      showSuccessMessage: false,
      showGenericErrorMessage: false,
      showEmailInUseErrorMessage: false,
      successMessageTimeout: null
    }
  },
  mixins: [DataMixin],
  activated() {
    this.emailInput = this.customerEmail
    this.emailInputOriginal = this.customerEmail
  },
  components: {
    OneColBoxGridContentBox,
    BaseButton,
    BaseLoadingInputWrapper
  },
  computed: {
    customerEmail() {
      return this.$store.state.customer.email
    },
    isButtonDisabled() {
      return (
        this.loading || this.$v.emailInput.$error || !this.$v.emailInput.$dirty
      )
    }
  },
  methods: {
    async changeEmail() {
      if (this.$v.emailInput.$error) {
        return
      }

      this.loading = true
      const response = await this.preHandleResponse(() =>
        CustomerService.postChangeEmail(
          this.emailInput,
          this.$store.state.customer.token
        )
      )

      if (!response || response.errorCode) {
        // not the best way to handle, the email error responses should be stored into another json file to be referenced.
        if (response.errorCode === 'email_already_in_use') {
          this.showEmailInUseErrorMessage = true
        } else {
          this.showGenericErrorMessage = true
        }
        this.showSuccessMessage = false
        this.loading = false
        return
      }

      this.$store.commit('customer/SET_EMAIL', this.emailInput)
      this.emailInputOriginal = this.emailInput
      this.$v.emailInput.$reset()
      this.showSuccessMessage = true
      this.showEmailInUseErrorMessage = false
      this.showGenericErrorMessage = false
      this.loading = false

      if (this.successMessageTimeout) {
        clearTimeout(this.successMessageTimeout)
      }

      this.successMessageTimeout = setTimeout(() => {
        this.showSuccessMessage = false
      }, 5000)
    },
    touchIfChangedOrReset() {
      if (this.emailInput === this.emailInputOriginal) {
        this.$v.emailInput.$reset()
        return
      }

      this.$v.emailInput.$touch()
    }
  },
  validations: {
    emailInput: {
      required,
      email
    }
  }
}
</script>

<style lang="scss" scoped>
.input-wrapper {
  &.loading {
    position: relative;

    &::before {
      content: '';
      background: url('../assets/images/spinner.svg');
      background-repeat: repeat;
      background-size: auto;
      position: absolute;
      z-index: 2;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center right;
    }
  }
}
.box {
  p {
    &.error-message {
      color: $danger-color;
      padding: 7px 0;
      font-size: 1em;
    }
    &.success-message {
      color: $ok-color;
      padding: 7px 0;
      font-size: 1em;
    }
  }
}
</style>
