<template>
  <div class="coupon-share" :class="{ loading: contentLoading }">
    <content-loading-indicator v-if="contentLoading" />
    <template v-else>
      <h2>{{ viewTitle }}</h2>
      <div class="padded-icon">
        <picture>
          <img :src="iconPath" alt="" />
        </picture>
        <div class="content">
          <h3 class="icon-spaced">{{ productName }}</h3>
          <p class="icon-spaced">{{ productDescription }}</p>
          <div class="hint-box">
            <picture>
              <img
                src="@/assets/images/icons/icon_star.svg"
                alt=""
                width="26"
                height="26px"
              />
            </picture>
            <p class="icon-spaced" v-html="productHint"></p>
          </div>
          <h4>{{ listTitle }}</h4>
          <div v-html="listContent" />
          <base-button class="primary" @click="postAddCoupon">{{
            buttonText
          }}</base-button>
          <div class="voucher-grid">
            <voucher-item
              v-for="license in licenses"
              :key="license"
              :heading="voucherTitleText"
              :voucher="license"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton.vue'
import ContentLoadingIndicator from '@/components/ContentLoadingIndicator.vue'
import VoucherItem from '@/components/CouponShareViewVoucherItem.vue'
import CouponShareService from '@/services/CouponShareService.js'
import XmlLocalizeService from '@/services/XmlLocalizeService.js'
import DataMixin from '@/mixins/data'
export default {
  data() {
    return {
      contentLoading: true,
      titleLocalizations: {},
      productName: '',
      iconPath: '',
      productDescriptionLocalizations: {},
      productHintTranslations: {},
      listTitleTranslations: {},
      listContentTranslations: {},
      buttonTextTranslations: {},
      voucherTitleTranslations: {},
      licenses: [],
      loading: false
    }
  },
  mixins: [DataMixin],
  watch: {
    productShortName(newValue, oldValue) {
      this.loadViewData(newValue)
    }
  },
  props: ['productShortName'],
  components: {
    BaseButton,
    ContentLoadingIndicator,
    VoucherItem
  },
  activated() {
    if (this.$props.productShortName) {
      this.loadViewData(this.$props.productShortName)
    }
  },
  deactivated() {},
  computed: {
    sharedProducts() {
      return this.$store.state.customer.sharedProducts
    },
    voucherAmount() {
      return this.$store.state.customer.contract.licenseSize
    },
    currentLocale() {
      return this.$store.state.ui.currentLocale
    },
    viewTitle() {
      return XmlLocalizeService.getTranlationText.bind(this)(
        this.titleLocalizations,
        this.currentLocale
      )
    },
    productDescription() {
      return XmlLocalizeService.getTranlationText.bind(this)(
        this.productDescriptionLocalizations,
        this.currentLocale
      )
    },
    productHint() {
      return XmlLocalizeService.getTranlationText.bind(this)(
        this.productHintTranslations,
        this.currentLocale,
        {
          boldstart: '<b>',
          boldend: '</b>',
          amount: this.voucherAmount
        }
      )
    },
    listContent() {
      return XmlLocalizeService.getTranlationText.bind(this)(
        this.listContentTranslations,
        this.currentLocale,
        {
          linkstart: '<a href="#fooasd">',
          linkend: '</a>'
        }
      )
    },
    listTitle() {
      return XmlLocalizeService.getTranlationText.bind(this)(
        this.listTitleTranslations,
        this.currentLocale
      )
    },
    buttonText() {
      return XmlLocalizeService.getTranlationText.bind(this)(
        this.buttonTextTranslations,
        this.currentLocale
      )
    },
    voucherTitleText() {
      return XmlLocalizeService.getTranlationText.bind(this)(
        this.voucherTitleTranslations,
        this.currentLocale
      )
    }
  },
  methods: {
    loadViewData(productShortName) {
      this.contentLoading = true
      this.licenses = this.sharedProducts[productShortName].licenses
      CouponShareService.fetchViewData(productShortName).then((response) => {
        if (!response || response.errorCode) {
          this.contentLoading = false
          return
        }
        this.titleLocalizations = XmlLocalizeService.getLocalizations(
          response,
          'title'
        )
        this.productName = XmlLocalizeService.getValue('productName', response)
        this.iconPath = XmlLocalizeService.getValue('icon', response)
        this.productDescriptionLocalizations =
          XmlLocalizeService.getLocalizations(response, 'productDescription')
        this.productHintTranslations = XmlLocalizeService.getLocalizations(
          response,
          'productHint'
        )
        this.listTitleTranslations = XmlLocalizeService.getLocalizations(
          response,
          'listTitle'
        )
        this.listContentTranslations = XmlLocalizeService.getLocalizations(
          response,
          'listContent'
        )
        this.buttonTextTranslations = XmlLocalizeService.getLocalizations(
          response,
          'buttonText'
        )
        this.voucherTitleTranslations = XmlLocalizeService.getLocalizations(
          response,
          'voucherTitle'
        )
        this.contentLoading = false
      })
    },
    postAddCoupon() {
      if (this.loading) {
        return
      }
      this.loading = true
      this.preHandleResponse(() =>
        CouponShareService.postAddCoupon(
          this.$props.productShortName,
          this.$store.state.customer.token
        )
      ).then((response) => {
        if (!response || response.errorCode) {
          this.loading = false
          return
        }

        this.licenses.push(response.coupon.toUpperCase())
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss">
.coupon-share {
  padding-bottom: 46px;

  &.loading {
    height: 600px;
  }

  h2 {
    padding: 40px 0 20px 0;
    font-size: 1.28571rem;
    font-weight: 600;
    margin: 0;
    line-height: 1.21886em;
  }

  h3 {
    font-weight: 600;
    font-size: 1.14286rem;
    padding: 0 0 16px 0;
    line-height: 1.375rem;
    margin: 0;
  }

  h4 {
    font-size: 1rem;
    padding: 0 0 16px 0;
    font-weight: 600;
    line-height: 1.21886em;
    margin: 0;
  }

  p {
    font-size: 1rem;
    padding: 0 0 16px;
    color: #666;
    line-height: 1.4rem;
    margin: 0;
  }

  picture {
    display: inline-block;
  }

  .purple {
    color: #6621cc;
  }

  .hint-box {
    background: #f5f5f5;

    picture {
      float: left;
      margin-top: 10px;
      margin-left: 8px;
      img {
        vertical-align: top;
      }
    }

    > .icon-spaced {
      margin: 0 0 20px 0;
      padding: 8px 10px 8px 46px;
    }
  }

  .padded-icon {
    > picture {
      width: 66px;
      float: right;
    }

    .content {
      > .icon-spaced {
        padding-right: 82px;
      }
    }
  }

  ol {
    margin: 0 0 20px 0;
    padding: 0;

    li {
      font-size: 1rem;
      list-style-position: inside;
      color: #666;
      padding-left: 5px;
      line-height: 140%;
      padding-bottom: 0.288em;
    }
  }

  .voucher-grid {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 27px;
    grid-gap: 20px;
  }
}
@media (min-width: 768px) {
  .coupon-share {
    padding-bottom: 54px;

    h2 {
      font-size: 1.57143rem;
      padding: 48px 0 20px 0;
    }

    h3 {
      font-size: 1.28571rem;
      padding: 0 0 10px 0;
      line-height: 1.567rem;
    }

    h4 {
      font-size: 1.14286rem;
      padding: 0 0 20px;
    }

    p {
      font-size: 1.14286rem;
      line-height: 1.6rem;
      padding: 0 0 20px;
    }

    .flex-btn {
      width: auto;
    }

    .hint-box {
      max-width: 572px;

      > picture {
        margin-top: 20px;
        margin-left: 17px;
      }

      > .icon-spaced {
        padding: 10px 20px 10px 58px;
      }
    }

    > .padded-icon {
      > *:not(picture) {
        padding-left: 108px;
      }

      .content {
        > .icon-spaced {
          padding-right: 0;
        }
      }

      > picture {
        float: left;
        width: 88px;
      }
    }

    ol {
      margin: 0 0 30px 0;
      li {
        font-size: 1.14286rem;
        margin-left: 35px;
        list-style-position: outside;
      }
    }

    .voucher-grid {
      grid-template-columns: 1fr 1fr 1fr;
      margin-top: 46px;
    }
  }
}
</style>
