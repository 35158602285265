<template>
  <div class="action-button">
    <base-purchase-button
      :style="{ height: heightCss }"
      ref="BasePurchaseButton"
    />
  </div>
</template>

<script>
import BasePurchaseButton from './BasePurchaseButton.vue'
export default {
  components: {
    BasePurchaseButton
  },
  data() {
    return {
      resizing: false,
      heightCss: '38px'
    }
  },
  mounted: function a() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy: function a() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize: function a() {
      if (this.resizing) {
        return
      }

      this.resizing = true

      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(this.onResize)
      } else {
        window.setTimeout(this.onResize, 66)
      }
    },
    onResize: function a() {
      let statusBar = this.$parent.$el
      this.heightCss = 'auto'

      this.$nextTick(function a() {
        let statusBarHeight = Math.round(
          statusBar.getBoundingClientRect().height
        )
        this.heightCss = statusBarHeight + 'px'
      })

      this.resizing = false
    }
  }
}
</script>

<style lang="scss" scoped>
.action-button ::v-deep {
  .flex-btn {
    border-radius: 0;
    min-height: 38px;

    span {
      font-weight: 500;
    }
  }
}
</style>
