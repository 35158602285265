var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-left-section" }, [
    _c(
      "div",
      { staticClass: "iframe-wrapper" },
      [
        _c("dynamic-iframe", {
          attrs: { title: "login", "d-src": _vm.iframeSource },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }