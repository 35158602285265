var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "bottom" }, [
    _vm.showNavButtons
      ? _c(
          "div",
          [
            _c(
              "base-header-bar-link",
              {
                attrs: {
                  "data-track-event": "navigate",
                  "data-track-name": _vm.dataTrackName,
                  to: "/portal/welcome",
                },
              },
              [_c("span", [_vm._v(_vm._s(_vm.productName))])]
            ),
            _vm.showMonitoring
              ? _c(
                  "base-header-bar-link",
                  {
                    attrs: {
                      "data-track-event": "navigate",
                      "data-track-name": "id-monitoring",
                      to: "/portal/idp-portal",
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("dashboard.id_monitoring"))),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._l(
              _vm.sharedProductShortNames,
              function (sharedProductShortName) {
                return _c("shared-product-link", {
                  key: sharedProductShortName,
                  attrs: { "product-short-name": sharedProductShortName },
                })
              }
            ),
          ],
          2
        )
      : _c("div"),
    _c(
      "div",
      [
        !_vm.isSubUser
          ? _c(
              "base-header-bar-link",
              {
                attrs: {
                  id: "top-subscription-link",
                  "data-track-event": "navigate",
                  "data-track-name": "subscription",
                  to: "/portal/order",
                },
              },
              [_vm._v(_vm._s(_vm.$t("header.lnk_subscription")))]
            )
          : _vm._e(),
        _c("the-header-bar-account-menu"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }