<template>
  <div class="item" :id="serviceId">
    <div class="title">
      <h3>{{ serviceName }}</h3>
      <p>{{ $t('subscription.txt_active') }}</p>
    </div>
    <div class="content">
      <p>
        {{ startText }}
        <a
          data-track-event="navigate"
          data-track-name="additional-service-info"
          target="_blank"
          rel="noopener noreferrer"
          :href="infoUrl"
          v-if="hasLink"
          @click="navigateOrOpenPopup"
          >{{ linkText }}</a
        >
        {{ endText }}
      </p>
    </div>
  </div>
</template>

<script>
import ActivationService from '@/services/ActivationService'
import XmlLocalizeService from '@/services/XmlLocalizeService'

export default {
  data() {
    return {
      infoText: '',
      infoUrl: '',
      nameLocKey: '',
      infoTextLocKey: ''
    }
  },
  computed: {
    hasLink() {
      return this.infoText.match(/\{linkstart|servicename\}/) !== null
    },
    linkText() {
      const nameExec = /\{servicename\}/g.exec(this.infoText)
      if (nameExec) {
        return this.serviceName
      }
      const exec = /\{linkstart\}(.*?)\{linkend\}/g.exec(this.infoText)
      return exec ? exec[1] : ''
    },
    startText() {
      this.updateInfoText()
      const nameExec = /(.*?)\{servicename\}/g.exec(this.infoText)
      if (nameExec) {
        return nameExec ? nameExec[1] : ''
      }
      const exec = /(.*?)\{linkstart\}/g.exec(this.infoText)
      return exec ? exec[1] : ''
    },
    endText() {
      const nameExec = /\{servicename\}(.*?)$/g.exec(this.infoText)
      if (nameExec) {
        return nameExec ? nameExec[1] : ''
      }
      const exec = /\{linkend\}(.*?)$/g.exec(this.infoText)
      return exec ? exec[1] : ''
    },
    serviceName() {
      return this.$t(this.nameLocKey)
    }
  },
  props: {
    serviceId: {
      type: Number
    }
  },
  methods: {
    navigateOrOpenPopup(event) {
      if (this.infoUrl === '#activation-instructions-popup') {
        event.preventDefault()
        this.openInstructionsPopup()
      }
    },
    openInstructionsPopup() {
      this.$root.$emit('toggle_popup', {
        elementId: 'activation-instructions-popup',
        action: 'open',
        eventInitiator: this.$el,
        data: {
          serviceId: this.$props.serviceId
        }
      })
    },
    updateInfoText() {
      let linkstart = '{linkstart}'
      let linkend = '{linkend}'
      let servicename = '{servicename}'
      this.infoText = this.$t(this.infoTextLocKey, {
        linkstart,
        linkend,
        servicename
      })
    }
  },
  mounted() {
    ActivationService.getServiceDetails(this.$props.serviceId).then(
      (response) => {
        if (!response) {
          return
        }
        this.infoTextLocKey = XmlLocalizeService.getValue(
          'subscriptionViewLocKey',
          response
        )
        this.infoUrl = XmlLocalizeService.getValue('infoUrl', response)
        this.nameLocKey = XmlLocalizeService.getValue('nameLocKey', response)
      }
    )
  }
}
</script>

<style lang="scss" scoped>
.title {
  h3 {
    margin: 0;
  }
  p {
    margin-top: 0;
    font-weight: 400;
    color: $font-color;
  }
}
.content {
  p {
    margin-bottom: 0;
  }
}
.item {
  p {
    font-weight: 300;
    color: $font-color;

    a {
      font-weight: 400;
    }
  }
}
</style>
