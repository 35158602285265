<template>
  <button class="flex-btn" v-bind="$props" v-on="$listeners">
    <span>
      <slot />
    </span>
  </button>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped></style>
