<template>
  <div class="box">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.box {
  a:not(.flex-btn) {
    font-size: 1rem;
    font-weight: bold;
    font-family: 'FSecureOffice';
  }

  h2 {
    font-size: 1.28571rem;
    font-weight: 600;
    padding: 40px 0 16px 0;
    margin: 0;
    line-height: 1.21886em;
  }

  p {
    margin: 0;
    font-size: 1rem;
    padding: 0 0 16px;
    color: #666;
    line-height: 140%;

    + .flex-btn {
      margin: 10px 0 0 0;
    }
  }
}
@media (min-width: $desktop-min-width) {
  .box {
    a:not(.flex-btn) {
      font-size: 1.14286rem;
    }

    h2 {
      font-size: 1.57143rem;
      padding-bottom: 20px;
    }

    p {
      font-size: 1.14286rem;
      padding-bottom: 20px;
    }
  }
}
</style>
