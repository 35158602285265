var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isVisible
    ? _c("one-col-box-grid-content-box", [
        _c("h2", [_vm._v(_vm._s(_vm.$t("account.hl_notification_settings")))]),
        _c("p", { domProps: { innerHTML: _vm._s(_vm.bodyText) } }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }