var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-view", {
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function () {
          return [
            _c("the-header", { attrs: { "is-nav-shown": "" } }),
            _c("the-title-section", { attrs: { title: _vm.mainTitle } }),
            _c("one-col-box-grid-content", [_c("ar-cancel-content")], 1),
          ]
        },
        proxy: true,
      },
      {
        key: "bottom",
        fn: function () {
          return [_c("the-region-picker"), _c("the-help"), _c("the-footer")]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }