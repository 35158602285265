var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isVisible
    ? _c(
        "base-settings-content-section",
        { staticClass: "sense-howto", attrs: { id: "sense-howto-id" } },
        [
          _c("h2", { attrs: { id: "hl_how_to_start_using_sense" } }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("subscription.hl_howto_start_using_sense")) +
                " "
            ),
          ]),
          _c("base-settings-content-section-grid", [
            _c("div", { staticClass: "long" }, [
              _c("ol", { attrs: { id: "ol-sense-instructions" } }, [
                _c("li", {
                  domProps: { innerHTML: _vm._s(_vm.infoSenseInstruction1) },
                }),
                _c("li", [
                  _vm._v(
                    _vm._s(_vm.$t("subscription.info_sense_instruction_2"))
                  ),
                ]),
                _c("li", [
                  _vm._v(
                    _vm._s(_vm.$t("subscription.info_sense_instruction_3"))
                  ),
                ]),
                _c("li", [
                  _vm._v(
                    _vm._s(_vm.$t("subscription.info_sense_instruction_4"))
                  ),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "short" },
              [
                _c(
                  "base-button-link-native",
                  {
                    staticClass: "primary",
                    attrs: {
                      href: _vm.senseDownloadUrl,
                      id: "btn-download-sense",
                      target: "_blank",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("subscription.info_sense_download_label")
                        ) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }