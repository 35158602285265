<template>
  <base-settings-content-section
    class="billing-details"
    id="billing-details-id"
    v-if="isVisible"
  >
    <h2>{{ $t('subscription.hl_billing_details') }}</h2>
    <base-settings-content-section-grid>
      <template>
        <div class="long">
          <p id="text-expiration-date-title">{{ dateSectionTitle }}</p>
        </div>
        <div class="short">
          <p id="text-expiration-date-value">{{ expirationDateFormatted }}</p>
        </div>
        <template v-if="isAutomaticSubscription">
          <div class="long">
            <p id="text-billing-price-title">
              {{ $t('subscription.next_billing_price_title') }}
            </p>
          </div>
          <div class="short">
            <p id="text-billing-price-value">{{ nextBillingPrice }}</p>
          </div>
          <div class="long">
            <p style="font-size: 0.75rem; font-style: italic">
              {{ $t('subscription.next_billing_cancellation_info') }}
            </p>
          </div>
          <div class="short"></div>
        </template>
        <div v-if="allowChangePaymentMethod" class="long">
          <p id="text-payment-method">{{ paymentMethodText }}</p>
        </div>
        <div v-if="allowChangePaymentMethod" class="short">
          <base-button-link-native
            id="btn-change-payment-method"
            class="neutral"
            :href="changePaymentMethodUrl"
            data-track-event="navigate"
            data-track-name="change-payment-method"
          >
            {{ $t('subscription.btn_change_payment_method') }}
          </base-button-link-native>
        </div>
      </template>
    </base-settings-content-section-grid>
  </base-settings-content-section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import BaseSettingsContentSection from './BaseSettingsContentSection.vue'
import BaseSettingsContentSectionGrid from './BaseSettingsContentSectionGrid.vue'
import BaseButtonLinkNative from './BaseButtonLinkNative.vue'

export default {
  components: {
    BaseSettingsContentSection,
    BaseSettingsContentSectionGrid,
    BaseButtonLinkNative
  },
  computed: {
    ...mapState({
      isAutomaticSubscription: (state) =>
        state.customer.contract.isAutomaticSubscription,
      isCancellationSet: (state) => state.customer.contract.isCancellationSet,
      changePaymentMethodUrl: (state) =>
        state.customer.contract.changePaymentMethodUrl,
      paymentMethod: (state) => state.customer.paymentMethod,
      contractType: (state) => state.customer.contract.type,
      currentPaidARBilling: (state) => state.ui.cart.current_paid_ar_billing
    }),
    ...mapGetters({
      expirationDateFormatted: 'customer/contract/expirationDateFormatted',
      isTrial: 'customer/contract/isTrial',
      isSubUser: 'customer/contract/isSubUser',
      isPrepaid: 'customer/contract/isPrepaid',
      isSenseSubscription: 'customer/contract/isSenseSubscription'
    }),
    isVisible() {
      return (
        !(this.isAutomaticSubscription && this.isPrepaid) &&
        !this.isSubUser &&
        this.contractType &&
        !this.isSenseSubscription
      )
    },
    dateSectionTitle() {
      if (this.isTrial) {
        return this.$t('subscription.expires')
      }

      return this.$t('subscription.hl_next_billing_date')
    },
    allowChangePaymentMethod() {
      return (
        !this.isTrial &&
        !this.isSubUser &&
        !this.isCancellationSet &&
        this.changePaymentMethodUrl
      )
    },
    paymentMethodText() {
      let returnValue = this.$t('subscription.' + this.paymentMethod)
      if (returnValue.startsWith('subscription.')) {
        return ''
      }
      return returnValue
    },
    nextBillingPrice() {
      return this.$t('subscription.next_billing_price_value', {
        currency: this.currentPaidARBilling.currency,
        amount: this.currentPaidARBilling.price
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.grid {
  p {
    font-size: 1.214rem;
  }
}
</style>
