<template>
  <div
    class="modal-popup"
    :class="{ wide: isWide, 'less-close-padding': lessClosePadding }"
    :data-close-on-click="displayCloseButton"
  >
    <div class="box">
      <a
        :id="topCloseId"
        class="close"
        v-if="displayCloseButton"
        href="#"
        @click.prevent="onCloseClick"
      >
        <span>
          <picture>
            <img
              src="../assets/images/icons/icon_close_x_gray.svg"
              alt="close"
            />
            <img
              src="../assets/images/icons/icon_close_x_hover.svg"
              alt="close"
            />
          </picture>
        </span>
      </a>
      <popup-content :more-padding="morePadding">
        <slot />
      </popup-content>
      <div
        class="footer"
        v-if="displayFooter"
        :class="{ 'more-padding': moreFooterPadding }"
      >
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
import PopupContent from './BaseModalPopupContent.vue'
export default {
  components: {
    PopupContent
  },
  props: {
    isWide: {
      type: Boolean,
      default: false
    },
    displayFooter: {
      type: Boolean,
      default: false
    },
    displayCloseButton: {
      type: Boolean,
      default: false
    },
    onCloseClick: {
      type: Function
    },
    morePadding: {
      type: Boolean
    },
    lessClosePadding: {
      type: Boolean
    },
    moreFooterPadding: {
      type: Boolean
    },
    topCloseId: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-popup {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 10004;
  display: flex;
  justify-content: center;
  align-content: center;
  top: 0;

  &.less-close-padding {
    a.close {
      top: 9px;
      right: 9px;
    }
  }

  a.close {
    display: block;
    width: 26px;
    height: 26px;
    position: absolute;
    top: 20px;
    right: 20px;

    span {
      display: block;
    }

    picture {
      display: block;
    }

    img {
      vertical-align: middle;
      display: inline-block;

      &:last-child {
        display: none;
      }
    }

    &:hover {
      img {
        &:first-child {
          display: none;
        }

        &:last-child {
          display: inline-block;
        }
      }
    }
  }

  &.wide {
    > .box {
      max-width: 900px;
      flex: 1 1 auto;
    }
  }

  > .box {
    background: #fff;
    position: relative;
    border-radius: 6px;
    box-shadow: 0px 0px 9px #888888;
    max-width: 640px;
    margin: auto 20px;
    max-height: 80%;
    overflow-y: auto;
    overflow-x: hidden;

    > .footer {
      padding: 20px;
      background: #f5f5f5;
      border-top: 1px #e8e8e8 solid;
      text-align: center;

      p {
        color: $font-color;
        margin-bottom: 4px;
      }
    }
  }
}

@media (min-width: $desktop-min-width) {
  .modal-popup {
    &.less-close-padding {
      a.close {
        top: 17px;
        right: 17px;
      }
    }
  }
}

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .modal-popup {
    > .box {
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      position: absolute;
      margin: auto;
      width: calc(100% - 40px);
    }
  }
}
</style>
