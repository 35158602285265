import DataService from './DataService'

export default {
  async postCancelAutorenewal(token) {
    return DataService.post(
      process.env.VUE_APP_API_BASE_URL + '/cancel_autorenew',
      {
        token
      }
    )
  },
  async postEnableAutorenewal(token) {
    return DataService.post(
      process.env.VUE_APP_API_BASE_URL + '/enable_autorenew',
      {
        token
      }
    )
  },
  async postConvertToAnnual(token) {
    return DataService.post(
      process.env.VUE_APP_API_BASE_URL + '/convert_to_annual',
      {
        token
      }
    )
  }
}
