<template>
  <base-modal-popup
    id="upgrade-popup"
    class="upgrade"
    v-show="isOpen"
    is-wide
    display-close-button
    :on-close-click="closePopup"
    more-padding
    less-close-padding
  >
    <upgrade-override-popup-content />
    <upgrade-popup-content />
  </base-modal-popup>
</template>

<script>
import popupMixin from '@/mixins/popup.js'
import BaseModalPopup from './BaseModalPopup.vue'
import UpgradePopupContent from './TheUpgradePopupContent.vue'
import UpgradeOverridePopupContent from './TheUpgradeOverridePopupContent.vue'

export default {
  props: {
    closeByEsc: {
      type: Boolean,
      default: true
    }
  },
  mixins: [popupMixin],
  components: {
    BaseModalPopup,
    UpgradePopupContent,
    UpgradeOverridePopupContent
  }
}
</script>

<style lang="scss" scoped></style>
