<template>
  <div class="view-content">
    <dynamic-iframe :d-src="iframeSrc" />
  </div>
</template>

<script>
import DynamicIframe from './DynamicIframe.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    DynamicIframe
  },
  computed: {
    ...mapGetters({
      email: 'customer/getEmail'
    }),
    iframeSrc() {
      return (
        process.env.VUE_APP_API_BASE_URL +
        '/portal/contact/embed/update?email=' +
        encodeURIComponent(this.email) +
        '&style=intern&hideBackButton=1'
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.view-content {
  /* position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden; */
  overflow: hidden;

  iframe {
    height: 600px;
    width: 100%;
    vertical-align: middle;
  }
}
</style>
