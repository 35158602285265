var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "the-status-bar",
      class: { "two-notes": _vm.showSecondNotice },
    },
    [
      _c(
        "div",
        { staticClass: "content-wrapper" },
        [
          _c("status-info", {
            attrs: {
              message: _vm.infoMessage,
              type: _vm.mainStatus,
              "link-url": _vm.statusbarLinkUrl,
              "link-text": _vm.statusbarLinkText,
            },
          }),
          _vm.showSecondNotice
            ? _c("status-info", {
                attrs: {
                  message: _vm.infoMessage2,
                  type: "notice",
                  "link-url": _vm.statusbarLinkUrl2,
                  "link-text": _vm.statusbarLinkText2,
                },
              })
            : _vm._e(),
          _c("other-info", {
            attrs: {
              "days-until-expiration": _vm.daysUntilExpirationProcessed,
              "total-licenses": _vm.totalLicenses,
              "available-licenses": _vm.availableLicenses,
              "hide-days-until-expiration": _vm.hideDaysUntilExpiration,
            },
          }),
          _vm.isPurchaseButtonVisible
            ? _c("action-button", { ref: "ActionButton" })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }