var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "inner-content" },
    [
      _c("h2", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
      _c("p", [_c("b", [_vm._v(_vm._s(_vm.listTitle))])]),
      _c("p", [_vm._v(" " + _vm._s(_vm.listSubtitle) + " ")]),
      _c(
        "ol",
        _vm._l(_vm.listItems, function (item, index) {
          return _c("li", { key: index, domProps: { innerHTML: _vm._s(item) } })
        }),
        0
      ),
      _c("div", { staticClass: "list-wrapper" }, [
        _c(
          "ul",
          _vm._l(_vm.vouchers, function (voucher, index) {
            return _c("li", { key: index }, [_vm._v(_vm._s(voucher))])
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "footer-message" }, [
        _c("i", [
          _vm._v(" " + _vm._s(_vm.footerStartText) + " "),
          _c(
            "a",
            { attrs: { href: _vm.termsAndConditionsUrl, target: "_blank" } },
            [_vm._v(" " + _vm._s(_vm.footerTermsLink) + " ")]
          ),
          _vm._v(" " + _vm._s(_vm.footerTextInBetweenLinks) + " "),
          _c("a", { attrs: { href: _vm.privacyPolicyUrl, target: "_blank" } }, [
            _vm._v(" " + _vm._s(_vm.footerPrivacyLink) + " "),
          ]),
          _vm._v(" " + _vm._s(_vm.footerEndText) + " "),
        ]),
      ]),
      _c(
        "base-button",
        {
          staticClass: "primary",
          attrs: {
            id: "btn-activation-instructions-close",
            "data-track-event": "navigate",
            "data-track-name": "close-activation-instructions",
          },
          on: { click: _vm.closePopup },
        },
        [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }